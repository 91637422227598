import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, TextInput, Image, Text } from "react-native";
import styled from "styled-components/native";
import fbInstance from "../Config/fbInstance";
import AwesomeAlert from 'react-native-awesome-alerts';
import { GetPickedTime, GetPickedTime2, GetColors } from "../Config/Datas";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';


const BackView = styled.View`
    background-color: ${GetColors(0)};
    padding-left: 45px;
    padding-right: 45px;
    margin-bottom: 150px;
`;

const GuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 500;
`;

const GuideText2 = styled.Text`
    font-size: 18px;
    font-weight: 600;
`;

const GuideText3 = styled.Text`
    font-size: 15px;
    font-weight: 600;
`;

const GuideText4 = styled.Text`
    font-size: 20px;
    font-weight: 600;
    color: ${GetColors(0)};
`;

const GuideText5 = styled.Text`
    font-size: 18px;
    font-weight: 700;
    color: ${GetColors(5)};
`;


const GuideText6 = styled.Text`
    font-size: 18px;
    font-weight: 500;
    color: ${GetColors(9)};
`;

const GuideText7 = styled.Text`
    font-size: 16px;
    font-weight: 500;
    color: ${GetColors(5)};
`;

const GuideText8 = styled.Text`
    font-size: 16px;
    font-weight: 500;
    color: ${GetColors(9)};
`;

const checkInTimeArr = GetPickedTime2();
const byeTimeArr = GetPickedTime();

const ReservationDetails = ({ route, navigation }) => {
    const history = route.params.history;
    const [isAlert, setIsAlert] = useState([false, false, "", true, -1]);

    let sDate = new Date(
        history.startDate.split('/')[0],
        history.startDate.split('/')[1],
        history.startDate.split('/')[2],
        checkInTimeArr[parseInt(history.byeTime.split(',')[0])].label.split(':')[0],
        checkInTimeArr[parseInt(history.byeTime.split(',')[0])].label.split(':')[1],
        0);

    let eDate = new Date(
        history.endDate.split('/')[0],
        history.endDate.split('/')[1],
        history.endDate.split('/')[2],
        byeTimeArr[parseInt(history.byeTime.split(',')[1])].label.split(':')[0],
        byeTimeArr[parseInt(history.byeTime.split(',')[1])].label.split(':')[1],
        0);

    let calDate = eDate.getTime() - sDate.getTime();
    let calDateHours = calDate / (60 * 60 * 1000);
    let hours = Math.round(calDateHours * 10) / 10;

    let useDays = Math.round(hours / 24);
    let useLeftHour = Math.round(hours % 24);

    let totalCost = (useDays * history.roomPrice) + (useLeftHour * history.roomPrice / 24);

    const goToDetail = () => {
        navigation.navigate("상세정보", { companyUid: history.storeUid });
    }

    // 장례식장 이름, 방 이름, 가격, 예약자 이름, 예약일시
    return (
        <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView style={{ width: 400, backgroundColor: GetColors(0) }}>
                <BackView>
                    <View style={{ flexDirection: 'row', paddingTop: 3, alignItems: 'center', paddingTop: 20 }}>
                        <GuideText5>국화 </GuideText5>
                        <GuideText1>와 함께 해주셔서 감사합니다.</GuideText1>
                    </View>
                    <View style={{ paddingBottom: 5, flexDirection: 'row', paddingTop: 3, alignItems: 'center', paddingTop: 20 }}>
                        <GuideText5>예약 내역 </GuideText5>
                    </View>
                    <View style={{ borderRadius: 5, borderWidth: 1, borderColor: GetColors(1), padding: 10 }}>
                        {history.userPhone == null ?
                            <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                                <GuideText1 style={{ flex: 3 }}>장례식장 : </GuideText1>
                                <GuideText7 style={{ flex: 7 }}>{history.storeName}</GuideText7>
                            </View>
                            :
                            <>
                                <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                                    <GuideText1 style={{ flex: 3 }}>예약자 : </GuideText1>
                                    <GuideText7 style={{ flex: 7 }}>{history.userName}</GuideText7>
                                </View>
                                <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                                    <GuideText1 style={{ flex: 3 }}>연락처 : </GuideText1>
                                    <GuideText7 style={{ flex: 7 }}>{history.userPhone}</GuideText7>
                                </View>
                            </>}

                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>빈소 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{history.roomName}</GuideText7>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>가격 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{history.roomPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</GuideText7>
                        </View>

                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>총 가격 : </GuideText1>
                            {parseInt(history.byeTime.split(',')[0]) === 0 || parseInt(history.byeTime.split(',')[1]) === 0 ?
                                <GuideText7 style={{ flex: 7 }}>장례식장에서{"\n"}입실시간 및 발인시간을{"\n"}입력하지 않았습니다.</GuideText7>
                                :
                                <View style={{ flex: 7 }}>
                                    <GuideText7>이용시간 {useDays} 일 {useLeftHour} 시간</GuideText7>
                                    <GuideText6>약 {Math.round(totalCost).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</GuideText6>
                                    <GuideText3>(시간당 {(Math.round(history.roomPrice / 24)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원 기준 계산)</GuideText3>
                                </View>
                            }
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>할인율 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{history.saleRate} %</GuideText7>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>고인 성함 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{history.RIPName}</GuideText7>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>상주님 성함 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{history.RIPResponsibilityName}</GuideText7>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>입실시간 : </GuideText1>
                            {parseInt(history.byeTime.split(',')[0]) === 0 ?
                                <GuideText8>장례식장에서{"\n"}입실시간을 입력하지 않았습니다.</GuideText8>
                                :
                                <GuideText7 style={{ flex: 7 }}>{checkInTimeArr[parseInt(history.byeTime.split(',')[0])].label}</GuideText7>}
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>발인시간 : </GuideText1>
                            {parseInt(history.byeTime.split(',')[1]) === 0 ?
                                <GuideText8>장례식장에서{"\n"}발인시간을 입력하지 않았습니다.</GuideText8>
                                :
                                <GuideText7 style={{ flex: 7 }}>{byeTimeArr[parseInt(history.byeTime.split(',')[1])].label}</GuideText7>
                            }
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>예약일자 : </GuideText1>
                        </View>
                        <GuideText7 style={{ paddingTop: 5, textAlign: 'center' }}>{history.startDate} 부터{"\n"}{history.endDate}일 까지</GuideText7>
                    </View>
                    <View style={{ paddingBottom: 5, flexDirection: 'row', paddingTop: 3, alignItems: 'center', paddingTop: 20 }}>
                        <GuideText5>추천인 정보(장례지도사)</GuideText5>
                    </View>
                    <View style={{ borderRadius: 5, borderWidth: 1, borderColor: GetColors(1), padding: 10 }}>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 4 }}>추천인 이름 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{history.RecommendName}</GuideText7>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 4 }}>추천인 연락처 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{history.RecommendPhone}</GuideText7>
                        </View>
                    </View>
                    {history.userPhone == null ?
                        <TouchableOpacity onPress={goToDetail} style={{ marginTop: 30, justifyContent: 'center', alignItems: 'center', height: 45, borderRadius: 30, backgroundColor: GetColors(5) }}>
                            <GuideText4>장례식장 정보 보기</GuideText4>
                        </TouchableOpacity>
                        :
                        <></>
                    }
                </BackView>
                <AwesomeAlert
                    show={isAlert[0]}
                    showProgress={isAlert[1]}
                    title="국화"
                    message={isAlert[2]}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showCancelButton={false}
                    showConfirmButton={isAlert[3]}
                    cancelText=""
                    confirmText=" 확인 "
                    confirmButtonColor="#000000"
                    onCancelPressed={() => {
                    }}
                    onConfirmPressed={() => {
                        if (isAlert[4] == 0) {
                            // actConfirmBook();
                        }
                        setIsAlert([false, false, "국화", true, -1]);
                    }}
                />
            </KeyboardAwareScrollView>
        </View>
    )
}

export default ReservationDetails;
