import React, { useState, useEffect } from "react";
import { View, TouchableOpacity } from "react-native";
import fbInstance from "../Config/fbInstance";
import styled from "styled-components/native";
import AwesomeAlert from 'react-native-awesome-alerts';
import * as ImagePicker from 'expo-image-picker';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import * as ImageManipulator from 'expo-image-manipulator';
import { GetColors, trimText } from "../Config/Datas";

const BackView = styled.View`
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 150px;
    background-color: ${GetColors(0)};
`;

const GuideText1 = styled.Text`
    font-size: 22px;
    font-weight: 700;
`;

const GuideText2 = styled.Text`
    font-size: 12px;
    color: ${GetColors(0)};
`;


const GuideText22 = styled.Text`
    font-size: 15px;
    color: ${GetColors(0)};
`;

const GuideText222 = styled.Text`
    font-size: 16px;
    color: ${GetColors(0)};
`;

const GuideText3 = styled.Text`
    font-size: 13px;
    font-weight: 400;
`;

const GuideText33 = styled.Text`
    font-size: 13px;
    font-weight: 700;
`;

const GuideText4 = styled.Text`
    font-size: 14px;
    font-weight: 600;
`;

const GuideText5 = styled.Text`
    font-size: 14px;
    font-weight: 700;
`;

const Profile = ({ navigation }) => {
    const fAuth = fbInstance.auth();
    const fDb = fbInstance.firestore();
    fAuth.useDeviceLanguage();
    const [isLogin, setIsLogin] = useState(fAuth.currentUser);
    const [user, setUser] = useState('');
    const [history, setHistory] = useState([]);
    const [image, setImage] = useState(null);
    const [isAlert, setIsAlert] = useState([false, "", false, true, 0, false]);

    useEffect(() => {
        if (fAuth.currentUser != null) {
            fDb.collection('GUsers').doc(fAuth.currentUser.uid).onSnapshot((doc) => {
                setUser(doc.data());
            })
            fDb.collection('UserHistory').doc(fAuth.currentUser.uid).onSnapshot((doc) => {
                if (doc.exists) {
                    const tempHis1 = doc.data().history;
                    let tempHis2 = [];
                    
                    for(let i = 0 ; i < tempHis1.length; i++){
                        tempHis2.push(tempHis1[tempHis1.length -1 -i])
                    }

                    setHistory(tempHis2);
                }
            })
        }
    }, [isLogin]);


    const onLogoutPressed = async () => {
        fAuth.signOut().then(() => {
            setIsLogin(fAuth.currentUser);
            navigation.goBack();
        }).catch((error) => {

        });
    }

    const actAskUserLevelUp = async () => {
        // 자격증 이미지 업로드
        // 유저 정보 변경
        setIsAlert([true, "2단계 회원신청 중입니다.\n잠시만 기다려주세요.", true, false, 0, false]);

        let imageUrlTemp = "";
        let imagePathTemp = "";
        const randomString = Math.random().toString(36).substr(2, 11) + Math.random().toString(36).substr(2, 11) + Math.random().toString(36).substr(2, 11);

        const res = await fetch(image);
        const blob = await res.blob();

        const storegeService = fbInstance.storage();

        const fileRef = storegeService.ref().child(`licenseImage/${randomString}`);
        imagePathTemp = randomString;
        const response = await fileRef.put(blob);
        imageUrlTemp = await response.ref.getDownloadURL();

        let userTemp = user;
        userTemp.licenseUrl = imageUrlTemp;
        userTemp.licenseImagePath = imagePathTemp;
        userTemp.userCode = 3;
        setUser(userTemp);

        const fDb = fbInstance.firestore();
        fDb.collection('GUsers').doc(fAuth.currentUser.uid).update(userTemp);
        setIsAlert([true, "2단계 회원신청이 완료되었습니다.\n결과는 영업일 기준\n3~4일 후 처리됩니다.", false, true, 0, false]);
    }

    const goToRequestCerti = () => {
        navigation.navigate("장례지도사 인증신청", { user: user });
    }

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            quality: 0.1
        });

        if (!result.cancelled) {
            const manipResult = await ImageManipulator.manipulateAsync(
                result.uri,
                [{ rotate: 0 }],
                { compress: 0.15, format: ImageManipulator.SaveFormat.JPEG }
            );


            setImage(manipResult.uri);
            let tempUser = user;
            tempUser.licenseUrl = "";
            setUser(tempUser);
            setIsAlert([true, "선택하신 사진으로 지도 자격증을 인증하시겠습니까?", false, true, 1, true]);
        }
        else {
            setIsAlert([true, "사진 선택이 취소 되었습니다.", false, true, 0, false]);
        }
    };


    const goToInfo = () => {
        navigation.navigate('지도사 회원정보수정', { user: user });
    }

    const goToReservationDetails = (his) => {
        navigation.navigate('예약내역', {history: his});
    }

    return (
        <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView style={{ backgroundColor: GetColors(0), width: 400 }}>
                <BackView>
                    <TouchableOpacity onPress={goToInfo} style={{ marginTop: 15, paddingBottom: 12, flexDirection: 'row', borderRadius: 5, borderColor: GetColors(5), borderWidth: 0.5 }}>
                        <View style={{ flex: 2, paddingLeft: 15, justifyContent: 'flex-end' }}>
                            <View style={{ flexDirection: 'row', paddingTop: 15, alignItems: 'flex-end', marginBottom: 10 }}>
                                <GuideText1>{user.name}</GuideText1>
                                <View style={{ borderRadius: 5, backgroundColor: GetColors(5), width: 55, height: 16, marginLeft: 5, alignItems: 'center', justifyContent: 'center' }}>
                                    <GuideText2>{user.userCode === 1 ? "인증필요" : "인증완료"}</GuideText2>
                                </View>
                            </View>
                            <GuideText3>{user.email}</GuideText3>
                            <GuideText3>{user.phone}</GuideText3>
                            <GuideText3>회원구분 : {user.userCode === 1 ? "일반 회원" : user.userCode === 2 ? "장례지도사 회원" : "인증 진행중 "}</GuideText3>
                        </View>
                        <View style={{ flex: 1, justifyContent: 'flex-end', paddingRight: 15, alignItems: 'flex-end' }}>
                            {/* <Image source={require('../assets/favicon.png')} style={{ width: 85, height: 85, borderRadius: 100 }}></Image> */}
                            <MaterialCommunityIcons name="account-circle" size={80} style={{ width: 85, height: 85, borderRadius: 100 }}></MaterialCommunityIcons>
                        </View>
                    </TouchableOpacity>
                    {user.userCode === 1 ?
                        <View style={{ marginTop: 24 }}>
                            <GuideText4>장례지도사 회원</GuideText4>
                            <GuideText3 style={{ paddingTop: 12 }}>국화와 함께해주셔서 감사합니다.</GuideText3>
                            <GuideText3 style={{ paddingTop: 10 }}>추가적인 서비스를 이용하시려면 장례지도사 회원인증이 필요합니다.</GuideText3>
                            <GuideText3>장례지도사 자격증 및 정보를 입력해주세요.</GuideText3>
                            <GuideText3>(영업일 기준 3-4일 소요됩니다.)</GuideText3>
                            <View style={{ paddingTop: 20, alignItems: 'center', justifyContent: 'center' }}>
                                <TouchableOpacity onPress={goToRequestCerti} style={{ borderRadius: 25, backgroundColor: GetColors(5), width: 250, height: 40, marginLeft: 5, alignItems: 'center', justifyContent: 'center' }}>
                                    <GuideText22>장례지도사 인증하기</GuideText22>
                                </TouchableOpacity>
                            </View>
                        </View>
                        :
                        <></>}
                    <View style={{ paddingTop: 20, marginBottom: 15 }}>
                        <GuideText5>예약현황</GuideText5>
                    </View>
                    {history.length === 0
                        ?
                        <View style={{ paddingTop: 10, paddingBottom: 10, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <GuideText4>예약내역이 없습니다.</GuideText4>
                        </View>
                        : history.map((his, index) => (
                            <TouchableOpacity
                                key={Math.random().toString()}
                                style={{ height: 63, marginBottom: 12, flexDirection: 'row', flex: 1, borderRadius: 5, borderColor: GetColors(22), borderWidth: 1 }}
                                onPress={() => goToReservationDetails(his)}>
                                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                    <GuideText3>{index + 1}</GuideText3>
                                </View>
                                <View style={{ flex: 6, justifyContent: 'center' }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <GuideText33>업체명 </GuideText33>
                                        <GuideText3>{trimText(his.storeName, 8)}</GuideText3>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <GuideText33>예약일 </GuideText33>
                                        <GuideText3>{his.startDate}</GuideText3>
                                    </View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <GuideText33>고인 성함 </GuideText33>
                                        <GuideText3>{his.RIPName}</GuideText3>
                                    </View>
                                </View>
                                <View style={{ flex: 6, justifyContent: 'center' }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <GuideText33>할인율 </GuideText33>
                                        <GuideText3>{his.saleRate}%</GuideText3>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <GuideText33>발인일 </GuideText33>
                                        <GuideText3>{his.endDate}</GuideText3>
                                    </View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <GuideText33>상주 성함 </GuideText33>
                                        <GuideText3>{his.RIPResponsibilityName}</GuideText3>
                                    </View>
                                </View>
                            </TouchableOpacity>
                        ))}
                    <View style={{ paddingTop: 20, alignItems: 'center', justifyContent: 'center' }}>
                        <TouchableOpacity onPress={onLogoutPressed} style={{ borderRadius: 25, backgroundColor: GetColors(5), height: 40, width: 200, marginLeft: 5, alignItems: 'center', justifyContent: 'center' }}>
                            <GuideText222>로그아웃</GuideText222>
                        </TouchableOpacity>
                    </View>
                    <AwesomeAlert
                        show={isAlert[0]}
                        showProgress={isAlert[2]}
                        title="국화"
                        message={isAlert[1]}
                        closeOnTouchOutside={false}
                        closeOnHardwareBackPress={false}
                        showCancelButton={isAlert[5]}
                        showConfirmButton={isAlert[3]}
                        cancelText=" 취소 "
                        confirmText=" 확인 "
                        confirmButtonColor="#000000"
                        onCancelPressed={() => {
                            setIsAlert([false, "", false, true, 0, false]);
                        }}
                        onConfirmPressed={() => {
                            if (isAlert[4] === 1) {
                                actAskUserLevelUp();
                            }
                            else {
                                setIsAlert([false, "", false, true, 0, false]);
                            }
                        }}
                    />
                </BackView>
            </KeyboardAwareScrollView>
        </View>
    );
}

export default Profile;
