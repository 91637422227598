import React, { useState, useEffect } from "react";
import { View, Platform, Dimensions, TouchableOpacity } from "react-native";
import styled from "styled-components/native";
import { GetColors } from "../Config/Datas"

const BackView = styled.View`
    flex: 1;
    background: ${GetColors(0)};
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 30px;
`;

const GuideText1 = styled.Text`
    font-size: 28px;
    font-weight: 500;
    color: ${GetColors(2)};
`;

const GuideText2 = styled.Text`
    font-size: 18px;
    font-weight: 500;
    color: ${GetColors(11)};
`;

const GuideText3 = styled.Text`
    font-size: 16px;
    font-weight: 500;
    color: ${GetColors(11)};
`;

const Welcome = ({ navigation }) => {

    const goToLogin = () => {
        navigation.navigate("내정보");
    }

    const goToChooseAccountType = () => {
        navigation.navigate("회원가입");
    }

    return (
        <BackView style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ width: 400, paddingLeft: 35, paddingRight: 35 }}>
                <GuideText1 style={{ paddingBottom: 5 }}>안녕하세요</GuideText1>
                <GuideText1 style={{ paddingBottom: 5 }}>상조 장례서비스</GuideText1>
                <View style={{ flexDirection: "row" }}>
                    <GuideText1 style={{ color: GetColors(5), paddingBottom: 5 }}>국화</GuideText1>
                    <GuideText1 style={{ paddingBottom: 5 }}> 입니다.</GuideText1>
                </View>
                <GuideText2 style={{ paddingBottom: 5 }}>로그인이 필요합니다.</GuideText2>
                <GuideText3>회원가입을 하시고 다양한 혜택을 누리세요</GuideText3>
                <View style={{ marginTop: 100 }}></View>
                <TouchableOpacity style={{
                    height: 45,
                    width: 400 - 90,
                    alignSelf: 'center',
                    justifyContent: "center",
                    backgroundColor: GetColors(0),
                    borderRadius: 30,
                    shadowColor: 'black',
                    shadowRadius: 3,
                    shadowOpacity: 0.6,
                    shadowOffset: { width: 1, height: 1 }
                }} onPress={goToLogin}>
                    <GuideText2 style={{ color: GetColors(12), textAlign: "center" }}>로그인</GuideText2>
                </TouchableOpacity>
                <TouchableOpacity style={{
                    marginTop: 20,
                    height: 45,
                    width: 400 - 90,
                    alignSelf: 'center',
                    justifyContent: "center",
                    backgroundColor: GetColors(12),
                    borderRadius: 30,
                    shadowColor: 'black',
                    shadowRadius: 3,
                    shadowOpacity: 0.6,
                    shadowOffset: { width: 1, height: 1 }
                }} onPress={goToChooseAccountType}>
                    <GuideText2 style={{ color: GetColors(0), textAlign: "center" }}>회원가입</GuideText2>
                </TouchableOpacity>
            </View>
        </BackView >
    )
}

export default Welcome;

