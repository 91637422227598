import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, TextInput, Image  } from "react-native"
import fbInstance from "../Config/fbInstance";
import styled from "styled-components/native";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import AwesomeAlert from 'react-native-awesome-alerts';
import moment from "moment";
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { GetColors } from '../Config/Datas';
import * as Linking from 'expo-linking';
import { AntDesign, MaterialCommunityIcons, FontAwesome } from '@expo/vector-icons';

// const windowHeight = Dimensions.get('window').height;
// const windowWidth = Dimensions.get('window').width;

const BackView = styled.View`
    padding-left: 45px;
    padding-right: 45px;
    background-color: ${GetColors(0)};
`;

const GuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 500;
`;

const GuideText2 = styled.Text`
    font-size: 18px;
    font-weight: 600;
`;

const GuideText3 = styled.Text`
    font-size: 20px;
    font-weight: 700;
    color: ${GetColors(0)};
`;

const GuideText4 = styled.Text`
    font-size: 18px;
    font-weight: 700;
    color: ${GetColors(5)};
`;

const GuideText6 = styled.Text`
    font-size: 18px;
    font-weight: 500;
`;
/* (상호, 사업자등록번호, 사업주소지, 대표자성명, 소속(선택사항)) */
// shopName, shopNumber, shopAddress, shopFounder, shopConnect
const SignInNormal = ({ navigation }) => {
    const [isAlert, setIsAlert] = useState([false, "메세지", false, true]);
    const fDb = fbInstance.firestore();
    const fAuth = fbInstance.auth();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [vPassword, setVPassword] = useState("");
    const [shopName, setShopName] = useState("");
    const [shopNumber, setShopNumber] = useState("");
    const [shopAddress, setShopAddress] = useState("");
    const [shopFounder, setShopFounder] = useState("");
    const [shopConnect, setShopConnect] = useState("");
    const [image, setImage] = useState(null);
    const [agreement1, setAgreement1] = useState(false);

    const onChangeEmail = t => {
        setEmail(t.nativeEvent.text);
    }
    const onChangeName = t => {
        setName(t.nativeEvent.text);
    }
    const onChangePhone = text => {
        setPhone(text.nativeEvent.text);
    }
    const onChangePw = text => {
        setPassword(text.nativeEvent.text);
    }
    const onChangeVPw = text => {
        setVPassword(text.nativeEvent.text);
    }

    const onChangeShopName = t => {
        setShopName(t.nativeEvent.text);
    }
    const onChangeShopNumber = t => {
        setShopNumber(t.nativeEvent.text);
    }
    const onChangeShopAddress = text => {
        setShopAddress(text.nativeEvent.text);
    }
    const onChangeShopFounder = text => {
        setShopFounder(text.nativeEvent.text);
    }
    const onChangeShopConnect = text => {
        setShopConnect(text.nativeEvent.text);
    }

    const onSignInNormal = async () => {
        if (!agreement1) {
            setIsAlert([true, "개인정보 보호 약관에 동의해주세요.", false, true]);
            return;
        }

        if (email.length === 0) {
            setIsAlert([true, "이메일 주소를 확인해주세요.", false, true]);
            return;
        }

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(email) === false) {
            setIsAlert([true, "이메일 형식을 확인해주세요.", false, true]);
            return;
        }

        if (password !== vPassword) {
            setIsAlert([true, "비밀번호가 일치하지 않습니다.", false, true]);
            return;
        }


        if (password.length < 6) {
            setIsAlert([true, "6자리 이상의 비밀번호를 설정해주세요.", false, true]);
        }


        if (name.length === 0 || phone.length === 0) {
            setIsAlert([true, "전화번호를 입력해주세요.", false, true]);
            return;
        }

        if (image === null) {
            setIsAlert([true, "장례지도사 자격증 사진을 첨부해주세요.", false, true]);
            return;
        }

        if (shopName.length === 0) {
            setIsAlert([true, "개인사업자 상호를 입력해주세요.", false, true]);
            return;
        }

        if (shopNumber.length === 0) {
            setIsAlert([true, "사업자등록번호를 입력해주세요.", false, true]);
            return;
        }

        if (shopAddress.length === 0) {
            setIsAlert([true, "사업주소지를 입력해주세요.", false, true]);
            return;
        }

        if (shopFounder.length === 0) {
            setIsAlert([true, "대표자 성명을 입력해주세요.", false, true]);
            return;
        }

        setIsAlert([true, "회원가입을 신청중입니다.\n잠시만 기다려주세요.\n회원가입은 관리자의 승인이 필요합니다.\n승인은 영업일기준 2~3일이 소요됩니다.", true, false]);

        try {
            let imageUrlTemp = "";
            let imagePathTemp = "";
            const randomString = Math.random().toString(36).substr(2, 11) + Math.random().toString(36).substr(2, 11) + Math.random().toString(36).substr(2, 11);

            const res = await fetch(image);
            const blob = await res.blob();

            const storegeService = fbInstance.storage();

            const fileRef = storegeService.ref().child(`licenseImage/${randomString}`);
            imagePathTemp = randomString;
            const imageUploadRes = await fileRef.put(blob);
            imageUrlTemp = await imageUploadRes.ref.getDownloadURL();

            const response = await fAuth.createUserWithEmailAndPassword(email, password);
            if (response.user.uid) {
                const user = {
                    uid: response.user.uid,
                    email: email,
                    name: name,
                    phone: phone,
                    userCode: 3,
                    licenseUrl: imageUrlTemp,
                    licenseImagePath: imagePathTemp,
                    signInDate: moment().format('YYYY/MM/DD'),
                    holding: false,
                    comment: "",
                    history: [],
                    shopName: shopName,
                    shopNumber: shopNumber,
                    shopAddress: shopAddress,
                    shopFounder: shopFounder,
                    shopConnect: shopConnect.length === 0 ? "무소속" : shopConnect
                }

                fDb.collection('GUsers')
                    .doc(response.user.uid)
                    .set(user);

                setIsAlert([false, "", true, false]);
                navigation.goBack();
                navigation.goBack();
                setIsAlert([false, "", true, false]);
            }
        } catch (e) {
            setIsAlert([true, "회원가입을 실패했습니다.", false, true]);
        }
    }

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            quality: 0.1
        });

        if (!result.cancelled) {
            const manipResult = await ImageManipulator.manipulateAsync(
                result.uri,
                [{ rotate: 0 }],
                { compress: 0.15, format: ImageManipulator.SaveFormat.JPEG }
            );


            setImage(manipResult.uri);
        }
        else {
            setImage(null);
            setIsAlert([true, "사진 선택이 취소 되었습니다.", false, true, 0, false]);
        }
    };

    return (
        <View style={{ width: '100%', flex: 1, alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView showsHorizontalScrollIndicator={false} style={{ backgroundColor: GetColors(0), width: 400 }}>
                <BackView>
                    <GuideText1 style={{ paddingTop: 20 }}>안녕하세요.</GuideText1>
                    <View style={{ flexDirection: 'row', paddingTop: 3, alignItems: 'center' }}>
                        <GuideText4>국화</GuideText4>
                        <GuideText1>에 오신것을 환영합니다.</GuideText1>
                    </View>
                    <GuideText2 style={{ paddingTop: 20 }}>이메일</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="email-address"
                        placeholder="예: gukwha@naver.com"
                        value={email}
                        onChange={onChangeEmail}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>이름</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="홍길동"
                        value={name}
                        onChange={onChangeName}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>전화번호</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="number-pad"
                        placeholder="010 1234 1234"
                        value={phone}
                        onChange={onChangePhone}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>비밀번호</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="default"
                        secureTextEntry={true}
                        placeholder="영문, 숫자 조합 최소 6자 이상"
                        value={password}
                        onChange={onChangePw}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>비밀번호 확인</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="default"
                        secureTextEntry={true}
                        textContentType="password"
                        placeholder="비밀번호를 다시한번 입력해주세요."
                        value={vPassword}
                        onChange={onChangeVPw}></TextInput>
                    <View style={{ flexDirection: 'row', marginTop: 25, alignItems: 'center' }}>
                        <GuideText4>장례지도사 등록정보</GuideText4>
                    </View>
                    <GuideText2 style={{ paddingTop: 20 }}>장례지도사 자격증</GuideText2>
                    <TouchableOpacity onPress={pickImage} style={{ marginTop: 10, padding: 10, justifyContent: 'center', alignItems: 'center', borderWidth: 1, borderRadius: 10 }}>
                        {image == null ?
                            <MaterialCommunityIcons name="image-filter-none" size={70} color="black" />
                            :
                            <Image
                                source={{ uri: image }}
                                style={{
                                    borderRadius: 10,
                                    width: 230,
                                    height: 350,
                                }} />}
                        <GuideText1 style={{ paddingTop: 20 }}>자격증 사진 가져오기</GuideText1>
                    </TouchableOpacity>
                    <GuideText2 style={{ paddingTop: 20 }}>상호(개인사업자)</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="국화"
                        value={shopName}
                        onChange={onChangeShopName}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>사업자등록번호(개인사업자)</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="number-pad"
                        placeholder="사업자번호를 입력해주세요."
                        value={shopNumber}
                        onChange={onChangeShopNumber}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>사업주소지(개인사업자)</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="예) 인천 남동구 논현동 447"
                        value={shopAddress}
                        onChange={onChangeShopAddress}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>대표자성명</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="예) 홍길동"
                        value={shopFounder}
                        onChange={onChangeShopFounder}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>소속(선택사항)</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="예) 프리드 상조"
                        value={shopConnect}
                        onChange={onChangeShopConnect}></TextInput>
                    <GuideText1 style={{ paddingTop: 20 }}>개인정보 보호약관</GuideText1>
                    <TouchableOpacity onPress={() => Linking.openURL(`https://theonplatform.imweb.me/terms`)}>
                        <GuideText1 style={{ color: GetColors(5) }}>https://theonplatform.imweb.me/terms</GuideText1>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{ marginTop: 10, flexDirection: 'row', height: 30, alignItems: 'center' }}
                        onPress={() => setAgreement1(!agreement1)}>
                        {agreement1 ?
                            <AntDesign name="checkcircleo" size={25} color={GetColors(5)} />
                            :
                            <FontAwesome name="circle-thin" size={29} color='grey' />
                        }
                        <GuideText6 style={{ color: agreement1 ? GetColors(5) : 'grey' }}>  개인정보 보호 약관 동의</GuideText6>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ marginTop: 30, justifyContent: 'center', alignItems: 'center', height: 45, borderRadius: 30, backgroundColor: GetColors(5) }}
                        onPress={onSignInNormal}>
                        <GuideText3>회원가입</GuideText3>
                    </TouchableOpacity>
                    <GuideText3>{"\n"}{"\n"}{"\n"}{"\n"}</GuideText3>
                </BackView>
                <AwesomeAlert
                    show={isAlert[0]}
                    showProgress={isAlert[2]}
                    title="국화"
                    message={isAlert[1]}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showCancelButton={false}
                    showConfirmButton={isAlert[3]}
                    cancelText=""
                    confirmText=" 확인 "
                    confirmButtonColor="#000000"
                    onCancelPressed={() => {
                        setIsAlert([false, "", false, true]);
                    }}
                    onConfirmPressed={() => {
                        setIsAlert([false, "", false, true]);
                    }}
                />
            </KeyboardAwareScrollView>
        </View>
    )
}

export default SignInNormal;