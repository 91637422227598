import React, { useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { NavigationContainer } from '@react-navigation/native';
import Stack from './navigation/Stack';

export default function App() {

  return (
    <>
      <NavigationContainer>
        <Stack />
      </NavigationContainer>
      <StatusBar Style="black" />
    </>
  );
}
