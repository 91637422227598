import React, { useState, useEffect } from "react";
import { View, Text, ScrollView } from "react-native";
import styled from "styled-components/native"

const RoomImage = styled.Image`
    margin-right: 5px;
    margin-left: 5px;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

const HorizontalScrollViewImageComponent = ({ roomPics }) => {
    return (
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {roomPics.map((pic) => (
                <RoomImage
                    style={{
                        borderRadius: 20,
                        width: 150,
                        height: 150
                    }}
                    key={Math.random().toString()}
                    source={{ uri: pic.imageUrl }} />
            ))}
        </ScrollView>
    )
}

export default React.memo(HorizontalScrollViewImageComponent);