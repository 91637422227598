import React, { useState, useEffect } from "react";
import { View, Text, Dimensions, ActivityIndicator, TouchableOpacity, Image } from "react-native";
import fbInstance from "../Config/fbInstance";
import styled from "styled-components/native"
import { GetAddress, GetRoomStateName, GetColors } from "../Config/Datas"
import HorizontalScrollViewImageComponent from "../Components/HorizontalScrollViewImageComponent";
import AwesomeAlert from 'react-native-awesome-alerts';
import { SimpleLineIcons, Feather, MaterialCommunityIcons } from '@expo/vector-icons';
import { Button } from "react-native";

const { width: WIDTH, height: HEIGHT } = Dimensions.get("window");

const RView = styled.View`
    flex-direction: row;
`;

const LineView = styled.View`
    margin-top: 15px;
    margin-bottom: 15px;
    height: 0.3px;
    background: ${GetColors(4)};
    justify-content: center;
`;

const UploadImage = styled.Image`
    justify-content: center;
    align-items: center;
`;

const BackView = styled.ScrollView`
    /* padding-top: ${HEIGHT / 15}px; */
    background: ${GetColors(0)};
`;

const GuideText0 = styled.Text`
    font-size: 16px;
    font-weight: 700;
    color: ${GetColors(0)};
`;

const GuideText1 = styled.Text`
    font-size: 16px;
    font-weight: 700;
    color: ${GetColors(1)};
`;


const GuideText2 = styled.Text`
    font-size: 11px;
    font-weight: 500;
`;

const GuideText22 = styled.Text`
    font-size: 15px;
    font-weight: 600;
    color: ${GetColors(1)};
    width: 90px;
`;

const GuideText3 = styled.Text`
    font-size: 12px;
    font-weight: 700;
    color: ${GetColors(1)};
`;

const GuideText4 = styled.Text`
    font-size: 14px;
    font-weight: 600;
    color: ${GetColors(2)};
`;

const GuideText5 = styled.Text`
    font-size: 14px;
    font-weight: 500;
    color: ${GetColors(5)};
`;

const GuideText6 = styled.Text`
    font-size: 16px;
    font-weight: 500;
    color: ${GetColors(9)};
`;

const GuideText7 = styled.Text`
    font-size: 12px;
    font-weight: 600;
    color: ${GetColors(20)};
`;

const GuideText77 = styled.Text`
    font-size: 14px;
    font-weight: 600;
    color: ${GetColors(20)};
`;

const GuideText8 = styled.Text`
    font-size: 24px;
    font-weight: 700;
    color: ${GetColors(5)};
`;

const GuideText9 = styled.Text`
    font-size: 12px;
    font-weight: 300;
    color: ${GetColors(1)};
`;

const GuideText10 = styled.Text`
    font-size: 14px;
    font-weight: 600;
    color: ${GetColors(0)};
`;

const PMView = styled.TouchableOpacity`

`;

const code1Array = GetAddress(0);
const code2Array = GetAddress(1);
const roomStateName = GetRoomStateName();

let during = 3;
let roomBookStart = "";
// let userInfo = null;

const Detail = ({ navigation, route }) => {
    let running = true;
    const fAuth = fbInstance.auth();
    const fDb = fbInstance.firestore();
    const isLogin = fAuth.currentUser;
    const userUid = isLogin === null ? "" : fAuth.currentUser.uid;
    const companyUid = route.params.companyUid;
    const [storeInfo, setStoreInfo] = useState(null);
    const [roomStateNameArr, setRoomStateNameArr] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [roomBookEnd, setRoomBookEnd] = useState();
    const [isAlert, setIsAlert] = useState([false, false, "", false, -1]);

    useEffect(() => {
        if (running) {
            fDb.collection("StoreInfo").doc(companyUid).onSnapshot((doc) => {
                setStoreInfo(doc.data());
                getRoomState(doc.data().roomInfo);
            });

            const startDate = new Date();
            roomBookStart = startDate.getFullYear() + "," + (startDate.getMonth() + 1) + "," + startDate.getDate();
            // setRoomBookStart(startDate.getFullYear() + "," + (startDate.getMonth() + 1) + "," + startDate.getDate());
            const endDate = new Date(startDate.setDate(startDate.getDate() + during));
            setRoomBookEnd(endDate.getFullYear() + "," + (endDate.getMonth() + 1) + "," + endDate.getDate());
            // userInfo
            if (userUid !== "") {
                fDb.collection('GUsers').doc(userUid).get().then((doc) => {
                    setUserInfo(doc.data());
                })
            }

        }
        return () => {
            running = false;
        }
    }, []);

    const getRoomState = (roomInfoTemp) => {
        let tempArr = [];
        for (let i = 0; i < roomInfoTemp.length; i++) {
            let stringTemp = "";
            let stateArrTemp = roomInfoTemp[i].roomState.split(',');

            for (let j = 0; j < stateArrTemp.length; j++) {
                if (stateArrTemp[j] === 'true') {
                    stringTemp = stringTemp + roomStateName[j] + ",";
                }
            }
            tempArr.push(stringTemp);
        }

        setRoomStateNameArr(tempArr);
    }

    const changeEndDate = (plusMinus) => {
        if (plusMinus === "-") {
            if (during <= 1)
                return;
            during = during - 1;
            const startDate = new Date();
            const endDate = new Date(startDate.setDate(startDate.getDate() + during));
            setRoomBookEnd(endDate.getFullYear() + "," + (endDate.getMonth() + 1) + "," + endDate.getDate());
        }
        else {
            if (during >= 5)
                return;
            during = during + 1;
            const startDate = new Date();
            const endDate = new Date(startDate.setDate(startDate.getDate() + during));
            setRoomBookEnd(endDate.getFullYear() + "," + (endDate.getMonth() + 1) + "," + endDate.getDate());
        }
    }

    const onBookBtnClicked = async (index) => {
        if (isLogin === null) {
            setIsAlert([true, false, "로그인이 필요한 서비스입니다.\n로그인 및 회원가입 후 이용해 주세요.", false, -1]);
        }
        else {
            if (userInfo.userCode === 0) {
                setIsAlert([true, false, "장례식장 사용자 입니다.\n지도사 계정으로 재 로그인 후 이용해주세요.", false, -1]);
            }
            else {
                const guideText = `${storeInfo.companyName} 장례식장\n\n예약일자\n시작일 : ${roomBookStart.split(',')[0]}년 ${roomBookStart.split(',')[1]}월 ${roomBookStart.split(',')[2]}일 (오늘)부터\n발인일 : ${roomBookEnd.split(',')[0]}년 ${roomBookEnd.split(',')[1]}월 ${roomBookEnd.split(',')[2]}일 까지\n예약자 성함 : ${userInfo.name}\n\n위 정보로 예약하시겠습니까?`;
                setIsAlert([true, false, guideText, true, index]);
            }
        }
    }

    const onBookConfirm = async () => {
        const index = isAlert[4];

        navigation.navigate('예약 확정', {
            roomBookStart: roomBookStart,
            roomBookEnd: roomBookEnd,
            storeInfo: storeInfo,
            userInfo: userInfo,
            index: index,
            duration: during
        });

        // setIsAlert([true, true, "예약중입니다.\n\n잠시만 기다려주세요.", true, -1]);

        // const startDate = `${roomBookStart.split(',')[0]}년 ${roomBookStart.split(',')[1]}월 ${roomBookStart.split(',')[2]}일`;
        // const endDate = `${roomBookEnd.split(',')[0]}년 ${roomBookEnd.split(',')[1]}월 ${roomBookEnd.split(',')[2]}일`;

        // let tempStoreInfo = storeInfo;
        // let targetRoom = tempStoreInfo.roomInfo[index];
        // targetRoom.roomBookStart = startDate;
        // targetRoom.roomBookEnd = endDate;
        // targetRoom.roomBookState = false;
        // targetRoom.roomBookUserUid = userUid;
        // targetRoom.roomBookUserInfo = userInfo.name + " " + userInfo.phone;
        // tempStoreInfo.roomInfo[index] = targetRoom;

        // // 예약정보 업데이트
        // fDb.collection('StoreInfo').doc(companyUid).update(tempStoreInfo);

        // // 예약정보 업데이트 - 유저 히스토리 부분
        // fDb.collection('UserHistory').doc(userUid).get().then((doc) => {
        //     if (doc.exists) {
        //         const addHistoryData = {
        //             storeUid: companyUid,
        //             storeName: storeInfo.companyName,
        //             roomName: targetRoom.roomName,
        //             roomPrice: targetRoom.roomPrice,
        //             startDate: startDate,
        //             endDate: endDate,
        //             saleRate: storeInfo.roomInfo[index].roomSaleRate
        //         }
        //         // 내역이 있음
        //         let userHistory = doc.data().history;
        //         userHistory.push(addHistoryData);

        //         const tempData = {
        //             history: userHistory
        //         }

        //         fDb.collection('UserHistory').doc(userUid).update(tempData);
        //     } else {
        //         // 내역이 없음
        //         const addHistoryData = {
        //             history: [{
        //                 storeUid: companyUid,
        //                 storeName: storeInfo.companyName,
        //                 roomName: targetRoom.roomName,
        //                 roomPrice: targetRoom.roomPrice,
        //                 startDate: startDate,
        //                 endDate: endDate,
        //                 saleRate: storeInfo.roomInfo[index].roomSaleRate
        //             }]
        //         }
        //         fDb.collection('UserHistory').doc(userUid).set(addHistoryData);
        //     }
        // })

        // // 예약정보 업데이트 - 상점 히스토리 부분
        // fDb.collection('StoreHistory').doc(companyUid).get().then((doc) => {
        //     if (doc.exists) {
        //         const addHistoryData = {
        //             userUid: userUid,
        //             userName: userInfo.name,
        //             userPhone: userInfo.phone,
        //             roomName: targetRoom.roomName,
        //             roomPrice: targetRoom.roomPrice,
        //             startDate: startDate,
        //             endDate: endDate,
        //             saleRate: storeInfo.roomInfo[index].roomSaleRate
        //         }

        //         // 내역이 있음
        //         let storeHistory = doc.data().history;
        //         storeHistory.push(addHistoryData);

        //         const tempData = {
        //             history: storeHistory
        //         }
        //         fDb.collection('StoreHistory').doc(companyUid).update(tempData);
        //     } else {
        //         // 내역이 없음
        //         const addHistoryData = {
        //             history: [{
        //                 userUid: userUid,
        //                 userName: userInfo.name,
        //                 userPhone: userInfo.phone,
        //                 roomName: targetRoom.roomName,
        //                 roomPrice: targetRoom.roomPrice,
        //                 startDate: startDate,
        //                 endDate: endDate,
        //                 saleRate: storeInfo.roomInfo[index].roomSaleRate
        //             }]
        //         }

        //         fDb.collection('StoreHistory').doc(companyUid).set(addHistoryData);
        //     }
        // })

        // setIsAlert([false, false, "", true, -1]);
    }

    const goToPhotoView = (index) => {
        let imgUrl = [];
        for (let i = 0; i < storeInfo.roomInfo[index].roomPics.length; i++) {
            imgUrl.push(storeInfo.roomInfo[index].roomPics[i].imageUrl);
        }
        navigation.navigate('상세보기', { roomPics: imgUrl });
    }

    const TestFuncForFB = async () => {
        fDb.collection('UserHistory').doc(userUid).get().then((doc) => {
            if (doc.exists) {
                const addHistoryData = {
                    storeUid: companyUid,
                    storeName: storeInfo.companyName,
                    roomName: "targetRoom.roomName",
                    roomPrice: "targetRoom.roomPrice",
                    startDate: "startDate",
                    endDate: "endDate",
                    saleRate: storeInfo.roomInfo[0].roomSaleRate
                }
                // 내역이 있음
                let userHistory = doc.data().history;
                userHistory.push(addHistoryData);

                const tempData = {
                    history: userHistory
                }

                fDb.collection('UserHistory').doc(userUid).update(tempData);
            } else {
                // 내역이 없음
                const addHistoryData = {
                    history: [{
                        storeUid: companyUid,
                        storeName: storeInfo.companyName,
                        roomName: "targetRoom.roomName",
                        roomPrice: 'targetRoom.roomPrice',
                        startDate: "startDate",
                        endDate: "endDate",
                        saleRate: storeInfo.roomInfo[0].roomSaleRate
                    }]
                }
                fDb.collection('UserHistory').doc(userUid).set(addHistoryData);
            }
        })
    }

    const goToLogin = () => {
        navigation.navigate('내정보');
    }

    const goToMainPicView = () => {
        navigation.navigate('대표사진 보기', { storeInfo: storeInfo })
    }

    return (
        storeInfo === null ?
            <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
                <BackView showsHorizontalScrollIndicator={false} style={{ width: 400 }}>
                    <ActivityIndicator style={{ marginTop: 30 }}></ActivityIndicator>
                </BackView>
            </View >
            :
            <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
                <BackView showsHorizontalScrollIndicator={false} style={{ width: 400 }}>
                    <TouchableOpacity style={{
                        zIndex: 1,
                        alignSelf: 'flex-end',
                        paddingLeft: 20,
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingRight: 20
                    }} onPress={() => navigation.goBack()}>
                        <Feather name="x" size={40} color={storeInfo.mainPics.length === 0 ? "black" : "white"} />
                    </TouchableOpacity>
                    {storeInfo.mainPics.length === 0 ?
                        <View style={{ height: 260, marginTop: -85, justifyContent: 'center', alignItems: 'center' }}>
                            <GuideText1>대표사진이 없습니다.</GuideText1>
                        </View>
                        :
                        <>
                            <TouchableOpacity onPress={goToMainPicView}>
                                <UploadImage
                                    source={{ uri: storeInfo.mainPics[0].mainPicUrl }}
                                    style={{
                                        height: 450,
                                        marginTop: -85
                                    }} />
                            </TouchableOpacity>
                            <View style={{ alignItems: 'flex-end', marginTop: -25 }}>
                                <View style={{
                                    backgroundColor: GetColors(6),
                                    borderTopLeftRadius: 10,
                                    borderBottomLeftRadius: 10,
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                    width: 70,
                                    height: 25
                                }}>
                                    <GuideText1 style={{ padding: 5 }}>총 {storeInfo.mainPics.length} 장</GuideText1>
                                </View>
                            </View>
                        </>
                    }
                    <View style={{
                        paddingLeft: 30, paddingRight: 30, backgroundColor: 'white'
                    }}>
                        <View style={{ height: 40, alignItems: 'center', justifyContent: 'center' }}>
                            <GuideText1>{storeInfo.companyName}</GuideText1>
                        </View>
                        <View style={{ flexDirection: 'row', height: 20, marginBottom: 3, alignItems: 'center' }}>
                            <GuideText22>주소</GuideText22>
                            <GuideText2 style={{ paddingLeft: 5 }}>{code1Array[storeInfo.code1].label} {code2Array[storeInfo.code1][parseInt(storeInfo.code2) + 1].label} {storeInfo.companyAddress}</GuideText2>
                        </View>
                        <View style={{ flexDirection: 'row', height: 20, marginBottom: 3, alignItems: 'center' }}>
                            <GuideText22>전화번호</GuideText22>
                            <GuideText2 style={{ paddingLeft: 5 }}>{storeInfo.companyPhone}</GuideText2>
                        </View>
                        <View style={{ flexDirection: 'row', height: 20, marginBottom: 3, alignItems: 'center' }}>
                            <GuideText22>홈페이지</GuideText22>
                            <GuideText2 style={{ paddingLeft: 5 }}>{storeInfo.homepage}  </GuideText2>
                        </View>
                        <View style={{ flexDirection: 'row', height: 20, alignItems: 'center' }}>
                            <GuideText22>운영형태</GuideText22>
                            <GuideText2 style={{ color: GetColors(1) }}>안치능력 </GuideText2>
                            <GuideText2 style={{ color: GetColors(12) }}>{storeInfo.capacity} 구  </GuideText2>
                            <GuideText2 style={{ color: GetColors(1) }}>빈소수 </GuideText2>
                            <GuideText2 style={{ color: GetColors(12) }}>{storeInfo.roomInfo.length} 개  </GuideText2>
                            <GuideText2 style={{ color: GetColors(1) }}>주차가능여부 </GuideText2>
                            <GuideText2 style={{ color: GetColors(12) }}>{storeInfo.companyParking === false ? "무료주차" : "유료주차"}  </GuideText2>
                        </View>
                        <LineView></LineView>
                        {/* <Button title="test" onPress={TestFuncForFB}></Button> */}
                        <GuideText3 style={{ marginBottom: 10 }}>빈소안내</GuideText3>
                        {storeInfo.roomInfo.map((roomTemp, index) => (
                            <View key={roomTemp.id} style={{ marginBottom: 15 }}>
                                <GuideText3 style={{ marginBottom: 3, textAlign: 'right' }}>{roomTemp.roomPics.length} 장</GuideText3>
                                <RView>
                                    <TouchableOpacity style={{ zIndex: 1 }} onPress={() => goToPhotoView(index)}>
                                        <Image
                                            source={{ uri: roomTemp.roomPics.length === 0 ? "https://firebasestorage.googleapis.com/v0/b/gukwha-2eb33.appspot.com/o/ready.jpeg?alt=media&token=28d57ed9-4d16-4ead-bdaa-ef873c77ae81" : roomTemp.roomPics[0].imageUrl }}
                                            style={{
                                                width: 340 / 2,
                                                height: 99,
                                                marginRight: 5
                                            }} />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ zIndex: 1 }} onPress={() => goToPhotoView(index)}>
                                        <Image
                                            source={require('../assets/viewmore.png')}
                                            style={{
                                                width: 340 / 2,
                                                height: 99,
                                            }} />
                                    </TouchableOpacity>
                                </RView>
                                <View style={{ marginTop: 10 }}>
                                    <View style={{ flexDirection: 'row', height: 20, marginBottom: 3, alignItems: 'center' }}>
                                        <GuideText22>호실</GuideText22>
                                        <GuideText2 style={{ paddingLeft: 5 }}>{roomTemp.roomName}</GuideText2>
                                    </View>
                                    <View style={{ flexDirection: 'row', height: 20, marginBottom: 3, alignItems: 'center' }}>
                                        <GuideText22>빈소규모</GuideText22>
                                        <GuideText2 style={{ paddingLeft: 5 }}>{roomTemp.roomSize}명</GuideText2>
                                    </View>
                                    <View style={{ flexDirection: 'row', height: 20, marginBottom: 3, alignItems: 'center' }}>
                                        <GuideText22>편의시설</GuideText22>
                                        <GuideText2 style={{ paddingLeft: 5 }}>{roomStateNameArr[index]}</GuideText2>
                                    </View>
                                </View>
                                {userInfo !== null ?
                                    <View style={{ flex: 1, paddingTop: 10, height: 100, flexDirection: 'row', backgroundColor: 'white' }}>
                                        <View style={{ backgroundColor: 'white', flex: 1, justifyContent: 'flex-end' }}>
                                            <View style={{ width: 70, height: 35, backgroundColor: roomTemp.roomBookState ? GetColors(6) : GetColors(21), borderRadius: 7, justifyContent: 'center', alignItems: 'center' }}>
                                                {roomTemp.roomBookState ? <GuideText5>예약가능</GuideText5> : <GuideText77>예약완료</GuideText77>}
                                            </View>
                                        </View>
                                        <View style={{ flex: 2, height: 120 }}>
                                            <View style={{ height: 25, backgroundColor: 'white', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                {userInfo.userCode === 2 ? <GuideText7>할인율 {roomTemp.roomSaleRate}%</GuideText7> : <GuideText7></GuideText7>}
                                                <GuideText4>회원 가격</GuideText4>
                                            </View>
                                            <GuideText4 style={{ textAlign: 'right' }}>24시간 기준  </GuideText4>
                                            <View style={{ height: 30, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                <GuideText8>{roomTemp.roomPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</GuideText8>
                                            </View>
                                            <View style={{ height: 25, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                                <GuideText4>(시간당 {(Math.round(roomTemp.roomPrice / 24)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원)</GuideText4>
                                            </View>
                                        </View>
                                    </View>
                                    :
                                    (<TouchableOpacity onPress={goToLogin}>
                                        <GuideText6>{"\n"}정보이용을 위해서 로그인이 필요합니다.</GuideText6>
                                    </TouchableOpacity>)
                                }
                                <LineView />
                                {userInfo === null ?
                                    <></>
                                    :
                                    roomTemp.roomBookState === true ?
                                        <>
                                            <View style={{ flex: 1, height: 90, flexDirection: 'row', backgroundColor: 'white' }}>
                                                <View style={{ backgroundColor: 'white', flex: 1 }}>
                                                    <GuideText1>이용일</GuideText1>
                                                    <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                                                        <MaterialCommunityIcons name="calendar-range-outline" size={15} color="black" />
                                                        <GuideText9>  {roomBookStart.split(',')[0]}년 {roomBookStart.split(',')[1]}월 {roomBookStart.split(',')[2]}일 기준</GuideText9>
                                                    </View>
                                                    <GuideText1 style={{ paddingTop: 8 }}>발인일</GuideText1>
                                                    <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                                                        <MaterialCommunityIcons name="calendar-range-outline" size={15} color="black" />
                                                        <GuideText9>  {roomBookEnd.split(',')[0]}년 {roomBookEnd.split(',')[1]}월 {roomBookEnd.split(',')[2]}일</GuideText9>
                                                    </View>
                                                </View>
                                                <View style={{ flex: 1 }}>
                                                    <View style={{ paddingTop: 15, backgroundColor: 'white', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <RView style={{ alignItems: "center" }}>
                                                            <PMView onPress={() => changeEndDate("-")}>
                                                                <SimpleLineIcons name="minus" size={20} color="black" />
                                                            </PMView>
                                                            <GuideText9>    {during}일 간    </GuideText9>
                                                            <PMView onPress={() => changeEndDate("+")}>
                                                                <SimpleLineIcons name="plus" size={20} color="black" />
                                                            </PMView>
                                                        </RView>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{ alignItems: 'center' }}>
                                                <TouchableOpacity onPress={() => onBookBtnClicked(index)} style={{ width: 72, height: 26, backgroundColor: GetColors(5), borderRadius: 7, justifyContent: 'center', alignItems: 'center' }} >
                                                    <GuideText10>예약</GuideText10>
                                                </TouchableOpacity>
                                            </View>
                                            <LineView></LineView>
                                        </>
                                        :
                                        <View style={{ flex: 1, height: 90, flexDirection: 'row', backgroundColor: 'white' }}>
                                            {/* <GuideText1>예약자 정보 : {roomTemp.roomBookUserInfo.substring(0, 1)}*{roomTemp.roomBookUserInfo.substring(2, 3)} {roomTemp.roomBookUserInfo.substring(4, 7)}-****-{roomTemp.roomBookUserInfo.substring(12, 17)}</GuideText1> */}
                                        </View>}
                            </View>
                        ))}
                        <GuideText1>{"\n"}{"\n"}{"\n"}{"\n"}{"\n"}{"\n"}</GuideText1>
                    </View>
                    <AwesomeAlert
                        show={isAlert[0]}
                        showProgress={isAlert[1]}
                        title="국화"
                        message={isAlert[2]}
                        closeOnTouchOutside={false}
                        closeOnHardwareBackPress={false}
                        showCancelButton={isAlert[3]}
                        showConfirmButton={true}
                        cancelText=" 취소 "
                        confirmText=" 확인 "
                        confirmButtonColor="#000000"
                        onCancelPressed={() => {
                            setIsAlert([false, false, "", true, -1]);
                        }}
                        onConfirmPressed={() => {
                            if (isAlert[4] !== -1) {
                                onBookConfirm();
                            }
                            setIsAlert([false, false, "", true, isAlert[4]]);
                        }}
                    />
                </BackView>
            </View>
    );
}

export default Detail;

