import React, { useState, useEffect } from "react";
import { Button, Dimensions, View, TouchableOpacity, Switch, TextInput } from "react-native";
import styled from "styled-components/native";
import Profile from "./Profile";
import Store from "./Store";
import fbInstance from "../Config/fbInstance";
import { GetColors } from "../Config/Datas";
import { Entypo } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

const { width: WIDTH, height: HEIGHT } = Dimensions.get("window");

// 스토어

const BackView = styled.View`
    width: 550;
    padding-left: 25px;
    padding-right: 25px;
    background-color: ${GetColors(0)};
`;

const SGuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 600;
`;

// 로그인

const RowView = styled.View`
    flex-direction: row;    
`;

const LineView = styled.View`
    height: 0.3px;
    background: ${GetColors(16)};
`;

const GuideText1 = styled.Text`
    font-size: 22px;
    font-weight: 600;
`;

const GuideText2 = styled.Text`
    font-size: 16px;
    font-weight: 600;
    color: ${GetColors(0)};
`;

const GuideText3 = styled.Text`
    font-size: 16px;
    font-weight: 700;
    color: ${GetColors(2)};
`;

const GuideText4 = styled.Text`
    font-size: 12px;
    font-weight: 400;
    color: ${GetColors(2)};
`;

const GuideText5 = styled.Text`
    font-size: 13px;
    font-weight: 600;
    color: ${GetColors(0)};
`;


let isFirst = true;
const fDb = fbInstance.firestore();
const fAuth = fbInstance.auth();


const Users = ({ navigation }) => {
    fAuth.useDeviceLanguage();
    const [isNormal, setIsNormal] = useState(-1);
    const [isLogin, setIsLogin] = useState(fAuth.currentUser);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isAlert, setIsAlert] = useState([false, "", false, true, 0, false]);

    useEffect(() => {
        if (fAuth.currentUser === null) {
            return;
        }
        fDb.collection('GUsers').doc(fAuth.currentUser.uid).get().then((doc) => {
            if (doc.data().userCode === 0) {
                setIsNormal(0);
            }
            else {
                setIsNormal(1);
            }
            setIsLogin(fAuth.currentUser);
        });
    }, [fAuth.currentUser])

    navigation.addListener('tabPress', (e) => {
        setIsLogin(fAuth.currentUser);
        if (fAuth.currentUser != null) {
            fDb.collection('GUsers').doc(fAuth.currentUser.uid).get().then((doc) => {
                if (doc.data().userCode === 0) {
                    setIsNormal(0);
                }
                else {
                    setIsNormal(1);
                }
                setIsLogin(fAuth.currentUser);
            });
        }
    });

    const onChangeEmail = t => {
        setEmail(t.nativeEvent.text);
    }

    const onChangePw = text => {
        setPassword(text.nativeEvent.text);
    }

    const onSignInPressed = () => {
        navigation.navigate('회원가입');
    }

    const confirmSendResetPasswordEmail = async () => {
        await fAuth.sendPasswordResetEmail(email)
            .then(() => {
                setIsAlert([true, email + " 의 주소로 비밀번호 재설정 메일을 전송하였습니다.\n메일이 오지 않았다면 스팸 메일함을 먼저 확인해주세요.", false, true]);
            })
            .catch((error) => {
                setIsAlert([true, "입력하신 이메일로\n비밀번호 재설정 메일 전송이 실패하였습니다.\n다시한번 확인해주세요.", false, true]);
            });
    }

    const sendResetPasswordEmail = async () => {
        if (email.length === 0) {
            setIsAlert([true, "이메일을 입력해주세요.", false, true, 0, false]);
            return;
        }

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(email) === false) {
            setIsAlert([true, "이메일 형식을 확인해주세요.", false, true]);
            return;
        }

        setIsAlert([true, "입력하신\n" + email + " 로 비밀번호 재설정 이메일을 발송하시겠습니까?", false, true, 1]);

        // await fAuth.sendPasswordResetEmail(email)
        //     .then(() => {
        //         setIsAlert([true, email + " 의 주소로 비밀번호 재설정 메일을 전송하였습니다.\n메일이 오지 않았다면 스팸 메일함을 먼저 확인해주세요.", false, true]);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    }

    const onLoginPressed = async () => {
        if (email.length === 0) {
            setIsAlert([true, "이메일을 입력해주세요.", false, true, 0, false]);
            return;
        }

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(email) === false) {
            setIsAlert([true, "이메일 형식을 확인해주세요.", false, true]);
            return;
        }

        if (password.length === 0 || password.length < 6) {
            setIsAlert([true, "비밀번호를 입력해주세요.", false, true, 0, false]);
            return;
        }

        setIsAlert([true, "로그인 중입니다.\n잠시만 기다려주세요.", true, false, 0, false]);

        await fAuth.signInWithEmailAndPassword(email, password)
            .then((response) => {
                setEmail("");
                setPassword("");

                fDb.collection('GUsers').doc(fAuth.currentUser.uid).get().then((doc) => {
                    if (doc.data().userCode === 0) {
                        setIsNormal(0);
                    }
                    else {
                        setIsNormal(1);
                    }
                    setIsLogin(fAuth.currentUser);
                    setIsAlert([false, "", true, false, 0, false]);
                });
            })
            .catch((e) => {
                setIsAlert([true, "로그인에 실패했습니다.\n다시 시도해주세요.", false, true, 0, false]);
            });
    }

    return (
        <>
            {isLogin === null ?
                <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
                    <KeyboardAwareScrollView style={{
                        backgroundColor: GetColors(0), width: 400
                    }}>
                        <View style={{ paddingLeft: 45, paddingRight: 45 }}>
                            <GuideText1 style={{ paddingTop: 50 }}>로그인이 필요합니다.</GuideText1>
                            <RowView style={{ paddingTop: 73 }}>
                                <Entypo style={{ flex: 1 }} name="mail" size={22} color="black" />
                                <TextInput style={{ flex: 9, paddingLeft: 15, fontSize: 16 }}
                                    placeholder="이메일"
                                    keyboardType="email-address"
                                    value={email}
                                    onChange={onChangeEmail}></TextInput>
                            </RowView>
                            <LineView></LineView>
                            <RowView style={{ paddingTop: 10 }}>
                                <Entypo style={{ flex: 1 }} name="lock" size={22} color="black" />
                                <TextInput style={{ flex: 9, paddingLeft: 15, fontSize: 16 }}
                                    placeholder="비밀번호"
                                    secureTextEntry={true}
                                    value={password}
                                    onChange={onChangePw}></TextInput>
                            </RowView>
                            <LineView style={{ marginTop: 3 }}></LineView>
                            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <TouchableOpacity style={{ marginTop: 38, backgroundColor: GetColors(5), borderRadius: 25, width: 285, hegith: 45 }}
                                    onPress={onLoginPressed}>
                                    <GuideText2 style={{ height: 45, paddingTop: 13, paddingBottom: 13, justifyContent: 'center', textAlign: 'center' }}>로그인</GuideText2>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ marginTop: 10, backgroundColor: GetColors(0), borderColor: GetColors(5), borderWidth: 1, borderRadius: 25, width: 285, hegith: 35 }}
                                    onPress={sendResetPasswordEmail}>
                                    <GuideText2 style={{ height: 45, paddingTop: 13, paddingBottom: 13, justifyContent: 'center', textAlign: 'center', color: GetColors(5) }}>비밀번호 재설정 이메일 보내기</GuideText2>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={{ paddingLeft: 45, paddingRight: 45, height: 300, marginTop: 114, backgroundColor: GetColors(3) }}>
                            <GuideText3 style={{ paddingTop: 35, paddingBottom: 5 }}>아직도</GuideText3>
                            <GuideText3 style={{ paddingBottom: 8 }}>국화의 회원이 아니세요?</GuideText3>
                            <GuideText4>지금 회원가입하고 국화의 혜택을 누려보세요</GuideText4>
                            <TouchableOpacity style={{ marginLeft: 150, marginTop: 11, backgroundColor: GetColors(5), borderRadius: 25, width: 135, height: 32, justifyContent: 'center' }}
                                onPress={onSignInPressed}>
                                <GuideText5 style={{ paddingTop: 10, paddingBottom: 10, textAlign: 'center' }}>회원가입</GuideText5>
                            </TouchableOpacity>
                        </View>
                        <AwesomeAlert
                            show={isAlert[0]}
                            showProgress={isAlert[2]}
                            title="국화"
                            message={isAlert[1]}
                            closeOnTouchOutside={true}
                            closeOnHardwareBackPress={true}
                            showCancelButton={isAlert[5]}
                            showConfirmButton={isAlert[3]}
                            cancelText=" 취소 "
                            confirmText=" 확인 "
                            confirmButtonColor="#000000"
                            onCancelPressed={() => {
                                setIsAlert([false, "", false, true, 0, false]);
                            }}
                            onConfirmPressed={() => {
                                if (isAlert[4] === 1) {
                                    // actAskUserLevelUp();
                                    confirmSendResetPasswordEmail();
                                }
                                else {
                                    setIsAlert([false, "", false, true, 0, false]);
                                }
                            }}
                        />
                    </KeyboardAwareScrollView>
                </View>
                :
                isNormal === 0 ?
                    <Store navigation={navigation} />
                    :
                    isNormal === 1
                        ? <Profile navigation={navigation} />
                        :
                        <View style={{ flex: 1, backgroundColor: GetColors(0) }}>
                            <GuideText1> </GuideText1>
                        </View>}
        </>
    )
}

export default Users;

