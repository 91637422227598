import React, { useLayoutEffect } from "react";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Home from "../screens/Home";
import User from "../screens/User"
import Notice from "../screens/Notice"
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
import { GetColors } from "../Config/Datas";
import Welcome from "../screens/Welcome";

const Tabs = createBottomTabNavigator();

const getHeaderTitle = (route) => {
    const routeName = getFocusedRouteNameFromRoute(route) ?? "XYZ";
    switch (routeName) {
        // case "웰컴":
        //     return "국화";
        case "홈":
            return "국화";
        case "공지":
            return "공지사항";
        case "내정보":
            return "마이페이지";
    }
}

export default ({ navigation, route }) => {
    useLayoutEffect(() => {
        navigation.setOptions({ title: getHeaderTitle(route) });
    }, [route]);

    return (

        <Tabs.Navigator
            screenOptions={({ route }) => ({
                width: '100%',
                tabBarIcon: ({ focused }) => {
                    let iconName = "";
                    let iconNameWhenFocused = "";
                    // if (route.name === "웰컴") {
                    //     iconName += "home";
                    //     iconNameWhenFocused += "home-outline";
                    //     return <MaterialCommunityIcons name={focused ? iconName : iconNameWhenFocused} color={focused ? GetColors(5) : GetColors(10)} size={28} />;
                    // }
                    // else 
                    if (route.name === "홈") {
                        iconName += "home";
                        iconNameWhenFocused += "home-outline";
                        return <MaterialCommunityIcons name={focused ? iconName : iconNameWhenFocused} color={focused ? GetColors(5) : GetColors(10)} size={28} />;
                    }
                    else if (route.name === "공지") {
                        iconName += "bell";
                        iconNameWhenFocused += "bell-outline";
                        return <MaterialCommunityIcons name={focused ? iconName : iconNameWhenFocused} color={focused ? GetColors(5) : GetColors(10)} size={28} />;
                    }
                    else if (route.name === "내정보") {
                        iconName += "account-circle";
                        iconNameWhenFocused += "account-circle-outline";
                        return <MaterialCommunityIcons name={focused ? iconName : iconNameWhenFocused} color={focused ? GetColors(5) : GetColors(10)} size={28} />;
                    }
                }
            })}
            tabBarOptions={{
                activeTintColor: GetColors(5),
                inactiveTintColor: GetColors(10),
                showLabel: true,
                labelStyle: {
                    fontSize: 15,
                    fontWeight: 'bold'
                },
                style: {
                    height: 90
                }
            }}>
            {/* <Tabs.Screen name="웰컴" component={Welcome} /> */}
            <Tabs.Screen name="홈" component={Home} />
            <Tabs.Screen name="공지" component={Notice} />
            <Tabs.Screen name="내정보" component={User} />
        </Tabs.Navigator>
    )
}
