import React from "react";
import { createStackNavigator } from '@react-navigation/stack';
import Detail from "../screens/Detail";
import Tabs from "./Tabs";
import Store from "../screens/Store";
import StoreDetail from "../screens/StoreDetail";
import StoreRoomDetail from "../screens/StoreRoomDetail";
import SignIn from "../screens/SignIn";
import SignInNormal from "../screens/SignInNormal";
import SignInType0 from "../screens/SignInType0";
import ChooseAccountType from "../screens/ChooseAccountType";
import Photo from "../screens/Photo";
import NormalInfo from "../screens/NormalInfo";
import StoreInfo from "../screens/StoreInfo";
import RequestCertification from "../screens/RequestCertification"
import StoreMainPics from "../screens/StoreMainPics";
import StoreMainPicsView from "../screens/StoreMainPicsView";
import BookConfirm from "../screens/BookConfirm";
import ReservationDetails from "../screens/ReservationDetails";

const StackNavigation = createStackNavigator();

const Stack = ({ }) => {

    return (
        <StackNavigation.Navigator
            mode="modal">
            <StackNavigation.Screen name="국화" component={Tabs} options={{headerTitleAlign: 'left'}} />
            <StackNavigation.Screen name="상세정보" component={Detail} options={{headerShown: false}}/>
            <StackNavigation.Screen name="예약 확정" component={BookConfirm}/>
            <StackNavigation.Screen name="업체 등록 정보" component={Store} />
            <StackNavigation.Screen name="업체 기본 정보 수정" component={StoreDetail} />
            <StackNavigation.Screen name="대표사진" component={StoreMainPics} />
            <StackNavigation.Screen name="대표사진 보기" component={StoreMainPicsView} />
            <StackNavigation.Screen name="방 상세 정보 수정" component={StoreRoomDetail} />
            <StackNavigation.Screen name="장례식장 회원가입" component={SignIn} />
            <StackNavigation.Screen name="지도사 회원가입" component={SignInNormal} />
            <StackNavigation.Screen name="일반 회원가입" component={SignInType0} />
            <StackNavigation.Screen name="지도사 회원정보수정" component={NormalInfo} />
            <StackNavigation.Screen name="식장 회원정보수정" component={StoreInfo} />
            <StackNavigation.Screen name="장례지도사 인증신청" component={RequestCertification} />
            <StackNavigation.Screen name="회원가입" component={ChooseAccountType} />
            <StackNavigation.Screen name="상세보기" component={Photo} />
            <StackNavigation.Screen name="예약내역" component={ReservationDetails} />
        </StackNavigation.Navigator>
    )

}

export default Stack;

