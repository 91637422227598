import React, { useState, useEffect } from "react";
import { View, Text, Image, Dimensions, TouchableOpacity, TextInput } from "react-native"
import fbInstance from "../Config/fbInstance";
import styled from "styled-components/native";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import AwesomeAlert from 'react-native-awesome-alerts';
import moment from "moment";
import { GetColors } from '../Config/Datas';

// const windowHeight = Dimensions.get('window').height;
// const windowWidth = Dimensions.get('window').width;

const BackView = styled.View`
    padding-left: 45px;
    padding-right: 45px;
    background-color: ${GetColors(0)};
`;

const GuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 500;
`;


const GuideText11 = styled.Text`
    font-size: 20px;
    font-weight: 500;
`;

const GuideText2 = styled.Text`
    font-size: 18px;
    font-weight: 600;
`;


const GuideText22 = styled.Text`
    font-size: 15px;
    font-weight: 500;
    color: ${GetColors(5)};
`;

const GuideText3 = styled.Text`
    font-size: 20px;
    font-weight: 700;
    color: ${GetColors(0)};
`;

const GuideText4 = styled.Text`
    font-size: 18px;
    font-weight: 700;
    color: ${GetColors(5)};
`;

const GuideText5 = styled.Text`
    font-size: 14px;
    font-weight: 500;
    color: ${GetColors(17)};
`;

// 7개
const StoreInfo = ({ navigation, route }) => {
    const [isAlert, setIsAlert] = useState([false, "메세지", false, true, -1]);
    const fDb = fbInstance.firestore();
    const fAuth = fbInstance.auth();
    let storeInfo = route.params.storeInfo;
    // let user = route.params.user;
    const [confirm, setConfirm] = useState(false);
    const [password, setPassword] = useState("");
    const [vPassword, setVPassword] = useState("");
    const [email, setEmail] = useState(storeInfo.email);
    const [companyName, setCompanyName] = useState(storeInfo.companyName);
    const [companyBossName, setCompanyBossName] = useState(storeInfo.companyBossName);
    const [companyInchargePerson, setCompanyInchargePerson] = useState(storeInfo.companyInchargePerson);
    const [companyBusinessNumber, setCompanyBusinessNumber] = useState(storeInfo.companyBusinessNumber);
    const [companyPhone, setCompanyPhone] = useState(storeInfo.companyPhone);
    const [homepage, setHomepage] = useState(storeInfo.homepage);

    const onChangePw = text => {
        setPassword(text.nativeEvent.text);
    }
    const onChangeVPw = text => {
        setVPassword(text.nativeEvent.text);
    }

    const onChangeEmail = t => {
        setEmail(t.nativeEvent.text);
    }

    const onChangeCompanyName = t => {
        setCompanyName(t.nativeEvent.text);
    }

    const onChangeCompanyBossName = t => {
        setCompanyBossName(t.nativeEvent.text);
    }

    const onChangeInchargePerson = t => {
        setCompanyInchargePerson(t.nativeEvent.text);
    }

    const onChangeBusinessNumber = t => {
        setCompanyBusinessNumber(t.nativeEvent.text);
    }

    const onChangeCompanyPhone = t => {
        setCompanyPhone(t.nativeEvent.text);
    }

    const onChangeHomePage = t => {
        setHomepage(t.nativeEvent.text);
    }

    const actChangeStoreInfo = async () => {
        if (companyName.length === 0 ||
            companyBossName.length === 0 ||
            companyInchargePerson.length === 0 ||
            companyBusinessNumber.length === 0 ||
            companyPhone.length === 0) {
            setIsAlert([true, "입력정보를 확인해주세요.", false, true, -1]);
            return;
        }

        setIsAlert([true, "회원정보를 업데이트중입니다.\n잠시만 기다려주세요.", true, false, -1]);
        storeInfo.companyName = companyName;
        storeInfo.companyBossName = companyBossName;
        storeInfo.companyInchargePerson = companyInchargePerson;
        storeInfo.companyBusinessNumber = companyBusinessNumber;
        storeInfo.companyPhone = companyPhone;
        storeInfo.homepage = homepage;


        if (password.length != 0 || vPassword.length != 0) {
            if (password !== vPassword) {
                setIsAlert([true, "비밀번호가 일치하지 않습니다.", false, true, -1]);
                return;
            }

            if (password.length < 6) {
                setIsAlert([true, "6자리 이상의 비밀번호를 설정해주세요.", false, true, -1]);
                return;
            }

            fAuth.currentUser.updatePassword(password).then(() => {

            }).catch((error) => {

            });
        }

        if (storeInfo.email != email) {
            if (email.length === 0) {
                setIsAlert([true, "이메일 주소를 확인해주세요.", false, true, -1]);
                return;
            }

            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
            if (reg.test(email) === false) {
                setIsAlert([true, "이메일 형식을 확인해주세요.", false, true, -1]);
                return;
            }

            storeInfo.email = email;


            // email 업데이트 하고
            fAuth.currentUser.updateEmail(email).then(() => {
                // GUsers 에서 정보 변경하고
                fDb.collection('GUsers').doc(storeInfo.companyUid).get().then((doc) => {
                    let targetGUserData = doc.data();
                    targetGUserData.email = email;
                    targetGUserData.companyName = companyName;
                    targetGUserData.companyBossName = companyBossName;
                    targetGUserData.companyBusinessNumber = companyBusinessNumber;

                    fDb.collection('GUsers').doc(storeInfo.companyUid).update(targetGUserData);
                })

                // StoreInfo 정보 변경
                fDb.collection('StoreInfo').doc(storeInfo.companyUid).update(storeInfo);
                navigation.goBack();
                navigation.goBack();
            }).catch((error) => {
                console.log(error);
            });

        }
        else {
            fDb.collection('GUsers').doc(storeInfo.companyUid).get().then((doc) => {
                let targetGUserData = doc.data();
                targetGUserData.companyName = companyName;
                targetGUserData.companyBossName = companyBossName;
                targetGUserData.companyBusinessNumber = companyBusinessNumber;

                fDb.collection('GUsers').doc(storeInfo.companyUid).update(targetGUserData);
            })

            fDb.collection('StoreInfo').doc(storeInfo.companyUid).update(storeInfo);
            setIsAlert([false, "", false, false]);
            navigation.goBack();
            navigation.goBack();
        }
    }

    const chnageStoreInfo = async () => {
        setIsAlert([true, "회원정보를 수정하시겠습니까?", false, true, 1]);
    }

    const Login = async () => {
        setIsAlert([true, "로그인중입니다.\n잠시만 기다려주세요.", true, false, -1]);
        await fAuth.signInWithEmailAndPassword(email, password)
            .then((response) => {
                setConfirm(true);
                setPassword("");
                setIsAlert([false, "", false, false, -1]);
            })
            .catch((e) => {
                setIsAlert([true, "로그인에 실패했습니다.\n다시 시도해주세요.", false, true, -1]);
                setPassword("");
            });
    }

    return (
        <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView showsVerticalScrollIndicator={false} style={{ width: 400, backgroundColor: GetColors(0) }}>
                {confirm ?
                    <BackView>
                        <GuideText1 style={{ paddingTop: 20 }}>안녕하세요.</GuideText1>
                        <View style={{ flexDirection: 'row', paddingTop: 3, alignItems: 'center' }}>
                            <GuideText4>국화</GuideText4>
                            <GuideText1>에 오신것을 환영합니다.</GuideText1>
                        </View>
                        <GuideText2 style={{ paddingTop: 20 }}>이메일</GuideText2>
                        <View style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5,
                            justifyContent: 'center',
                        }}>
                            <GuideText5>{storeInfo.email}</GuideText5>
                        </View>
                        <GuideText2 style={{ paddingTop: 20 }}>수정할 이메일을 입력해주세요.</GuideText2>
                        <GuideText22>(수정을 원하지 않으시면 변경하지 마세요.)</GuideText22>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="email-address"
                            placeholder="예: gukwha@naver.com"
                            value={email}
                            secureTextEntry={false}
                            onChange={onChangeEmail}></TextInput>

                        <GuideText2 style={{ paddingTop: 20 }}>수정할 비밀번호를 입력해주세요.</GuideText2>
                        <GuideText22>(수정을 원하지 않으시면 입력하지 마세요.)</GuideText22>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="default"
                            secureTextEntry={true}
                            placeholder="영문, 숫자 조합 최소 6자 이상"
                            value={password}
                            onChange={onChangePw}></TextInput>
                        <GuideText2 style={{ paddingTop: 20 }}>비밀번호 확인</GuideText2>
                        <GuideText22>(수정을 원하지 않으시면 입력하지 마세요.)</GuideText22>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="default"
                            secureTextEntry={true}
                            placeholder="비밀번호를 다시한번 입력해주세요."
                            value={vPassword}
                            onChange={onChangeVPw}></TextInput>
                        <GuideText2 style={{ paddingTop: 20 }}>업체명</GuideText2>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            placeholder="국화 장례식장"
                            value={companyName}
                            onChange={onChangeCompanyName}></TextInput>
                        <GuideText2 style={{ paddingTop: 20 }}>대표자 성명</GuideText2>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            placeholder="홍길동"
                            value={companyBossName}
                            onChange={onChangeCompanyBossName}></TextInput>
                        <GuideText2 style={{ paddingTop: 20 }}>담당자 성명</GuideText2>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            placeholder="홍길동"
                            value={companyInchargePerson}
                            onChange={onChangeInchargePerson}></TextInput>
                        <GuideText2 style={{ paddingTop: 20 }}>사업자등록번호</GuideText2>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="number-pad"
                            placeholder="사업자 등록번호를 - 없이 입력해 주세요."
                            value={companyBusinessNumber}
                            onChange={onChangeBusinessNumber}></TextInput>
                        <GuideText2 style={{ paddingTop: 20 }}>대표번호</GuideText2>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="number-pad"
                            placeholder="02 1234 1234 확인용으로 사용됩니다."
                            value={companyPhone}
                            onChange={onChangeCompanyPhone}></TextInput>
                        <GuideText2 style={{ paddingTop: 20 }}>홈페이지</GuideText2>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            placeholder="www.e-gukhwa.com/ (선택)"
                            value={homepage}
                            onChange={onChangeHomePage}></TextInput>

                        <GuideText1 style={{ paddingTop: 20 }}>수정하시기 전에 위 정보를 확인해주세요.</GuideText1>
                        <TouchableOpacity style={{ marginTop: 30, justifyContent: 'center', alignItems: 'center', height: 45, borderRadius: 30, backgroundColor: GetColors(5) }}
                            onPress={chnageStoreInfo}>
                            <GuideText3>회원정보 수정</GuideText3>
                        </TouchableOpacity>
                        <GuideText3>{"\n"}{"\n"}{"\n"}{"\n"}</GuideText3>
                    </BackView>
                    :
                    <BackView>
                        <View style={{ flexDirection: 'row', paddingTop: 15, paddingBottom: 5, alignItems: 'center' }}>
                            <GuideText4>국화</GuideText4>
                            <GuideText1>에 오신것을 환영합니다.</GuideText1>
                        </View>
                        <GuideText11>회원정보를 수정하시려면{"\n"}비밀번호를 다시 한번 입력해주세요.</GuideText11>
                        <GuideText2 style={{ paddingTop: 20 }}>이메일</GuideText2>
                        <View style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5,
                            justifyContent: 'center',
                        }}>
                            <GuideText5>{storeInfo.email}</GuideText5>
                        </View>
                        <GuideText2 style={{ paddingTop: 20 }}>비밀번호</GuideText2>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="default"
                            secureTextEntry={true}
                            placeholder="영문, 숫자 조합 최소 6자 이상"
                            value={password}
                            onChange={onChangePw}></TextInput>
                        <TouchableOpacity style={{ marginTop: 30, justifyContent: 'center', alignItems: 'center', height: 45, borderRadius: 30, backgroundColor: GetColors(5) }}
                            onPress={Login}>
                            <GuideText3>로그인</GuideText3>
                        </TouchableOpacity>
                    </BackView>}
                <AwesomeAlert
                    show={isAlert[0]}
                    showProgress={isAlert[2]}
                    title="국화"
                    message={isAlert[1]}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showCancelButton={false}
                    showConfirmButton={isAlert[3]}
                    cancelText=""
                    confirmText=" 확인 "
                    confirmButtonColor="#000000"
                    onCancelPressed={() => {
                        setIsAlert([false, "", false, true, -1]);
                    }}
                    onConfirmPressed={() => {
                        if (isAlert[4] === 1) {
                            actChangeStoreInfo();
                        }
                        setIsAlert([false, "", false, true, -1]);
                    }}
                />
            </KeyboardAwareScrollView>
        </View>
    )
}

export default StoreInfo;