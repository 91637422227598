import React, { useState, useEffect } from "react";
import { ScrollView, TouchableOpacity } from "react-native";
import styled from "styled-components/native"
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { GetColors } from "../Config/Datas";

const RoomImage = styled.Image`
    margin-right: 5px;
    margin-left: 5px;
    justify-content: center;
    align-items: center;
    flex: 1;
`;
const TitleText = styled.Text`
    font-size: 18px;
    font-weight: 500;
    color: black;
`;

const ImageTouchable = styled.TouchableOpacity`
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const DeleteText = styled.Text`
    align-items: center;
    color: ${GetColors(9)};
    font-weight: 500;
    font-size: 15px;
`;

const UploadImage = styled.Image`
    margin-right: 5px;
    justify-content: center;
    align-items: center;
    flex: 1;
`;


const InsideImageView = styled.View`
    align-items: center;
`;

const StoreInputImageComponent = ({ image, deleteImage }) => {
    return (
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {image.map((imageTemp, index) => (
                <InsideImageView key={Math.random().toString()} >
                    <UploadImage source={{ uri: imageTemp.imageUri === "" ? imageTemp.imageUrl : imageTemp.imageUri }} style={{ width: 113, height: 75 }}></UploadImage>
                    <TouchableOpacity onPress={() => deleteImage(index)}>
                        <DeleteText>삭제</DeleteText>
                    </TouchableOpacity>
                </InsideImageView>
            ))}
        </ScrollView>
    )
}

export default React.memo(StoreInputImageComponent);