import React, { useState, useEffect } from "react";
import { ScrollView, TouchableOpacity, View, Dimensions, Image } from "react-native";
import styled from "styled-components/native"
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { GetColors } from "../Config/Datas";

const { width: WIDTH, height: HEIGHT } = Dimensions.get("window");

const RoomImage = styled.Image`
    margin-right: 5px;
    margin-left: 5px;
    justify-content: center;
    align-items: center;
    flex: 1;
`;
const TitleText = styled.Text`
    font-size: 18px;
    font-weight: 500;
    color: black;
`;

const ImageTouchable = styled.TouchableOpacity`
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const DeleteText = styled.Text`
    align-items: center;
    color: ${GetColors(0)};
    font-weight: 500;
    font-size: 17px;
`;

const UploadImage = styled.Image`
    margin-right: 5px;
    justify-content: center;
    align-items: center;
`;


const StoreInputMainImageComponent = ({ image, deleteImage }) => {
    return (
        <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
            <ScrollView showsHorizontalScrollIndicator={false} style={{ backgroundColor: GetColors(0), width: 400 }}>
                <View style={{ paddingTop: 5 }}>
                    {image.map((imageTemp, index) => (
                        <View key={Math.random().toString()} style={{ paddingTop: 10, paddingBottom: 10 }} >
                            <TitleText style={{ paddingTop: 10, paddingBottom: 10 }}>  {index + 1} 번 사진</TitleText>
                            <UploadImage source={{
                                uri: imageTemp.mainPicUri === "" ? imageTemp.mainPicUrl : imageTemp.mainPicUri
                            }}
                                style={{
                                    width: 400,
                                    height: 450
                                }}></UploadImage>
                            {deleteImage == null ?
                                <></>
                                :
                                <View style={{ alignItems: 'flex-end' }}>
                                    <TouchableOpacity
                                        onPress={() => deleteImage(index)}
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: 20,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            marginLeft: 20,
                                            marginRight: 20,
                                            borderRadius: 10,
                                            height: 30,
                                            width: 150,
                                            backgroundColor: GetColors(9)
                                        }}>
                                        <DeleteText>{index + 1}번 사진 삭제</DeleteText>
                                    </TouchableOpacity>
                                </View>
                            }
                        </View>
                    ))}

                </View>
            </ScrollView>
        </View>
    )
}

export default React.memo(StoreInputMainImageComponent);