import React, { useState, useEffect } from "react";
import { View, Switch, Dimensions, Image, TouchableOpacity, TextInput, Platform } from "react-native";
import styled from "styled-components/native";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import { GetAddress, GetColors } from "../Config/Datas"
import DropDownPicker from 'react-native-dropdown-picker';
import fbInstance from "../Config/fbInstance";
import AwesomeAlert from 'react-native-awesome-alerts';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import * as ImageManipulator from 'expo-image-manipulator';

const { width: WIDTH, height: HEIGHT } = Dimensions.get("window");

const BackView = styled.View`
    padding-left: 25px;
    padding-right: 25px;
    background-color: ${GetColors(0)};
    padding-bottom: 150px;
`;

const GuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 600;
`;
const GuideText2 = styled.Text`
    font-size: 12px;
    font-weight: 500;
    color: ${GetColors(5)};
`;
const GuideText3 = styled.Text`
    font-size: 18px;
    font-weight: 600;
`;

const GuideText4 = styled.Text`
    font-size: 12px;
    font-weight: 600;
`;

const GuideText5 = styled.Text`
    font-size: 12px;
    font-weight: 400;
`;

const GuideText6 = styled.Text`
    font-size: 16px;
    font-weight: 700;
    color: ${GetColors(0)};
`;


// homepage: "",
// Capacity: "",
// OperationType: false, 
// false : 사설, true : 공설

const subCodeArray = GetAddress(1);
let controller1;
let controller2;
var code2Number = 0;

const StoreDetail = ({ route, navigation }) => {
    const [storeInfo, setStoreInfo] = useState(route.params.storeInfo);
    const [isAlert, setIsAlert] = useState([false, false, "국화", "메세지", false, false, false, false, "취소", "확인", "#0073cf", -1]);
    const [code1Number, SetCode1Number] = useState(0);
    const [text1, setText1] = useState(storeInfo.companyName);
    const [text2, setText2] = useState(storeInfo.companyIntro);
    const [text3, setText3] = useState(storeInfo.companyAddress);
    const [text4, setText4] = useState(storeInfo.companyPhone);
    const [text5, setText5] = useState(storeInfo.homepage);
    const [text6, setText6] = useState(storeInfo.capacity);
    const [parking, setParking] = useState(storeInfo.companyParking);
    const [operationType, setOperationType] = useState(storeInfo.operationType);

    const parkingToggle = () => setParking(previousState => !previousState);
    const operationTypeToggle = () => setOperationType(previousState => !previousState);

    const onChange1 = text => {
        setText1(text.nativeEvent.text);
    }

    const onChange2 = text => {
        setText2(text.nativeEvent.text);
    }

    const onChange3 = text => {
        setText3(text.nativeEvent.text);
    }

    const onChange4 = text => {
        setText4(text.nativeEvent.text);
    }

    const onChange5 = text => {
        setText5(text.nativeEvent.text);
    }

    const onChange6 = text => {
        setText6(text.nativeEvent.text);
    }

    const actSaveStoreDetail = async () => {
        setIsAlert([true, true, "국화", "정보 저장중입니다. 잠시만 기다려주세요", false, false, false, false, "", "", "#0073cf", 0]);

        const fDb = fbInstance.firestore();
        fDb.collection('StoreInfo').doc(storeInfo.companyUid).update({
            code1: code1Number,
            code2: code2Number,
            companyName: text1,
            companyIntro: text2,
            companyAddress: text3,
            companyPhone: text4,
            homepage: text5,
            capacity: text6,
            companyParking: parking,
            operationType: operationType
        })

        navigation.goBack();
    }

    const saveStoreDetail = () => {
        if (text1.length === 0 ||
            text2.length === 0 ||
            text3.length === 0 ||
            text4.length === 0 ||
            text6.length === 0) {
            setIsAlert([true, false, "국화", "비어 있는 입력정보가 있습니다.\n입력정보를 확인해주세요.", false, false, false, true, "취소", "확인", "#0073cf", 0]);
            return;
        }
        else {
            setIsAlert([true, false, "국화", "입력하신 정보를 저장하시겠습니까?\n저장하신 데이터는 지금부터 사용자에게 보여집니다.", false, false, false, true, "취소", "확인", "#0073cf", 1]);
        }
    }

    const goToPictures = () => {
        navigation.navigate('대표사진', { storeInfo: storeInfo });
    }


    return (
        <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView showsVerticalScrollIndicator={false} style={{ backgroundColor: GetColors(0), width: 400 }}>
                <BackView>
                    <View style={{ flexDirection: 'row', marginTop: 18, justifyContent: "space-between", height: 20, alignItems: 'center' }}>
                        <GuideText1>대표사진</GuideText1>
                        {/* <TouchableOpacity onPress={pickImage} style={{ height: 17, flexDirection: 'row', justifyContent: 'center' }}>
                        <MaterialCommunityIcons name="plus-box-outline" size={18} color={GetColors(5)} />
                        <GuideText2> 이미지 가져오기</GuideText2>
                    </TouchableOpacity> */}
                    </View>
                    <TouchableOpacity onPress={goToPictures} style={{ marginTop: 5, height: 350, alignItems: 'center', justifyContent: 'center' }}>
                        {storeInfo.mainPics.length === 0 ?
                            <GuideText3>대표이미지를 업로드 해주세요.</GuideText3>
                            :
                            <Image
                                source={{ uri: storeInfo.mainPics[0].mainPicUrl }}
                                style={{ width: 350, height: 250, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                            </Image>
                        }
                    </TouchableOpacity>
                    <GuideText1 style={{ textAlign: 'right' }}>업로드 된 대표사진 : {storeInfo.mainPics.length} 장</GuideText1>
                    <GuideText4 style={{ paddingTop: 20 }}>상호</GuideText4>
                    <TextInput style={{
                        paddingLeft: 12,
                        marginTop: 5,
                        height: 37,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="업체명"
                        onChange={onChange1}
                        value={text1}></TextInput>
                    <GuideText4 style={{ paddingTop: 20 }}>소개</GuideText4>
                    <TextInput style={{
                        paddingLeft: 12,
                        marginTop: 5,
                        height: 37,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="간단한 한줄 소개글을 작성해주세요"
                        onChange={onChange2}
                        value={text2}></TextInput>
                    <GuideText4 style={{ paddingTop: 20 }}>주소</GuideText4>
                    {Platform.OS === 'android' ?
                        <View style={{ flexDirection: 'row', marginTop: 5, justifyContent: 'space-between' }}>
                            <DropDownPicker
                                controller={instance => controller1 = instance}
                                items={GetAddress(0)}
                                defaultValue={storeInfo.code1.toString()}
                                containerStyle={{ height: 37, width: (WIDTH - 50) / 2 }}
                                itemStyle={{
                                    justifyContent: 'flex-start'
                                }}
                                selectedLabelStyle={{
                                    color: '#000000'
                                }}
                                onChangeItem={item => {
                                    SetCode1Number(item.value);
                                    controller2.selectItem('-1');
                                }} />
                            <DropDownPicker
                                controller={instance => controller2 = instance}
                                items={subCodeArray[code1Number]}
                                defaultValue={storeInfo.code2.toString()}
                                containerStyle={{ height: 37, width: (WIDTH - 50) / 2 }}
                                itemStyle={{
                                    justifyContent: 'flex-start'
                                }}
                                selectedLabelStyle={{
                                    color: '#000000'
                                }}
                                dropDownStyle={{ backgroundColor: '#ffffff' }}
                                onChangeItem={item => {
                                    code2Number = item.value;
                                }} />
                        </View>
                        :
                        <View style={{ zIndex: 1, flexDirection: 'row', marginTop: 5 }}>
                            <DropDownPicker
                                controller={instance => controller1 = instance}
                                items={GetAddress(0)}
                                defaultValue={storeInfo.code1.toString()}
                                containerStyle={{ height: 37, width: 350 / 2 }}
                                itemStyle={{
                                    justifyContent: 'flex-start'
                                }}
                                selectedLabelStyle={{
                                    color: '#000000'
                                }}
                                dropDownStyle={{ backgroundColor: '#ffffff' }}
                                onChangeItem={item => {
                                    SetCode1Number(item.value);
                                    controller2.selectItem('-1');
                                }} />
                            <DropDownPicker
                                controller={instance => controller2 = instance}
                                items={subCodeArray[code1Number]}
                                defaultValue={storeInfo.code2.toString()}
                                containerStyle={{ height: 37, width: 350 / 2 }}
                                itemStyle={{
                                    justifyContent: 'flex-start'
                                }}
                                selectedLabelStyle={{
                                    color: '#000000'
                                }}
                                dropDownStyle={{ backgroundColor: '#ffffff' }}
                                onChangeItem={item => {
                                    code2Number = item.value;
                                }} />
                        </View>}
                    <TextInput style={{
                        paddingLeft: 12,
                        marginTop: 5,
                        height: 37,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="상세주소를 입력해주세요."
                        onChange={onChange3}
                        value={text3}></TextInput>
                    <GuideText4 style={{ paddingTop: 20 }}>대표번호</GuideText4>
                    <TextInput style={{
                        paddingLeft: 12,
                        marginTop: 5,
                        height: 37,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="number-pad"
                        placeholder="사업체 대표번호를 입력해주세요."
                        onChange={onChange4}
                        value={text4}></TextInput>
                    <GuideText4 style={{ paddingTop: 20 }}>홈페이지(선택)</GuideText4>
                    <TextInput style={{
                        paddingLeft: 12,
                        marginTop: 5,
                        height: 37,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="홈페이지 주소를 입력해주세요."
                        onChange={onChange5}
                        value={text5}></TextInput>
                    <GuideText4 style={{ paddingTop: 20 }}>안치능력</GuideText4>
                    <TextInput style={{
                        paddingLeft: 12,
                        marginTop: 5,
                        height: 37,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="number-pad"
                        placeholder="안치능력을 입력해주세요(구)"
                        onChange={onChange6}
                        value={text6}></TextInput>
                    <View style={{ flexDirection: 'row', height: 50, paddingTop: 20, flex: 1, justifyContent: 'space-between' }}>
                        <View style={{ height: 50, flex: 1 }}>
                            <GuideText4 style={{ paddingTop: 20 }}>주차여부</GuideText4>
                            <View style={{ flexDirection: 'row', paddingTop: 5, alignItems: 'center' }}>
                                <GuideText5>무료주차  </GuideText5>
                                <Switch
                                    trackColor={{ false: '#767577', true: '#0073cf' }}
                                    onValueChange={parkingToggle}
                                    value={parking}
                                />
                                <GuideText5>  유료주차</GuideText5>
                            </View>
                        </View>
                        <View style={{ height: 50, flex: 1 }}>
                            <GuideText4 style={{ paddingTop: 20 }}>운영형태</GuideText4>
                            <View style={{ flexDirection: 'row', paddingTop: 5, alignItems: 'center' }}>
                                <GuideText5>사설  </GuideText5>
                                <Switch
                                    trackColor={{ false: '#767577', true: '#0073cf' }}
                                    onValueChange={operationTypeToggle}
                                    value={operationType}
                                />
                                <GuideText5>  공설</GuideText5>
                            </View>
                        </View>
                    </View>
                    <TouchableOpacity
                        onPress={saveStoreDetail}
                        style={{ marginTop: 80, alignItems: 'center', justifyContent: 'center', borderRadius: 20, paddingLeft: 20, paddingRight: 20, height: 45, backgroundColor: GetColors(5) }}>
                        <GuideText6>기본 정보 저장</GuideText6>
                    </TouchableOpacity>
                </BackView>
                <AwesomeAlert
                    show={isAlert[0]}
                    showProgress={isAlert[1]}
                    title={isAlert[2]}
                    message={isAlert[3]}
                    closeOnTouchOutside={isAlert[4]}
                    closeOnHardwareBackPress={isAlert[5]}
                    showCancelButton={isAlert[6]}
                    showConfirmButton={isAlert[7]}
                    cancelText={isAlert[8]}
                    confirmText={isAlert[9]}
                    confirmButtonColor={isAlert[10]}
                    onCancelPressed={() => {
                    }}
                    onConfirmPressed={() => {
                        // 정보 입력이 제대로 되지 않음
                        if (isAlert[11] === 0) {
                            setIsAlert([false, false, "국화", "비어 있는 입력정보가 있습니다.", false, false, false, true, "취소", "확인", "#0073cf", -1]);
                        }
                        // 저장 이후 확인 버튼
                        else if (isAlert[11] === 1) {
                            setIsAlert([false, false, "국화", "비어 있는 입력정보가 있습니다.", false, false, false, true, "취소", "확인", "#0073cf", -1]);
                            actSaveStoreDetail();
                        }
                        else {
                            setIsAlert([false, false, "국화", "비어 있는 입력정보가 있습니다.", false, false, false, true, "취소", "확인", "#0073cf", -1]);
                        }
                    }}
                />
            </KeyboardAwareScrollView>
        </View>
    );
}

export default StoreDetail;
