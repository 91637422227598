import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, ActivityIndicator, Image } from "react-native";
import styled from "styled-components/native";
import fbInstance from "../Config/fbInstance";
import AwesomeAlert from 'react-native-awesome-alerts';
import { GetAddress, GetRoomStateName, GetColors } from "../Config/Datas"
import { MaterialIcons } from '@expo/vector-icons';
import DropDownPicker from 'react-native-dropdown-picker';
import { GetPickedTime, GetPickedTime2 } from '../Config/Datas';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { MaterialCommunityIcons } from '@expo/vector-icons';

const BackView = styled.View`
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 150px;
    background: ${GetColors(0)};
`;

const GuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 500;
`;

const GuideText2 = styled.Text`
    font-size: 20px;
    font-weight: 700;
`;

const GuideText3 = styled.Text`
    font-size: 12px;
    font-weight: 400;
`;

const GuideText4 = styled.Text`
    font-size: 12px;
    font-weight: 600;
`;

const GuideText44 = styled.Text`
    font-size: 14px;
    font-weight: 600;
`;

const GuideText5 = styled.Text`
    font-size: 14px;
    font-weight: 800;
`;

const GuideText6 = styled.Text`
    font-size: 7px;
    font-weight: 400;
    color: ${GetColors(0)};
`;

const GuideText7 = styled.Text`
    font-size: 16px;
    font-weight: 500;
    color: ${GetColors(0)};
`;

const GuideText8 = styled.Text`
    font-size: 19px;
    font-weight: 500;
    color: ${GetColors(0)};
`;

const GuideText222 = styled.Text`
    font-size: 16px;
    color: ${GetColors(0)};
`;

const LineView = styled.View`
    height: 1px;
    background: ${GetColors(4)};
    justify-content: center;
`;

const code1Array = GetAddress(0);
const code2Array = GetAddress(1);

const checkInTimeArr = GetPickedTime2();
const byeTimeArr = GetPickedTime();

const Store = ({ navigation, route }) => {
    const fAuth = fbInstance.auth();
    fAuth.useDeviceLanguage();
    const fDb = fbInstance.firestore();
    const [isAlert, setIsAlert] = useState([false, "", false, -1, -1]);
    const [isLogin, setIsLogin] = useState(fAuth.currentUser);
    const [storeUid, setStoreUid] = useState(isLogin === null ? "" : fAuth.currentUser.uid);
    const [storeInfo, setStoreInfo] = useState(null);
    const [storeAddressStr, setStroeAddressStr] = useState("");
    const [history, setHistory] = useState([]);

    useEffect(() => {
        if (fAuth.currentUser != null) {
            fDb.collection('StoreInfo').doc(storeUid).onSnapshot((doc) => {
                setStoreInfo(doc.data());
                const tempData = doc.data();

                let indexNum = parseInt(tempData.code2) + 1;
                setStroeAddressStr("" + code1Array[tempData.code1].label + " " + code2Array[tempData.code1][indexNum].label + " " + tempData.companyAddress);
            });
        }
        return () => {

        }
    }, [storeUid]);

    const goToStoreMainInfo = () => {
        navigation.navigate("업체 기본 정보 수정", { storeInfo: storeInfo });
    }

    const goToStoreRoomInfo = (index) => {
        navigation.navigate("방 상세 정보 수정", { storeUid: storeUid, roomInfo: storeInfo.roomInfo, index: index });
    }

    const deleteRoom = (index) => {
        if (storeInfo.roomInfo.length === 1) {
            setIsAlert([true, "방이 1개 이하이면 삭제할 수 없습니다.", false, -1, -1]);
            return;
        }
        let roomTemp = [];
        for (let i = 0; i < storeInfo.roomInfo.length; i++) {
            if (i != index) {
                roomTemp.push(storeInfo.roomInfo[i]);
            }
        }
        let storeInfoTemp = storeInfo;
        storeInfoTemp.roomInfo = roomTemp;
        setStoreInfo(storeInfoTemp);
        const fDb = fbInstance.firestore();
        fDb.collection('StoreInfo').doc(storeUid).update({
            roomInfo: roomTemp
        });
    }

    const addRoom = () => {
        var storeInfoTemp = storeInfo;
        var roomTemp = storeInfo.roomInfo;
        roomTemp.push({
            id: Math.random().toString(),
            roomName: "",
            roomSize: "",
            roomPrice: "",
            byeTime: "0,0",
            userHistoryIndex: 0,
            storeHistoryIndex: 0,
            RIPName: "",
            RIPResponsibilityName: "",
            roomBookState: true,
            roomBookStart: "",
            roomBookEnd: "",
            roomBookUserUid: "",
            roomBookUserInfo: "",
            roomState: "",
            roomSaleRate: "",
            roomPics: [
            ]
        })

        storeInfoTemp.roomInfo = roomTemp;
        setStoreInfo(storeInfoTemp);
        // 스토어 정보 업데이트
        const fDb = fbInstance.firestore();
        fDb.collection('StoreInfo').doc(storeUid).update({
            roomInfo: roomTemp
        });
    }

    const onLogoutPressed = async () => {
        fAuth.signOut().then(() => {
            setIsLogin(fAuth.currentUser);
            navigation.goBack();
            navigation.goBack();
        }).catch((error) => {

        });
    }

    const getStoreHistory = () => {
        fDb.collection('StoreHistory').doc(storeUid).get().then((doc) => {
            if (doc.exists) {
                const tempHis1 = doc.data().history;
                let tempHis2 = [];

                for (let i = 0; i < tempHis1.length; i++) {
                    tempHis2.push(tempHis1[tempHis1.length - 1 - i])
                }

                setHistory(tempHis2);
            }
            else {
                setIsAlert([true, "예약내역이 없습니다.", false, -1, -1]);
            }
        })
    }

    const actRoomBookState = (index) => {
        let storeInfoTemp = storeInfo;
        let roomTemp = storeInfo.roomInfo;

        let targetRoom = roomTemp[index];
        targetRoom.byeTime = "0,0";
        targetRoom.roomBookEnd = "";
        targetRoom.roomBookStart = "";
        targetRoom.roomBookState = true;
        targetRoom.roomBookUserInfo = "";
        targetRoom.roomBookUserUid = "";

        roomTemp[index] = targetRoom;

        setStoreInfo(storeInfoTemp);
        // 스토어 정보 업데이트
        const fDb = fbInstance.firestore();
        fDb.collection('StoreInfo').doc(storeUid).update({
            roomInfo: roomTemp
        });
        setIsAlert([false, "", false, -1, -1]);
    }

    const SetRoomBookState = (index) => {
        let roomTemp = storeInfo.roomInfo;
        let targetRoom = roomTemp[index];

        if (parseInt(targetRoom.byeTime.split(',')[0]) === 0) {
            setIsAlert([true, "입실시간을 입력해주세요.\n\n입실시간을 입력하시지 않으면\n새로운 예약을 받을 수 없습니다.", false, -1, -1]);
            return;
        }
        if (parseInt(targetRoom.byeTime.split(',')[1]) === 0) {
            setIsAlert([true, "발인시간을 입력해주세요.\n\n발인시간을 입력하시지 않으면\n새로운 예약을 받을 수 없습니다.", false, -1, -1]);
            return;
        }

        const guide = "\"" + targetRoom.roomName + "\" 빈소의 발인을 완료하시겠습니까?\n발인을 완료하고나서는 다시 예약을 받게 됩니다.";
        setIsAlert([true, guide, true, 1, index]);
    }

    const goToReservationDetails = (his) => {
        navigation.navigate('예약내역', { history: his });
    }

    // 식장 회원정보수정
    const goToStoreInfo = () => {
        navigation.navigate('식장 회원정보수정', { storeInfo: storeInfo });
    }

    return (
        <>
            {storeInfo === null ?
                <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
                    <BackView style={{ width: 400 }}>
                        <ActivityIndicator></ActivityIndicator>
                    </BackView>
                </View>
                :
                <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
                    <KeyboardAwareScrollView style={{ backgroundColor: GetColors(0), width: 400 }}>
                        <BackView>
                            <GuideText5 style={{ paddingTop: 15, paddingBottom: 10 }}>계정 정보</GuideText5>
                            <View style={{ borderRadius: 5, padding: 4, backgroundColor: GetColors(5), height: 30, alignItems: 'center', justifyContent: 'center' }}>
                                <GuideText1 style={{ color: 'white' }}>{storeInfo.confirmed ? "관리자로부터 제휴 승인이 확인 되었습니다." : "관리자가 제휴 승인을 진행하는 중입니다."}</GuideText1>
                            </View>
                            <TouchableOpacity onPress={goToStoreInfo} style={{ marginTop: 15, padding: 10, flexDirection: 'row', flex: 1, borderRadius: 5, borderColor: GetColors(5), borderWidth: 0.5 }}>
                                <View style={{ flex: 4, justifyContent: 'flex-end' }}>
                                    <GuideText1>업체명 : {storeInfo.companyName}</GuideText1>
                                    <GuideText1>대표자 : {storeInfo.companyBossName}</GuideText1>
                                    <GuideText1>담당자 : {storeInfo.companyInchargePerson}</GuideText1>
                                    <GuideText3>이메일(계정) : {storeInfo.email}</GuideText3>
                                    <GuideText3>대표번호 : {storeInfo.companyPhone}</GuideText3>
                                </View>
                                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <MaterialCommunityIcons name="account-circle" size={50} style={{ borderRadius: 100 }}></MaterialCommunityIcons>
                                </View>
                            </TouchableOpacity>
                            <GuideText5 style={{ paddingTop: 15, paddingBottom: 10 }}>식장 정보</GuideText5>
                            <TouchableOpacity onPress={goToStoreMainInfo} style={{
                                marginTop: 12,
                                height: 326,
                                backgroundColor: GetColors(0),
                                shadowColor: 'black',
                                shadowRadius: 3,
                                borderRadius: 10,
                                shadowOpacity: 0.3,
                                shadowOffset: { width: 1, height: 2 },
                                elevation: 3
                            }}>
                                {storeInfo.mainPics.length === 0 ?
                                    <View style={{ height: 163, justifyContent: 'center', alignItems: 'center' }}>
                                        <GuideText1>대표사진을 등록해주세요.</GuideText1>
                                    </View>
                                    :
                                    <Image
                                        source={{ uri: storeInfo.mainPics[0].mainPicUrl }}
                                        style={{
                                            height: 163,
                                            borderTopRightRadius: 10,
                                            borderTopLeftRadius: 10
                                        }} />
                                }
                                <View style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 14
                                }}>
                                    <View style={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between'
                                    }}>
                                        <GuideText2>{storeInfo.companyName}</GuideText2>
                                        <MaterialIcons name="settings" size={20} color="black" />
                                    </View>
                                    <GuideText3 style={{ paddingTop: 5 }}>{storeInfo.companyIntro}</GuideText3>
                                    <View style={{
                                        flexDirection: 'row',
                                        height: 20,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <GuideText4 style={{ flex: 1.5 }}>주소</GuideText4>
                                        <GuideText3 style={{ flex: 7, paddingLeft: 8 }}>{storeAddressStr}</GuideText3>
                                    </View>
                                    <View style={{
                                        flexDirection: 'row',
                                        height: 20,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <GuideText4 style={{ flex: 1.5 }}>대표번호</GuideText4>
                                        <GuideText3 style={{ flex: 7, paddingLeft: 8, color: GetColors(5) }}>{storeInfo.companyPhone}</GuideText3>
                                    </View>
                                    <View style={{
                                        flexDirection: 'row',
                                        height: 20,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <GuideText4 style={{ flex: 1.5 }}>홈페이지</GuideText4>
                                        <GuideText3 style={{ flex: 7, paddingLeft: 8 }}>{storeInfo.homepage}</GuideText3>
                                    </View>
                                    <View style={{
                                        flexDirection: 'row',
                                        height: 50,
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        <GuideText4 style={{ flex: 1.5 }}>운영형태</GuideText4>
                                        <View style={{ flex: 7, justifyContent: 'center', alignItems: 'flex-start' }}>
                                            <View style={{ flexDirection: 'row' }} >
                                                <GuideText3 style={{ paddingLeft: 8 }}>안치능력</GuideText3>
                                                <GuideText3 style={{ paddingLeft: 3, color: GetColors(5) }}>{storeInfo.capacity} 구</GuideText3>
                                                <GuideText3 style={{ paddingLeft: 3 }}>빈소수</GuideText3>
                                                <GuideText3 style={{ paddingLeft: 3, color: GetColors(5) }}>{storeInfo.roomInfo.length} 개</GuideText3>
                                                {/* <GuideText3 style={{ paddingLeft: 3 }}>주차가능여부</GuideText3>
                                            <GuideText3 style={{ paddingLeft: 3, color: GetColors(5) }}>{storeInfo.companyParking ? "유료주차" : "무료주차"}</GuideText3> */}
                                            </View>
                                            <View style={{ flexDirection: 'row' }} >
                                                <GuideText3 style={{ paddingLeft: 8 }}>주차가능여부</GuideText3>
                                                <GuideText3 style={{ paddingLeft: 3, color: GetColors(5) }}>{storeInfo.companyParking ? "유료주차" : "무료주차"}</GuideText3>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </TouchableOpacity>
                            <GuideText5 style={{ paddingTop: 15, paddingBottom: 10 }}>빈소 정보</GuideText5>
                            {storeInfo.roomInfo.map((roomTemp, index) => (
                                <View key={roomTemp.id} style={{ paddingBottom: 15 }}>
                                    <View style={{ height: roomTemp.roomBookState ? 66 : 100, borderRadius: 7, borderWidth: 0.5, borderColor: GetColors(17) }}>
                                        <View style={{ height: 66, flexDirection: 'row' }}>
                                            <View style={{ flex: 3, padding: 5 }}>
                                                <View style={{ flex: 2, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                                                    <GuideText3>{roomTemp.roomName}</GuideText3>
                                                </View>
                                                <View style={{ alignItems: 'center', justifyContent: 'center', marginLeft: 3, marginRight: 3, borderRadius: 3, backgroundColor: roomTemp.roomBookState ? GetColors(5) : GetColors(9) }}>
                                                    <GuideText6>{roomTemp.roomBookState ? " 예약가능 " : " 예약완료 "}</GuideText6>
                                                </View>
                                            </View>
                                            <View style={{ flex: 6, padding: 5 }}>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>예약자  </GuideText4>
                                                    <GuideText3>{roomTemp.roomBookState ? "-" : roomTemp.roomBookUserInfo.substring(0, 3)}</GuideText3>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>예약일  </GuideText4>
                                                    <GuideText3>{roomTemp.roomBookState ? "-" : roomTemp.roomBookStart}</GuideText3>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>가격     </GuideText4>
                                                    <GuideText3>{roomTemp.roomPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</GuideText3>
                                                </View>
                                            </View>
                                            <View style={{ flex: 6, padding: 5 }}>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>연락처  </GuideText4>
                                                    <GuideText3>{roomTemp.roomBookState ? "-" : roomTemp.roomBookUserInfo.substring(4, 17)}</GuideText3>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>발인일  </GuideText4>
                                                    <GuideText3>{roomTemp.roomBookState ? "-" : roomTemp.roomBookEnd}</GuideText3>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>할인율  </GuideText4>
                                                    <GuideText3>{roomTemp.roomSaleRate}%</GuideText3>
                                                </View>
                                            </View>
                                        </View>
                                        {roomTemp.roomBookState ?
                                            <View></View>
                                            :
                                            <View style={{ paddingTop: 5, alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                                                <GuideText44>입실 : {checkInTimeArr[parseInt(roomTemp.byeTime.split(',')[0])].label} /</GuideText44>
                                                <GuideText44> 발인 : {byeTimeArr[parseInt(roomTemp.byeTime.split(',')[1])].label}</GuideText44>
                                            </View>}

                                    </View>

                                    <View style={{ flexDirection: 'row', marginTop: 5, alignItems: 'center', justifyContent: 'center' }}>
                                        <TouchableOpacity onPress={() => deleteRoom(index)} style={{ borderRadius: 8, padding: 5, marginLeft: 6, backgroundColor: GetColors(5) }}>
                                            <GuideText7> 빈소 정보 삭제 </GuideText7>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => goToStoreRoomInfo(index)} style={{ borderRadius: 8, padding: 5, marginLeft: 6, backgroundColor: GetColors(5) }}>
                                            <GuideText7> 빈소 정보 수정 </GuideText7>
                                        </TouchableOpacity>
                                        {roomTemp.roomBookState ?
                                            <></>
                                            :
                                            <TouchableOpacity onPress={() => SetRoomBookState(index)} style={{ borderRadius: 8, padding: 5, marginLeft: 6, backgroundColor: GetColors(9) }}>
                                                <GuideText7>발인완료</GuideText7>
                                            </TouchableOpacity>}
                                    </View>
                                </View>
                            ))}
                            <LineView />
                            <View style={{ alignItems: "center", marginTop: 30 }}>
                                <TouchableOpacity onPress={addRoom} style={{ marginTop: 15, borderRadius: 5, padding: 5, backgroundColor: GetColors(5) }}>
                                    <GuideText8>  빈소 추가  </GuideText8>
                                </TouchableOpacity>
                            </View>
                            <View style={{ alignItems: "center" }}>
                                <TouchableOpacity onPress={getStoreHistory} style={{ paddingLeft: 5, paddingRight: 5, marginTop: 25, borderRadius: 15, padding: 5, backgroundColor: GetColors(5) }}>
                                    <GuideText7 style={{ paddingLeft: 15, paddingRight: 15 }}>예약내역 불러오기</GuideText7>
                                </TouchableOpacity>
                            </View>
                            <GuideText5 style={{ paddingTop: 15, paddingBottom: 10 }}>예약내역</GuideText5>
                            {history.length === 0 ?
                                <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <GuideText3>예약 내역이 없습니다.</GuideText3>
                                </View>
                                :
                                history.map((his) => (
                                    <TouchableOpacity onPress={() => goToReservationDetails(his)} key={Math.random().toString()}>
                                        <View style={{ height: 66, marginBottom: 5, flexDirection: 'row', borderRadius: 7, borderWidth: 0.5, borderColor: GetColors(17) }}>
                                            <View style={{ flex: 2, padding: 10, justifyContent: 'center', alignItems: 'center' }}>
                                                <View style={{ justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                                                    <GuideText3>{his.roomName}</GuideText3>
                                                </View>
                                            </View>
                                            <View style={{ flex: 6, padding: 10 }}>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>예약자  </GuideText4>
                                                    <GuideText3>{his.userName}</GuideText3>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>예약일  </GuideText4>
                                                    <GuideText3>{his.startDate}</GuideText3>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>가격     </GuideText4>
                                                    <GuideText3>{his.roomPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</GuideText3>
                                                </View>
                                            </View>
                                            <View style={{ flex: 6, padding: 10 }}>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>연락처  </GuideText4>
                                                    <GuideText3>{his.userPhone}</GuideText3>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>발인일  </GuideText4>
                                                    <GuideText3>{his.endDate}</GuideText3>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <GuideText4>할인율  </GuideText4>
                                                    <GuideText3>{his.saleRate}%</GuideText3>
                                                </View>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                ))
                            }
                            <View style={{ paddingTop: 20, alignItems: 'center', justifyContent: 'center' }}>
                                <TouchableOpacity onPress={onLogoutPressed} style={{ borderRadius: 25, backgroundColor: GetColors(5), height: 45, width: 285, marginLeft: 5, alignItems: 'center', justifyContent: 'center' }}>
                                    <GuideText222>로그아웃</GuideText222>
                                </TouchableOpacity>
                            </View>
                        </BackView>
                        <AwesomeAlert
                            show={isAlert[0]}
                            showProgress={false}
                            title="국화"
                            message={isAlert[1]}
                            closeOnTouchOutside={true}
                            closeOnHardwareBackPress={true}
                            showCancelButton={isAlert[2]}
                            showConfirmButton={true}
                            cancelText=" 취소 "
                            confirmText=" 확인 "
                            confirmButtonColor="#000000"
                            onCancelPressed={() => {
                                setIsAlert([false, "", false, -1, -1]);
                            }}
                            onConfirmPressed={() => {
                                if (isAlert[3] === 1) {
                                    actRoomBookState(isAlert[4]);
                                    setIsAlert([false, "", false, -1, -1]);
                                }
                                setIsAlert([false, "", false, -1, -1]);
                            }}
                        />
                    </KeyboardAwareScrollView>
                </View>
            }
        </>
    )
}

export default Store;
