import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import styled from "styled-components/native";
import { GetColors } from "../Config/Datas"
import SignIn from "./SignIn";

const BackView = styled.View`
    background-color: ${GetColors(0)};
    padding-left: 45px;
    padding-right: 45px;
    flex: 1;
`;

const GuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 500;
`;

const GuideText2 = styled.Text`
    font-size: 24px;
    font-weight: 700;
`;

const ChooseAccountType = ({ navigation }) => {

    const SignIn = (type) => {
        // 0 일반, 1 장례지도사, 2 식장
        if (type === 0) {
            navigation.navigate('일반 회원가입')
        }
        else if (type === 1) {
            navigation.navigate('지도사 회원가입')
        }
        else if (type === 2) {
            navigation.navigate('장례식장 회원가입')
        }
    }

    return (
        <BackView style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ width: 400 }}>
                <GuideText1 style={{ paddingTop: 58, textAlign: 'center' }}>회원 가입 유형을 선택해주세요</GuideText1>
                <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                    <TouchableOpacity style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 20,
                        borderRadius: 200,
                        width: 120,
                        height: 120,
                        backgroundColor: GetColors(5),
                        shadowColor: 'black',
                        shadowRadius: 5,
                        shadowOpacity: 0.5,
                        shadowOffset: { width: 1, height: 2 },
                        elevation: 20
                    }} onPress={() => SignIn(0)}>
                        <GuideText2 style={{ color: GetColors(0) }}>일반회원</GuideText2>
                    </TouchableOpacity>
                    <TouchableOpacity style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 20,
                        borderRadius: 200,
                        width: 120,
                        height: 120,
                        backgroundColor: GetColors(0),
                        shadowColor: 'black',
                        shadowRadius: 5,
                        shadowOpacity: 0.3,
                        shadowOffset: { width: 1, height: 2 },
                        elevation: 3
                    }} onPress={() => SignIn(1)}>
                        <GuideText2 style={{ color: GetColors(5) }}>장례지도사</GuideText2>
                    </TouchableOpacity>
                    <TouchableOpacity style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 20,
                        borderRadius: 200,
                        width: 120,
                        height: 120,
                        backgroundColor: GetColors(5),
                        shadowColor: 'black',
                        shadowRadius: 5,
                        shadowOpacity: 0.3,
                        shadowOffset: { width: 1, height: 2 },
                        elevation: 3
                    }} onPress={() => SignIn(2)}>
                        <GuideText2 style={{ color: GetColors(0) }}>장례식장</GuideText2>
                    </TouchableOpacity>
                </View>
            </View>
        </BackView>
    )
}

export default ChooseAccountType;
