import React, { useState, useEffect } from "react";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import styled from "styled-components/native";
import { GetColors } from "../Config/Datas"
import StoreInputMainImageComponent from "../Components/StoreInputMainImageComponent";

const GuideText1 = styled.Text`
    font-size: 25px;
    font-weight: 600;
`;

const StoreMainPicsView = ({ navigation, route }) => {
    const images = route.params.storeInfo.mainPics;

    return (
        <KeyboardAwareScrollView showsVerticalScrollIndicator={false} style={{ backgroundColor: GetColors(0) }}>
            <StoreInputMainImageComponent image={images} />
            <GuideText1>{"\n\n"}</GuideText1>
        </KeyboardAwareScrollView>
    )
}

export default StoreMainPicsView;
