import React, { useState, useEffect } from "react";
import { View, Platform, Dimensions, TouchableOpacity, Text } from "react-native";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import styled from "styled-components/native";
import { GetColors } from "../Config/Datas"
import fbInstance from "../Config/fbInstance";
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import AwesomeAlert from 'react-native-awesome-alerts';
import StoreInputMainImageComponent from "../Components/StoreInputMainImageComponent";
import { MaterialCommunityIcons } from '@expo/vector-icons';

const GuideText1 = styled.Text`
    font-size: 25px;
    font-weight: 600;
`;

const GuideText2 = styled.Text`
    font-size: 20px;
    font-weight: 600;
    color: ${GetColors(0)};
`;

let isImageDelete = false;
let isImageAdd = false;
let oriImageArr;
let updateImageArr;

const StoreMainPics = ({ navigation, route }) => {
    const [storeInfo, SetStoreInfo] = useState(route.params.storeInfo);
    const [images, setImages] = useState(storeInfo.mainPics);
    const [isAlert, setIsAlert] = useState([false, false, "", false, false, true, true, -1]);

    useEffect(() => {
        oriImageArr = storeInfo.mainPics;
        updateImageArr = images;
    }, []);

    /* show showPorgress message closeontouch closebackbutton showcancelbutton showconfirmbutton actionIndex */
    const pickImage = async () => {
        if (images.length > 9) {
            setIsAlert([true, false, "10장 이상의 사진을 등록 할 수 없습니다.", true, true, false, true, -1]);
            return;
        }
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.images,
            quality: 0.1
        });

        if (!result.cancelled) {
            const manipResult = await ImageManipulator.manipulateAsync(
                result.uri,
                [{ rotate: 0 }],
                { compress: 0.05, format: ImageManipulator.SaveFormat.JPEG }
            );

            isImageAdd = true;
            let imageTemp = [...images];
            imageTemp.push({
                mainPicUrl: "",
                mainPicPath: "",
                mainPicUri: manipResult.uri
            });
            updateImageArr.push({
                mainPicUrl: "",
                mainPicPath: "",
                mainPicUri: manipResult.uri
            })

            setImages(imageTemp);
        }
    };


    const deleteImage = (index) => {
        isImageDelete = true;
        let imageTemp = [];
        for (let i = 0; i < images.length; i++) {
            if (i !== index) {
                imageTemp.push(images[i]);
            }
        }

        setImages(imageTemp);
        updateImageArr = imageTemp;
    }

    const actSaveMainPics = async () => {
        /* show showPorgress message closeontouch closebackbutton showcancelbutton showconfirmbutton actionIndex */
        setIsAlert([true, true, "이미지 처리중입니다. 잠시만 기다려주세요.", false, false, false, false, -1]);
        let storeInfoTemp = storeInfo;

        if (isImageDelete) {
            for (let i = 0; i < oriImageArr.length; i++) {
                let isDelete = true;
                for (let j = 0; j < images.length; j++) {
                    if (oriImageArr[i].mainPicUrl === images[j].mainPicUrl) {
                        isDelete = false;
                    }
                }
                if (isDelete) {
                    const storageRef = fbInstance.storage().ref();
                    const desertRef = storageRef.child(`storeMainImage/${oriImageArr[i].mainPicPath}`);

                    desertRef.delete().then(function () {
                        console.log("delete success");
                    }).catch(function (error) {
                        console.log("delete error");
                    });
                }
            }
        }

        // 이미지 추가된거 셋팅
        if (isImageAdd) {
            for (let i = 0; i < images.length; i++) {
                if (images[i].mainPicUri != "") {
                    let imageUrlTemp = "";
                    let imagePathTemp = "";
                    const randomString = Math.random().toString(36).substr(2, 11) + Math.random().toString(36).substr(2, 11) + Math.random().toString(36).substr(2, 11);
                    const res = await fetch(images[i].mainPicUri);
                    const blob = await res.blob();
                    const storegeService = fbInstance.storage();

                    const fileRef = storegeService.ref().child(`storeMainImage/${randomString}`);
                    imagePathTemp = randomString;
                    const response = await fileRef.put(blob);
                    imageUrlTemp = await response.ref.getDownloadURL();

                    updateImageArr[i].mainPicUri = "";
                    updateImageArr[i].mainPicUrl = imageUrlTemp;
                    updateImageArr[i].mainPicPath = imagePathTemp;
                }
            }
        }
        storeInfoTemp.mainPics = updateImageArr;

        SetStoreInfo(storeInfoTemp);

        const fDb = fbInstance.firestore().collection("StoreInfo").doc(storeInfo.companyUid);
        fDb.update({
            mainPics: storeInfoTemp.mainPics
        }).then().catch(function (error) { console.log(error) });

        setIsAlert([false, false, "", false, true, true, false, -1]);
        setIsAlert([false, false, "", false, true, true, false, -1]);
        navigation.goBack();
        navigation.goBack();
    }

    const saveMainPics = () => {
        if (isImageAdd === false && isImageDelete === false) {
            navigation.goBack();
        }
        else {
            setIsAlert([true, false, "변경된 대표사진을 저장하시겠습니까?", true, true, true, true, 1]);
        }
    }

    return (
        <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView showsVerticalScrollIndicator={false} style={{ backgroundColor: GetColors(0), width: 400 }}>
                <StoreInputMainImageComponent image={images} deleteImage={deleteImage} pickImage={pickImage} />
                <TouchableOpacity onPress={pickImage} style={{ flexDirection: 'row', height: 70, paddingTop: 30, justifyContent: 'center', alignItems: 'center' }}>
                    <MaterialCommunityIcons name="plus-box-outline" size={40} color="black" />
                    <GuideText1>사진 추가</GuideText1>
                </TouchableOpacity>
                <TouchableOpacity onPress={saveMainPics} style={{ justifyContent: 'center', alignItems: 'center', marginTop: 20, paddingLeft: 20, paddingRight: 20, marginLeft: 20, marginRight: 20, borderRadius: 25, height: 50, backgroundColor: GetColors(5) }}>
                    <GuideText2>대표사진 저장</GuideText2>
                </TouchableOpacity>
                <GuideText1>{"\n\n"}</GuideText1>
                <AwesomeAlert
                    show={isAlert[0]}
                    showProgress={isAlert[1]}
                    title="국화"
                    message={isAlert[2]}
                    closeOnTouchOutside={isAlert[3]}
                    closeOnHardwareBackPress={isAlert[4]}
                    showCancelButton={isAlert[5]}
                    showConfirmButton={isAlert[6]}
                    cancelText="취소"
                    confirmText="확인"
                    confirmButtonColor="#0073cf"
                    onCancelPressed={() => {
                        setIsAlert([false, false, "", false, false, true, true, -1]);
                    }}
                    onConfirmPressed={() => {
                        if (isAlert[7] === 1) {
                            actSaveMainPics();
                        }
                        else {
                            setIsAlert([false, false, "", false, false, true, true, -1]);
                        }
                    }}
                />
            </KeyboardAwareScrollView>
        </View>
    )
}

export default StoreMainPics;
