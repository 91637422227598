import React, { useState, useEffect } from "react";
import { SafeAreaView, StyleSheet, View } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';

const Photo = ({ navigation, route }) => {
    const img = route.params.roomPics;

    let showingImages = [];

    for (let i = 0; i < img.length; i++) {
        let temp = { url: img[i] };
        showingImages.push(temp);
    }

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
          <View style={styles.container}>
            <ImageViewer imageUrls={showingImages} renderIndicator={() => null} />
          </View>
        </SafeAreaView>
      );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#ffffff',
        flex: 1,
    },
});

export default Photo;
