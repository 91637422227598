import React from "react";
import { ScrollView, TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import styled from "styled-components/native";
import { GetAddress, GetColors } from "../Config/Datas"

const LineView = styled.View`
    height: 1px;
    background: ${GetColors(14)};
`;

const RView = styled.View`
    flex-direction: row;
`;


const UploadImage = styled.Image`
    justify-content: flex-start;
    align-items: flex-start;
`;


const BaseView = styled.View`
    padding-top: 20px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 20px;
`;

const GuideText1 = styled.Text`
    color: ${GetColors(0)};
    font-size: 15px;
    font-weight: 700;
`;


const GuideText2 = styled.Text`
    color: ${GetColors(2)};
    font-size: 18px;
    font-weight: 600;
`;


const GuideText3 = styled.Text`
    color: ${GetColors(2)};
    font-size: 15px;
    font-weight: 700;
`;


const GuideText4 = styled.Text`
    color: ${GetColors(2)};
    font-size: 16px;
    font-weight: 400;
`;

const GuideText5 = styled.Text`
    color: ${GetColors(15)};
    font-size: 14px;
    font-weight: 700;
`;

const code1Array = GetAddress(0);
const code2Array = GetAddress(1);

let fixedCode2 = 0;

const StoreComponent = ({
    companyUid,
    id,
    code1,
    code2,
    companyMainPicUrl,
    companyMainPicPath,
    mainPics,
    companyName,
    companyIntro,
    homepage,
    capacity,
    operationType,
    companyAddress,
    roomInfo,
    userCode }) => {
    const navigation = useNavigation();

    fixedCode2 = parseInt(code2) + 1;

    const goToDetail = (companyUid) => {
        navigation.navigate("상세정보", { companyUid: companyUid });
    }

    return (
        <TouchableOpacity style={{ height: 345, marginBottom: 20 }} onPress={() => goToDetail(companyUid)}>
            <BaseView>
                <RView style={{ alignItems: "center" }}>
                    {operationType ?
                        <View style={{ backgroundColor: GetColors(13), padding: 5, borderRadius: 7 }}>
                            <GuideText1>공설</GuideText1>
                        </View>
                        :
                        <View style={{ backgroundColor: GetColors(12), padding: 5, borderRadius: 7 }}>
                            <GuideText1>사설</GuideText1>
                        </View>}
                    <GuideText2 style={{ paddingLeft: 5 }}>{companyName}</GuideText2>
                </RView>
                <View style={{
                    marginTop: 5,
                    height: 300,
                    backgroundColor: 'white',
                    shadowColor: 'black',
                    borderRadius: 10,
                    shadowRadius: 3,
                    shadowOpacity: 0.3,
                    elevation: 3,
                    shadowOffset: { width: 1, height: 2 },
                }}>
                    {mainPics.length === 0 ?
                        <View style={{ height: 200, justifyContent: 'center', alignItems: 'center' }}>
                            <GuideText3>대표사진이 없습니다.</GuideText3>
                        </View>
                        :
                        <UploadImage
                            source={{ uri: mainPics[0].mainPicUrl }}
                            style={{
                                borderTopLeftRadius: 7,
                                borderTopRightRadius: 7,
                                height: 200,
                            }} />}
                    {/* <View style={{ height: 28, top: -10, position: 'relative', alignSelf: 'center', borderRadius: 20, backgroundColor: GetColors(13), justifyContent: 'center' }}>
                        <GuideText3 style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 6, paddingBottom: 6 }}>{companyName}</GuideText3>
                    </View> */}
                    <View style={{ height: 100, paddingLeft: 10, paddingRight: 10 }}>
                        <GuideText4 style={{ textAlign: 'center', marginTop: 10, maxHeight: 40 }}>{companyIntro}</GuideText4>
                        <LineView style={{ marginTop: 10, marginBottom: 10 }} />
                        <GuideText5>{code1Array[code1].label} {code2Array[code1][fixedCode2].label}</GuideText5>
                    </View>
                    {/* <View style={{ height: 112, paddingTop: 30, paddingLeft: 14, paddingRight: 14, paddingBottom: 14 }}>
                        <GuideText4 style={{ textAlign: 'center', height: 58 }}>{companyIntro}</GuideText4>
                        <LineView ></LineView>
                        <View style={{ height: 40, justifyContent: 'center' }}>
                            <GuideText5>{code1Array[code1].label} {code2Array[code1][fixedCode2].label}</GuideText5>
                        </View>
                    </View> */}
                </View>
            </BaseView>
        </TouchableOpacity>
    );
}

export default StoreComponent;
