import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, TextInput, Image, Text } from "react-native";
import styled from "styled-components/native";
import fbInstance from "../Config/fbInstance";
import AwesomeAlert from 'react-native-awesome-alerts';
import { GetAddress, GetRoomStateName, GetColors } from "../Config/Datas";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Linking from 'expo-linking';
import { AntDesign, FontAwesome } from '@expo/vector-icons';


const BackView = styled.View`
    background-color: ${GetColors(0)};
    padding-left: 45px;
    padding-right: 45px;
    margin-bottom: 150px;
`;

const GuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 500;
`;

const GuideText2 = styled.Text`
    font-size: 18px;
    font-weight: 600;
`;

const GuideText3 = styled.Text`
    font-size: 15px;
    font-weight: 600;
`;

const GuideText4 = styled.Text`
    font-size: 20px;
    font-weight: 600;
    color: ${GetColors(0)};
`;

const GuideText5 = styled.Text`
    font-size: 18px;
    font-weight: 700;
    color: ${GetColors(5)};
`;


const GuideText6 = styled.Text`
    font-size: 18px;
    font-weight: 500;
`;

const GuideText7 = styled.Text`
    font-size: 16px;
    font-weight: 500;
    color: ${GetColors(5)};
`;

// : 확정시 추가 정보(고인성명, 상주이름)
// : 앞 단계에서의 정보 다 상세하게 보여주기(장례식장 이름, 주소, 방 이름, 가격, 예약자 이름, 예약일시 )

const BookConfirm = ({ route, navigation }) => {
    const fDb = fbInstance.firestore();
    const roomBookStart = route.params.roomBookStart;
    const roomBookEnd = route.params.roomBookEnd;
    const storeInfo = route.params.storeInfo;
    const userInfo = route.params.userInfo;
    const targetRoomInfo = storeInfo.roomInfo[route.params.index];
    const targetRoomIndex = route.params.index;
    const during = route.params.duration;

    const [RIPName, setRIPName] = useState("");
    const [RIPResponsibilityName, setRIPResponsibilityName] = useState("");
    const [RecommendName, setRecommendName] = useState("");
    const [RecommendPhone, setRecommendPhone] = useState("");
    const [agreement1, setAgreement1] = useState(false);
    const [isAlert, setIsAlert] = useState([false, false, "", true, -1]);


    const onChange11 = text => {
        setRIPName(text.nativeEvent.text);
    }
    const onChange22 = text => {
        setRIPResponsibilityName(text.nativeEvent.text);
    }

    const onChange33 = text => {
        setRecommendName(text.nativeEvent.text);
    }
    const onChange44 = text => {
        setRecommendPhone(text.nativeEvent.text);
    }

    const actConfirmBookStep1 = async () => {
        setIsAlert([true, true, "예약중입니다.\n\n잠시만 기다려주세요.", true, -1]);

        let userHistoryIndex = -1;

        const startDate = `${roomBookStart.split(',')[0]}/${roomBookStart.split(',')[1]}/${roomBookStart.split(',')[2]}`;
        const endDate = `${roomBookEnd.split(',')[0]}/${roomBookEnd.split(',')[1]}/${roomBookEnd.split(',')[2]}`;

        // 예약정보 업데이트 - 유저 히스토리 부분
        fDb.collection('UserHistory').doc(userInfo.uid).get().then((doc) => {
            if (doc.exists) {
                const addHistoryData = {
                    storeUid: storeInfo.companyUid,
                    storeName: storeInfo.companyName,
                    roomName: storeInfo.roomInfo[targetRoomIndex].roomName,
                    roomPrice: storeInfo.roomInfo[targetRoomIndex].roomPrice,
                    startDate: startDate,
                    endDate: endDate,
                    byeTime: "0,0",
                    saleRate: storeInfo.roomInfo[targetRoomIndex].roomSaleRate,
                    RIPName: RIPName,
                    RIPResponsibilityName: RIPResponsibilityName,
                    RecommendName: RecommendName,
                    RecommendPhone: RecommendPhone

                }
                // 내역이 있음
                let userHistory = doc.data().history;
                userHistory.push(addHistoryData);

                userHistoryIndex = (userHistory.length - 1);

                const tempData = {
                    history: userHistory
                }

                fDb.collection('UserHistory').doc(userInfo.uid).update(tempData);
            } else {
                // 내역이 없음
                const addHistoryData = {
                    history: [{
                        storeUid: storeInfo.companyUid,
                        storeName: storeInfo.companyName,
                        roomName: storeInfo.roomInfo[targetRoomIndex].roomName,
                        roomPrice: storeInfo.roomInfo[targetRoomIndex].roomPrice,
                        startDate: startDate,
                        endDate: endDate,
                        byeTime: "0,0",
                        saleRate: storeInfo.roomInfo[targetRoomIndex].roomSaleRate,
                        RIPName: RIPName,
                        RIPResponsibilityName: RIPResponsibilityName,
                        RecommendName: RecommendName,
                        RecommendPhone: RecommendPhone
                    }]
                }
                userHistoryIndex = 0;

                fDb.collection('UserHistory').doc(userInfo.uid).set(addHistoryData);
            }

            actConfirmBookStep2(startDate, endDate, userHistoryIndex);
        })
    }

    const actConfirmBookStep2 = async (startDate, endDate, userHistoryIndex) => {
        let storeHistoryIndex = -1;
        // 예약정보 업데이트 - 상점 히스토리 부분
        fDb.collection('StoreHistory').doc(storeInfo.companyUid).get().then((doc) => {
            if (doc.exists) {
                const addHistoryData = {
                    userUid: userInfo.uid,
                    userName: userInfo.name,
                    userPhone: userInfo.phone,
                    roomName: storeInfo.roomInfo[targetRoomIndex].roomName,
                    roomPrice: storeInfo.roomInfo[targetRoomIndex].roomPrice,
                    startDate: startDate,
                    endDate: endDate,
                    byeTime: "0,0",
                    saleRate: storeInfo.roomInfo[targetRoomIndex].roomSaleRate,
                    RIPName: RIPName,
                    RIPResponsibilityName: RIPResponsibilityName,
                    RecommendName: RecommendName,
                    RecommendPhone: RecommendPhone
                }

                // 내역이 있음
                let storeHistory = doc.data().history;
                storeHistory.push(addHistoryData);

                storeHistoryIndex = (storeHistory.length - 1);

                const tempData = {
                    history: storeHistory
                }
                fDb.collection('StoreHistory').doc(storeInfo.companyUid).update(tempData);
            } else {
                // 내역이 없음
                const addHistoryData = {
                    history: [{
                        userUid: userInfo.uid,
                        userName: userInfo.name,
                        userPhone: userInfo.phone,
                        roomName: storeInfo.roomInfo[targetRoomIndex].roomName,
                        roomPrice: storeInfo.roomInfo[targetRoomIndex].roomPrice,
                        startDate: startDate,
                        endDate: endDate,
                        byeTime: "0,0",
                        saleRate: storeInfo.roomInfo[targetRoomIndex].roomSaleRate,
                        RIPName: RIPName,
                        RIPResponsibilityName: RIPResponsibilityName,
                        RecommendName: RecommendName,
                        RecommendPhone: RecommendPhone
                    }]
                }
                storeHistoryIndex = 0;
                fDb.collection('StoreHistory').doc(storeInfo.companyUid).set(addHistoryData);
            }

            actConfirmBookStep3(startDate, endDate, userHistoryIndex, storeHistoryIndex);
        })
    }

    const actConfirmBookStep3 = async (startDate, endDate, userHistoryIndex, storeHistoryIndex) => {

        let tempStoreInfo = storeInfo;
        let targetRoom = targetRoomInfo;
        targetRoom.roomBookStart = startDate;
        targetRoom.roomBookEnd = endDate;
        targetRoom.roomBookState = false;
        targetRoom.roomBookUserUid = userInfo.uid;
        targetRoom.roomBookUserInfo = userInfo.name + " " + userInfo.phone;
        targetRoom.RIPName = RIPName;
        targetRoom.RIPResponsibilityName = RIPResponsibilityName;
        targetRoom.userHistoryIndex = userHistoryIndex;
        targetRoom.storeHistoryIndex = storeHistoryIndex;
        tempStoreInfo.roomInfo[targetRoomIndex] = targetRoom;

        // 예약정보 업데이트
        fDb.collection('StoreInfo').doc(storeInfo.companyUid).update(tempStoreInfo);

        setIsAlert([false, false, "", true, -1]);

        navigation.goBack();
    }


    const confirmBook = () => {
        if (!agreement1) {
            setIsAlert([true, false, "개인정보 보호정책에 동의해주세요.", true, -1]);
            return;
        }

        if (RIPName.length === 0) {
            setIsAlert([true, false, "고인 성함을 입력해주세요.", true, -1]);
            return;
        }

        if (RIPResponsibilityName.length === 0) {
            setIsAlert([true, false, "상주님 성함을 입력해주세요.", true, -1]);
            return;
        }

        const guide = "고인 성함 : " + RIPName + " 님\n상주님 성함 : " + RIPResponsibilityName + "\n위 정보로 장례식장을 예약하시겠습니까?";

        setIsAlert([true, false, guide, true, 0]);
    }

    // 장례식장 이름, 방 이름, 가격, 예약자 이름, 예약일시
    return (
        <View style={{ width: '100%', flex: 1, alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView style={{ backgroundColor: GetColors(0), flex: 1 }}>
                <BackView style={{ backgroundColor: GetColors(0), width: 400 }}>
                    <View style={{ flexDirection: 'row', paddingTop: 3, alignItems: 'center', paddingTop: 20 }}>
                        <GuideText5>국화 </GuideText5>
                        <GuideText1>와 함께 해주셔서 감사합니다.</GuideText1>
                    </View>
                    <View style={{ flexDirection: 'row', paddingTop: 3, alignItems: 'center', paddingTop: 20 }}>
                        <GuideText5>예약 확정 </GuideText5>
                        <GuideText1>을 하시려면</GuideText1>
                    </View>
                    <GuideText1>아래정보를 다시 한번 확인해주세요.</GuideText1>
                    <GuideText1>{"\n"}입력하신 정보는{"\n"}'국화'의 개인정보 보호정책에 따라 관리됩니다.</GuideText1>
                    <GuideText2 style={{ paddingTop: 20, paddingBottom: 10 }}>예약정보</GuideText2>
                    <View style={{ borderRadius: 5, borderWidth: 1, borderColor: GetColors(1), padding: 10 }}>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>장례식장 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{storeInfo.companyName}</GuideText7>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>빈소 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{targetRoomInfo.roomName}</GuideText7>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>가격 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{targetRoomInfo.roomPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</GuideText7>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>{during}일간 총 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{(targetRoomInfo.roomPrice * during).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</GuideText7>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>장례지도사 : </GuideText1>
                            <GuideText7 style={{ flex: 7 }}>{userInfo.name}</GuideText7>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                            <GuideText1 style={{ flex: 3 }}>예약일자 : </GuideText1>
                        </View>
                        <GuideText7 style={{ paddingTop: 5, textAlign: 'center' }}>{roomBookStart.split(',')[0]}년{roomBookStart.split(',')[1]}월{roomBookStart.split(',')[2]}일 부터{"\n"}{roomBookEnd.split(',')[0]}년{roomBookEnd.split(',')[1]}월{roomBookEnd.split(',')[2]}일 까지</GuideText7>
                    </View>
                    <GuideText2 style={{ paddingTop: 20 }}>고인 성함</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="- 고인의 성함을 입력해주세요"
                        onChange={onChange11}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>상주 성함</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="- 상주님의 성함을 입력해주세요"
                        onChange={onChange22}></TextInput>
                    {userInfo.userCode === 1 ?
                        <>
                            <GuideText2 style={{ paddingTop: 20, paddingBottom: 10 }}>추천인 정보(장례지도사)</GuideText2>
                            <View style={{ borderRadius: 5, borderWidth: 1, borderColor: GetColors(1), padding: 10 }}>
                                <GuideText2 style={{ paddingTop: 20 }}>추천인 이름(선택)</GuideText2>
                                <TextInput style={{
                                    paddingLeft: 15,
                                    marginTop: 5,
                                    height: 40,
                                    borderRadius: 5,
                                    borderColor: GetColors(17),
                                    borderWidth: 0.5
                                }}
                                    placeholder="-추천인(장례지도사)의 이름을 입력해주세요."
                                    onChange={onChange33}></TextInput>

                                <GuideText2 style={{ paddingTop: 20 }}>추천인 연락처(선택)</GuideText2>
                                <TextInput style={{
                                    paddingLeft: 15,
                                    marginTop: 5,
                                    height: 40,
                                    borderRadius: 5,
                                    borderColor: GetColors(17),
                                    borderWidth: 0.5
                                }}
                                    keyboardType="number-pad"
                                    placeholder="-추천인(장례지도사)의 연락처를 입력해주세요."
                                    onChange={onChange44}></TextInput>
                            </View>
                        </>
                        :
                        <></>}
                    <GuideText3 style={{ paddingTop: 20 }}>개인정보 보호약관</GuideText3>
                    <TouchableOpacity onPress={() => Linking.openURL(`https://theonplatform.imweb.me/terms`)}>
                        <GuideText3 style={{ color: GetColors(5) }}>https://theonplatform.imweb.me/terms</GuideText3>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{ marginTop: 10, flexDirection: 'row', height: 30, alignItems: 'center' }}
                        onPress={() => setAgreement1(!agreement1)}>
                        {agreement1 ?
                            <AntDesign name="checkcircleo" size={25} color={GetColors(5)} />
                            :
                            <FontAwesome name="circle-thin" size={29} color='grey' />
                        }
                        <GuideText6 style={{ color: agreement1 ? GetColors(5) : 'grey' }}>  개인정보 보호 약관 동의</GuideText6>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={confirmBook} style={{ marginTop: 30, justifyContent: 'center', alignItems: 'center', height: 45, borderRadius: 30, backgroundColor: GetColors(5) }}
                    >
                        <GuideText4>확정</GuideText4>
                    </TouchableOpacity>
                </BackView>
                <AwesomeAlert
                    show={isAlert[0]}
                    showProgress={isAlert[1]}
                    title="국화"
                    message={isAlert[2]}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showCancelButton={false}
                    showConfirmButton={isAlert[3]}
                    cancelText=""
                    confirmText=" 확인 "
                    confirmButtonColor="#000000"
                    onCancelPressed={() => {
                    }}
                    onConfirmPressed={() => {
                        if (isAlert[4] == 0) {
                            actConfirmBookStep1();
                        }
                        setIsAlert([false, false, "국화", true, -1]);
                    }}
                />
            </KeyboardAwareScrollView>
        </View>
    )
}

export default BookConfirm;
