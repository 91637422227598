import React, { useState, useEffect } from "react";
import { View, Text, Image, Dimensions, TouchableOpacity, TextInput } from "react-native"
import fbInstance from "../Config/fbInstance";
import styled from "styled-components/native";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import AwesomeAlert from 'react-native-awesome-alerts';
import moment from "moment";
import { GetColors } from '../Config/Datas';

// const windowHeight = Dimensions.get('window').height;
// const windowWidth = Dimensions.get('window').width;

const BackView = styled.View`
    padding-left: 45px;
    padding-right: 45px;
    background-color: ${GetColors(0)};
`;

const GuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 500;
`;


const GuideText11 = styled.Text`
    font-size: 20px;
    font-weight: 500;
`;

const GuideText2 = styled.Text`
    font-size: 18px;
    font-weight: 600;
`;


const GuideText22 = styled.Text`
    font-size: 15px;
    font-weight: 500;
    color: ${GetColors(5)};
`;

const GuideText3 = styled.Text`
    font-size: 20px;
    font-weight: 700;
    color: ${GetColors(0)};
`;

const GuideText4 = styled.Text`
    font-size: 18px;
    font-weight: 700;
    color: ${GetColors(5)};
`;

const GuideText5 = styled.Text`
    font-size: 14px;
    font-weight: 500;
    color: ${GetColors(17)};
`;

const NormalInfo = ({ navigation, route }) => {
    const [isAlert, setIsAlert] = useState([false, "메세지", false, true]);
    const fDb = fbInstance.firestore();
    const fAuth = fbInstance.auth();
    let user = route.params.user;
    const [confirm, setConfirm] = useState(false);
    const [email, setEmail] = useState(user.email);
    const [name, setName] = useState(user.name);
    const [phone, setPhone] = useState(user.phone);
    const [password, setPassword] = useState("");
    const [vPassword, setVPassword] = useState("");
    const [shopName, setShopName] = useState(user.shopName);
    const [shopNumber, setShopNumber] = useState(user.shopNumber);
    const [shopAddress, setShopAddress] = useState(user.shopAddress);
    const [shopFounder, setShopFounder] = useState(user.shopFounder);
    const [shopConnect, setShopConnect] = useState(user.shopConnect);
    const [image, setImage] = useState(user.licenseUrl);

    const onChangeEmail = t => {
        setEmail(t.nativeEvent.text);
    }
    const onChangeName = t => {
        setName(t.nativeEvent.text);
    }
    const onChangePhone = text => {
        setPhone(text.nativeEvent.text);
    }
    const onChangePw = text => {
        setPassword(text.nativeEvent.text);
    }
    const onChangeVPw = text => {
        setVPassword(text.nativeEvent.text);
    }
    const onChangeShopName = t => {
        setShopName(t.nativeEvent.text);
    }
    const onChangeShopNumber = t => {
        setShopNumber(t.nativeEvent.text);
    }
    const onChangeShopAddress = text => {
        setShopAddress(text.nativeEvent.text);
    }
    const onChangeShopFounder = text => {
        setShopFounder(text.nativeEvent.text);
    }
    const onChangeShopConnect = text => {
        setShopConnect(text.nativeEvent.text);
    }

    const chnageUserInfo = async () => {
        if (user.userCode === 2) {
            if (name.length === 0 ||
                phone.length === 0 ||
                shopName.length === 0 ||
                shopNumber.length === 0 ||
                shopAddress.length === 0 ||
                shopFounder.length === 0 ||
                shopConnect.length === 0) {
                setIsAlert([true, "입력정보를 확인해주세요.", false, true]);
                return;
            }
        }
        else {
            if (name.length === 0 || phone.length === 0) {
                setIsAlert([true, "입력정보를 확인해주세요.", false, true]);
                return;
            }
        }

        setIsAlert([true, "회원정보를 업데이트중입니다.\n잠시만 기다려주세요.", true, false]);
        user.name = name;
        user.phone = phone;
        user.shopName = shopName;
        user.shopNumber = shopNumber;
        user.shopAddress = shopAddress;
        user.shopFounder = shopFounder;
        user.shopConnect = shopConnect;

        if (password.length != 0 || vPassword.length != 0) {
            if (password !== vPassword) {
                setIsAlert([true, "비밀번호가 일치하지 않습니다.", false, true]);
                return;
            }

            if (password.length < 6) {
                setIsAlert([true, "6자리 이상의 비밀번호를 설정해주세요.", false, true]);
                return;
            }

            fAuth.currentUser.updatePassword(password).then(() => {

            }).catch((error) => {

            });
        }

        if (user.email != email) {
            if (email.length === 0) {
                setIsAlert([true, "이메일 주소를 확인해주세요.", false, true]);
                return;
            }

            let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
            if (reg.test(email) === false) {
                setIsAlert([true, "이메일 형식을 확인해주세요.", false, true]);
                return;
            }

            user.email = email;
            fDb.collection('GUsers').doc(user.uid).update(user);

            fAuth.currentUser.updateEmail(email).then(() => {
                user.email = email;
                fDb.collection('GUsers').doc(user.uid).update(user);
                setIsAlert([false, "", false, false]);
                navigation.goBack();
                navigation.goBack();
            }).catch((error) => {
                console.log(error);
            });
        }
        else {
            fDb.collection('GUsers').doc(user.uid).update(user);
            setIsAlert([false, "", false, false]);
            navigation.goBack();
            navigation.goBack();
        }
    }

    const Login = async () => {
        setIsAlert([true, "로그인중입니다.\n잠시만 기다려주세요.", true, false]);
        await fAuth.signInWithEmailAndPassword(email, password)
            .then((response) => {
                setConfirm(true);
                setPassword("");
                setIsAlert([false, "", false, false]);
            })
            .catch((e) => {
                setIsAlert([true, "로그인에 실패했습니다.\n다시 시도해주세요.", false, true]);
                setPassword("");
            });
    }

    /* (상호, 사업자등록번호, 사업주소지, 대표자성명, 소속(선택사항)) */
    // shopName, shopNumber, shopAddress, shopFounder, shopConnect
    return (
        <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView style={{ backgroundColor: GetColors(0), width: 400 }}>
                {confirm ?
                    <BackView>
                        <GuideText1 style={{ paddingTop: 20 }}>안녕하세요.</GuideText1>
                        <View style={{ flexDirection: 'row', paddingTop: 3, alignItems: 'center' }}>
                            <GuideText4>국화</GuideText4>
                            <GuideText1>에 오신것을 환영합니다.</GuideText1>
                        </View>
                        <GuideText2 style={{ paddingTop: 20 }}>이메일</GuideText2>
                        <View style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5,
                            justifyContent: 'center',
                        }}>
                            <GuideText5>{user.email}</GuideText5>
                        </View>
                        <GuideText2 style={{ paddingTop: 20 }}>수정할 이메일을 입력해주세요.</GuideText2>
                        <GuideText22>(수정을 원하지 않으시면 변경하지 마세요.)</GuideText22>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="email-address"
                            placeholder="예: gukwha@naver.com"
                            value={email}
                            secureTextEntry={false}
                            onChange={onChangeEmail}></TextInput>

                        <GuideText2 style={{ paddingTop: 20 }}>수정할 비밀번호를 입력해주세요.</GuideText2>
                        <GuideText22>(수정을 원하지 않으시면 입력하지 마세요.)</GuideText22>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="default"
                            secureTextEntry={true}
                            placeholder="영문, 숫자 조합 최소 6자 이상"
                            value={password}
                            onChange={onChangePw}></TextInput>
                        <GuideText2 style={{ paddingTop: 20 }}>비밀번호 확인</GuideText2>
                        <GuideText22>(수정을 원하지 않으시면 입력하지 마세요.)</GuideText22>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="default"
                            secureTextEntry={true}
                            placeholder="비밀번호를 다시한번 입력해주세요."
                            value={vPassword}
                            onChange={onChangeVPw}></TextInput>
                        <GuideText2 style={{ paddingTop: 20 }}>이름</GuideText2>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            placeholder="홍길동"
                            value={name}
                            onChange={onChangeName}></TextInput>
                        <GuideText2 style={{ paddingTop: 20 }}>전화번호</GuideText2>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="number-pad"
                            placeholder="010 1234 1234"
                            value={phone}
                            onChange={onChangePhone}></TextInput>
                        {user.userCode === 2 ?
                            <>
                                <View style={{ flexDirection: 'row', marginTop: 25, alignItems: 'center' }}>
                                    <GuideText4>장례지도사 등록정보</GuideText4>
                                </View>
                                <GuideText2 style={{ paddingTop: 20 }}>장례지도사 자격증</GuideText2>
                                <View style={{ marginTop: 10, padding: 10, justifyContent: 'center', alignItems: 'center', borderWidth: 1, borderRadius: 10 }}>
                                    <Image
                                        source={{ uri: image }}
                                        style={{
                                            borderRadius: 10,
                                            width: 230,
                                            height: 350,
                                        }} />
                                </View>
                                <GuideText2 style={{ paddingTop: 20 }}>상호(개인사업자)</GuideText2>
                                <TextInput style={{
                                    paddingLeft: 15,
                                    marginTop: 5,
                                    height: 40,
                                    borderRadius: 5,
                                    borderColor: GetColors(17),
                                    borderWidth: 0.5
                                }}
                                    placeholder="국화"
                                    value={shopName}
                                    onChange={onChangeShopName}></TextInput>
                                <GuideText2 style={{ paddingTop: 20 }}>사업자등록번호(개인사업자)</GuideText2>
                                <TextInput style={{
                                    paddingLeft: 15,
                                    marginTop: 5,
                                    height: 40,
                                    borderRadius: 5,
                                    borderColor: GetColors(17),
                                    borderWidth: 0.5
                                }}
                                    keyboardType="number-pad"
                                    placeholder="사업자번호를 입력해주세요."
                                    value={shopNumber}
                                    onChange={onChangeShopNumber}></TextInput>
                                <GuideText2 style={{ paddingTop: 20 }}>사업주소지(개인사업자)</GuideText2>
                                <TextInput style={{
                                    paddingLeft: 15,
                                    marginTop: 5,
                                    height: 40,
                                    borderRadius: 5,
                                    borderColor: GetColors(17),
                                    borderWidth: 0.5
                                }}
                                    placeholder="예) 인천 남동구 논현동 447"
                                    value={shopAddress}
                                    onChange={onChangeShopAddress}></TextInput>
                                <GuideText2 style={{ paddingTop: 20 }}>대표자성명</GuideText2>
                                <TextInput style={{
                                    paddingLeft: 15,
                                    marginTop: 5,
                                    height: 40,
                                    borderRadius: 5,
                                    borderColor: GetColors(17),
                                    borderWidth: 0.5
                                }}
                                    placeholder="예) 홍길동"
                                    value={shopFounder}
                                    onChange={onChangeShopFounder}></TextInput>
                                <GuideText2 style={{ paddingTop: 20 }}>소속(선택사항)</GuideText2>
                                <TextInput style={{
                                    paddingLeft: 15,
                                    marginTop: 5,
                                    height: 40,
                                    borderRadius: 5,
                                    borderColor: GetColors(17),
                                    borderWidth: 0.5
                                }}
                                    placeholder="예) 프리드 상조"
                                    value={shopConnect}
                                    onChange={onChangeShopConnect}></TextInput>
                            </>
                            :
                            <></>}
                        <GuideText1 style={{ paddingTop: 20 }}>수정하시기 전에 위 정보를 확인해주세요.</GuideText1>
                        <TouchableOpacity style={{ marginTop: 30, justifyContent: 'center', alignItems: 'center', height: 45, borderRadius: 30, backgroundColor: GetColors(5) }}
                            onPress={chnageUserInfo}>
                            <GuideText3>회원정보 수정</GuideText3>
                        </TouchableOpacity>
                        <GuideText3>{"\n"}{"\n"}{"\n"}{"\n"}</GuideText3>
                    </BackView>
                    :
                    <BackView>
                        <View style={{ flexDirection: 'row', paddingTop: 15, paddingBottom: 5, alignItems: 'center' }}>
                            <GuideText4>국화</GuideText4>
                            <GuideText1>에 오신것을 환영합니다.</GuideText1>
                        </View>
                        <GuideText11>회원정보를 수정하시려면{"\n"}비밀번호를 다시 한번 입력해주세요.</GuideText11>
                        <GuideText2 style={{ paddingTop: 20 }}>이메일</GuideText2>
                        <View style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5,
                            justifyContent: 'center',
                        }}>
                            <GuideText5>{user.email}</GuideText5>
                        </View>
                        <GuideText2 style={{ paddingTop: 20 }}>비밀번호</GuideText2>
                        <TextInput style={{
                            paddingLeft: 15,
                            marginTop: 5,
                            height: 40,
                            borderRadius: 5,
                            borderColor: GetColors(17),
                            borderWidth: 0.5
                        }}
                            keyboardType="default"
                            secureTextEntry={true}
                            placeholder="영문, 숫자 조합 최소 6자 이상"
                            value={password}
                            onChange={onChangePw}></TextInput>
                        <TouchableOpacity style={{ marginTop: 30, justifyContent: 'center', alignItems: 'center', height: 45, borderRadius: 30, backgroundColor: GetColors(5) }}
                            onPress={Login}>
                            <GuideText3>로그인</GuideText3>
                        </TouchableOpacity>
                    </BackView>}
                <AwesomeAlert
                    show={isAlert[0]}
                    showProgress={isAlert[2]}
                    title="국화"
                    message={isAlert[1]}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showCancelButton={false}
                    showConfirmButton={isAlert[3]}
                    cancelText=""
                    confirmText=" 확인 "
                    confirmButtonColor="#000000"
                    onCancelPressed={() => {
                        setIsAlert([false, "", false, true]);
                    }}
                    onConfirmPressed={() => {
                        setIsAlert([false, "", false, true]);
                    }}
                />
            </KeyboardAwareScrollView>
        </View>
    )
}

export default NormalInfo;