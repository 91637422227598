import React, { useState, useEffect } from "react";
import { Text, View, Dimensions } from "react-native";
import fbInstance from '../Config/fbInstance';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import styled from "styled-components/native";
import moment from "moment";
import { GetColors } from '../Config/Datas';

const { width: WIDTH, height: HEIGHT } = Dimensions.get("window")

const NoticeView = styled.TouchableOpacity`
    border-radius: 5px;
    background-color: ${GetColors(0)};
    border-color: ${GetColors(18)};
    border-width: 1px;
`;

const TitleText = styled.Text`
    align-items: center;
    align-content: center;
    margin: 0px 10px;
    font-size: 16px;
    font-weight: bold;
`;

const BackView = styled.View`
    padding-top: 30px;
    padding-left: 45px;
    padding-right: 45px;
`;

const GuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 500;
`;

const GuideText2 = styled.Text`
    font-size: 12px;
    font-weight: 400;
`;

const GuideText3 = styled.Text`
    font-size: 16px;
    font-weight: 400;
`;

const fAuth = fbInstance.auth();

const Notice = ({ navigation }) => {
    const fDb = fbInstance.firestore();
    const [notice, setNotice] = useState([]);
    const [userCode, setUserCode] = useState(-1);
    const [isLogin, setIsLogin] = useState(fAuth.currentUser);

    useEffect(() => {
        getNotice();
    }, [userCode]);

    useEffect(() => {
        if (fAuth.currentUser === null) {
            return;
        }
        fDb.collection('GUsers').doc(fAuth.currentUser.uid).get().then((doc) => {
            setUserCode(doc.data().userCode);
            setIsLogin(fAuth.currentUser);
        });
    }, [fAuth.currentUser])

    navigation.addListener('tabPress', (e) => {
        setIsLogin(fAuth.currentUser);
        if (fAuth.currentUser != null) {
            fDb.collection('GUsers').doc(fAuth.currentUser.uid).get().then((doc) => {
                setUserCode(doc.data().userCode);
                setIsLogin(fAuth.currentUser);
            });
        }
    });


    const getNotice = () => {
        fDb.collection('Notice').get().then((querySnapshot) => {
            let noticeTemp = [];
            querySnapshot.forEach((doc) => {
                const temp = {
                    id: doc.id,
                    isOpen: false,
                    ...doc.data()
                }
                // 장례식장
                if (userCode === 0) {
                    // tmep.target 2 + 0
                    if (temp.target === "0" || temp.target === "2") {
                        noticeTemp.push(temp);
                    }
                }
                // 장례지도사
                else if (userCode === 2) {
                    // tmep.target 1 + 0
                    if (temp.target === "0" || temp.target === "1") {
                        noticeTemp.push(temp);
                    }
                }
                else {
                    if (temp.target === "0") {
                        noticeTemp.push(temp);
                    }
                }
            });
            setNotice(noticeTemp);
        });
    }

    const openNotice = (id, index) => {
        setNotice(
            notice.map(noti =>
                noti.id === id ? { ...noti, isOpen: !noti.isOpen } : noti
            )
        )
    }

    return (
        <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView
                style={{ backgroundColor: GetColors(0), width: WIDTH < 400 ? '100%' : 400 }}>
                <BackView>
                    {notice.length !== 0 ?
                        notice.map((noti, index) => (
                            <NoticeView key={noti.id} style={{
                                height: noti.isOpen ? 180 : 48,
                                shadowColor: 'black',
                                shadowRadius: 1,
                                shadowOpacity: 0.1,
                                shadowOffset: { width: 1, height: 2 },
                                marginBottom: 16,
                                paddingLeft: 15,
                                paddingRight: 15,
                                elevation: 3
                            }} onPress={() => openNotice(noti.id, index)}>
                                <View style={{ height: 48, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <GuideText1 style={{ paddingTop: 15, paddingBottom: 15 }}>{noti.title}</GuideText1>
                                    <GuideText2 style={{ paddingTop: 15, paddingBottom: 15 }}>작성일 {noti.date}</GuideText2>
                                </View>
                                {noti.isOpen ?
                                    <>
                                        <View style={{ height: 0.5, backgroundColor: GetColors(19), marginBottom: 15 }} />
                                        <GuideText3>{noti.contents}</GuideText3>
                                    </>
                                    :
                                    <></>}
                            </NoticeView>
                        ))
                        :
                        <></>}
                </BackView>
            </KeyboardAwareScrollView>
        </View>
    )
}

export default Notice;