
// 색 가져오는 함수
// 0 : 배경
// 1 : 메인 폰트색
// 2 : 서브 폰트색
// 3 : 서브 배경(예약가능 배경)
// 4 : 라인 색
// 5 : 포인트 색
// 6 : 서브 포인트 색
// 7 : 작은 폰트 색
// 8 : 탭 아이콘 색
// 9 : 레드
// 10 : 테스트
// 11 : #323B45
// 12 : #395AFF
// 13 : #FFD500
// 14 : #B7B7B7
// 15 : #1A1A1A
// 16 : #9F9F9F
// 17 : #C8C8C8
// 18 : #DCDCDC
// 19 : #B1B1B1
// 20 : #FE5F5F
// 21 : #F2E1E1
// 22 : #E1E1E1
// 23 : #545454
export const GetColors = (index) => {
    const colors = [
        "#ffffff",
        "#191919",
        "#000000",
        "#F3F4F8",
        "#BEBEBE",
        "#3B5EC9",
        "#E1E5F2",
        "#707070",
        "#242424",
        "#E63B3B",
        "#00000080",
        "#323B45",
        "#395AFF",
        "#FFD500",
        "#B7B7B7",
        "#1A1A1A",
        "#9F9F9F",
        "#C8C8C8",
        "#DCDCDC",
        "#B1B1B1",
        "#FE5F5F",
        "#F2E1E1",
        "#E1E1E1",
        "#545454"];
    return colors[index];
}

export const trimText = (text = "", limit) => text.length > limit ? `${text.slice(0, limit)}...` : text;


export const GetRoomStateName = () => {
    const roomState = ["빈소", "접객실", "상주 휴게실", "상주 샤워실", "금고", "접수대"];
    return roomState;
}

export const GetPickedTime = () => {
    const time = [
        { label: '발인시간 선택', value: '0' },
        { label: '03:00', value: '1' },
        { label: '03:30', value: '2' },
        { label: '04:00', value: '3' },
        { label: '04:30', value: '4' },
        { label: '05:00', value: '5' },
        { label: '05:30', value: '6' },
        { label: '06:00', value: '7' },
        { label: '06:30', value: '8' },
        { label: '07:00', value: '9' },
        { label: '07:30', value: '10' },
        { label: '08:00', value: '11' },
        { label: '08:30', value: '12' },
        { label: '09:00', value: '13' },
        { label: '09:30', value: '14' },
        { label: '10:00', value: '15' },
        { label: '10:30', value: '16' },
        { label: '11:00', value: '17' },
        { label: '11:30', value: '18' },
        { label: '12:00', value: '19' }
    ];
    return time;
}


export const GetPickedTime2 = () => {
    const time = [
        { label: '입실시간 선택', value: '0' },
        { label: '00:00', value: '1' },
        { label: '00:30', value: '2' },
        { label: '01:00', value: '3' },
        { label: '01:30', value: '4' },
        { label: '02:00', value: '5' },
        { label: '02:30', value: '6' },
        { label: '03:00', value: '7' },
        { label: '03:30', value: '8' },
        { label: '04:00', value: '9' },
        { label: '04:30', value: '10' },
        { label: '05:00', value: '11' },
        { label: '05:30', value: '12' },
        { label: '06:00', value: '13' },
        { label: '06:30', value: '14' },
        { label: '07:00', value: '15' },
        { label: '07:30', value: '16' },
        { label: '08:00', value: '17' },
        { label: '08:30', value: '18' },
        { label: '09:00', value: '19' },
        { label: '09:30', value: '20' },
        { label: '10:00', value: '21' },
        { label: '10:30', value: '22' },
        { label: '11:00', value: '23' },
        { label: '11:30', value: '24' },
        { label: '12:00', value: '25' },
        { label: '12:30', value: '26' },
        { label: '13:00', value: '27' },
        { label: '13:30', value: '28' },
        { label: '14:00', value: '29' },
        { label: '14:30', value: '30' },
        { label: '15:00', value: '31' },
        { label: '15:30', value: '32' },
        { label: '16:00', value: '33' },
        { label: '16:30', value: '34' },
        { label: '17:00', value: '35' },
        { label: '17:30', value: '36' },
        { label: '18:00', value: '37' },
        { label: '18:30', value: '38' },
        { label: '19:00', value: '39' },
        { label: '19:30', value: '40' },
        { label: '20:00', value: '41' },
        { label: '20:30', value: '42' },
        { label: '21:00', value: '43' },
        { label: '21:30', value: '44' },
        { label: '22:00', value: '45' },
        { label: '22:30', value: '46' },
        { label: '23:00', value: '47' },
        { label: '23:30', value: '48' },
        { label: '24:00', value: '49' }
    ];
    return time;
}

export const GetAddress = (num) => {
    // 한국
    const code1 = [
        { label: '서울특별시', value: '0' },
        { label: '부산광역시', value: '1' },
        { label: '대구광역시', value: '2' },
        { label: '인천광역시', value: '3' },
        { label: '광주광역시', value: '4' },
        { label: '대전광역시', value: '5' },
        { label: '울산광역시', value: '6' },
        { label: '세종특별자치시', value: '7' },
        { label: '경기남부', value: '8' },
        { label: '경기북부', value: '9' },
        { label: '강원도', value: '10' },
        { label: '충청북도', value: '11' },
        { label: '충청남도', value: '12' },
        { label: '전라북도', value: '13' },
        { label: '전라남도', value: '14' },
        { label: '경상북도', value: '15' },
        { label: '경상남도', value: '16' },
        { label: '제주특별자치도', value: '17' }
    ];

    // 서울특별시
    const sub_code0 = [
        { label: '전체', value: '-1' },
        { label: '종로구', value: '0' },
        { label: '중구', value: '1' },
        { label: '용산구', value: '2' },
        { label: '성동구', value: '3' },
        { label: '광진구', value: '4' },
        { label: '동대문구', value: '5' },
        { label: '중랑구', value: '6' },
        { label: '성북구', value: '7' },
        { label: '강북구', value: '8' },
        { label: '도봉구', value: '9' },
        { label: '노원구', value: '10' },
        { label: '은평구', value: '11' },
        { label: '서대문구', value: '12' },
        { label: '마포구', value: '13' },
        { label: '양천구', value: '14' },
        { label: '강서구', value: '15' },
        { label: '구로구', value: '16' },
        { label: '금천구', value: '17' },
        { label: '영등포구', value: '18' },
        { label: '동작구', value: '19' },
        { label: '관악구', value: '20' },
        { label: '서초구', value: '21' },
        { label: '강남구', value: '22' },
        { label: '송파구', value: '23' },
        { label: '강동구', value: '24' }
    ];

    // 부산광역시
    const sub_code1 = [
        { label: '전체', value: '-1' },
        { label: '중구', value: '0' },
        { label: '서구', value: '1' },
        { label: '동구', value: '2' },
        { label: '영도구', value: '3' },
        { label: '부산진구', value: '4' },
        { label: '동래구', value: '5' },
        { label: '남구', value: '6' },
        { label: '북구', value: '7' },
        { label: '강서구', value: '8' },
        { label: '해운대구', value: '9' },
        { label: '사하구', value: '10' },
        { label: '금정구', value: '11' },
        { label: '연제구', value: '12' },
        { label: '수영구', value: '13' },
        { label: '사상구', value: '14' },
        { label: '기장군', value: '15' }
    ];

    // 대구광역시
    const sub_code2 = [
        { label: '전체', value: '-1' },
        { label: '중구', value: '0' },
        { label: '동구', value: '1' },
        { label: '서구', value: '2' },
        { label: '남구', value: '3' },
        { label: '북구', value: '4' },
        { label: '수성구', value: '5' },
        { label: '달서구', value: '6' },
        { label: '달성군', value: '7' }
    ];

    // 인천광역시
    const sub_code3 = [
        { label: '전체', value: '-1' },
        { label: '중구', value: '0' },
        { label: '동구', value: '1' },
        { label: '미추홀구', value: '2' },
        { label: '연수구', value: '3' },
        { label: '남동구', value: '4' },
        { label: '부평구', value: '5' },
        { label: '계양구', value: '6' },
        { label: '서구', value: '7' },
        { label: '강화군', value: '8' },
        { label: '옹진군', value: '9' }
    ];

    // 광주광역시
    const sub_code4 = [
        { label: '전체', value: '-1' },
        { label: '동구', value: '0' },
        { label: '서구', value: '1' },
        { label: '남구', value: '2' },
        { label: '북구', value: '3' },
        { label: '광산구', value: '4' }
    ];

    // 대전광역시
    const sub_code5 = [
        { label: '전체', value: '-1' },
        { label: '동구', value: '0' },
        { label: '중구', value: '1' },
        { label: '서구', value: '2' },
        { label: '유성구', value: '3' },
        { label: '대덕구', value: '4' }
    ];

    // 울산광역시
    const sub_code6 = [
        { label: '전체', value: '-1' },
        { label: '중구', value: '0' },
        { label: '남구', value: '1' },
        { label: '동구', value: '2' },
        { label: '북구', value: '3' },
        { label: '울주군', value: '4' }
    ];

    // 세종특별자치시
    const sub_code7 = [
        { label: '전체', value: '-1' },
        { label: '전체', value: '0' }
    ];

    // 경기남부
    const sub_code8 = [
        { label: '전체', value: '-1' },
        { label: '수원시', value: '0' },
        { label: '장안구', value: '1' },
        { label: '권선구', value: '2' },
        { label: '팔달구', value: '3' },
        { label: '영통구', value: '4' },
        { label: '성남시', value: '5' },
        { label: '수정구', value: '6' },
        { label: '중원구', value: '7' },
        { label: '분당구', value: '8' },
        { label: '안양시', value: '9' },
        { label: '만안구', value: '10' },
        { label: '동안구', value: '11' },
        { label: '안산시', value: '12' },
        { label: '상록구', value: '13' },
        { label: '단원구', value: '14' },
        { label: '용인시', value: '15' },
        { label: '처인구', value: '16' },
        { label: '기흥구', value: '17' },
        { label: '수지구', value: '18' },
        { label: '부천시', value: '19' },
        { label: '광명시', value: '20' },
        { label: '평택시', value: '21' },
        { label: '과천시', value: '22' },
        { label: '오산시', value: '23' },
        { label: '시흥시', value: '24' },
        { label: '군포시', value: '25' },
        { label: '의왕시', value: '26' },
        { label: '하남시', value: '27' },
        { label: '이천시', value: '28' },
        { label: '안성시', value: '29' },
        { label: '김포시', value: '30' },
        { label: '화성시', value: '31' },
        { label: '광주시', value: '32' },
        { label: '여주시', value: '33' },
        { label: '양평군', value: '34' }
    ];

    // 경기북부
    const sub_code9 = [
        { label: '전체', value: '-1' },
        { label: '고양시', value: '0' },
        { label: '덕양구', value: '1' },
        { label: '일산동구', value: '2' },
        { label: '일산서구', value: '3' },
        { label: '자치시', value: '4' },
        { label: '의정부시', value: '5' },
        { label: '동두천시', value: '6' },
        { label: '구리시', value: '7' },
        { label: '남양주시', value: '8' },
        { label: '파주시', value: '9' },
        { label: '양주시', value: '10' },
        { label: '포천시', value: '11' },
        { label: '연천군', value: '12' },
        { label: '가평군', value: '13' }
    ];

    // 강원도
    const sub_code10 = [
        { label: '전체', value: '-1' },
        { label: '춘천시', value: '0' },
        { label: '원주시', value: '1' },
        { label: '강릉시', value: '2' },
        { label: '동해시', value: '3' },
        { label: '태백시', value: '4' },
        { label: '속초시', value: '5' },
        { label: '삼척시', value: '6' },
        { label: '홍천군', value: '7' },
        { label: '횡성군', value: '8' },
        { label: '영월군', value: '9' },
        { label: '평창군', value: '10' },
        { label: '정선군', value: '11' },
        { label: '철원군', value: '12' },
        { label: '화천군', value: '13' },
        { label: '양구군', value: '14' },
        { label: '인제군', value: '15' },
        { label: '고성군', value: '16' },
        { label: '양양군', value: '17' }
    ];

    // 충청북도
    const sub_code11 = [
        { label: '전체', value: '-1' },
        { label: '청주시', value: '0' },
        { label: '상당구', value: '1' },
        { label: '흥덕구', value: '2' },
        { label: '서원구', value: '3' },
        { label: '청원구', value: '4' },
        { label: '자치시', value: '5' },
        { label: '충주시', value: '6' },
        { label: '제천시', value: '7' },
        { label: '보은군', value: '8' },
        { label: '옥천군', value: '9' },
        { label: '영동군', value: '10' },
        { label: '증평군', value: '11' },
        { label: '진천군', value: '12' },
        { label: '괴산군', value: '13' },
        { label: '음성군', value: '14' },
        { label: '단양군', value: '15' }
    ];

    // 충청남도
    const sub_code12 = [
        { label: '전체', value: '-1' },
        { label: '천안시', value: '0' },
        { label: '동남구', value: '1' },
        { label: '서북구', value: '2' },
        { label: '자치시', value: '3' },
        { label: '공주시', value: '4' },
        { label: '보령시', value: '5' },
        { label: '아산시', value: '6' },
        { label: '서산시', value: '7' },
        { label: '논산시', value: '8' },
        { label: '계룡시', value: '9' },
        { label: '당진시', value: '10' },
        { label: '금산군', value: '11' },
        { label: '부여군', value: '12' },
        { label: '서천군', value: '13' },
        { label: '청양군', value: '14' },
        { label: '홍성군', value: '15' },
        { label: '예산군', value: '16' },
        { label: '태안군', value: '17' }
    ];

    // 전라북도
    const sub_code13 = [
        { label: '전체', value: '-1' },
        { label: '전주시', value: '0' },
        { label: '완산구', value: '1' },
        { label: '덕진구', value: '2' },
        { label: '자치시', value: '3' },
        { label: '군산시', value: '4' },
        { label: '익산시', value: '5' },
        { label: '정읍시', value: '6' },
        { label: '남원시', value: '7' },
        { label: '김제시', value: '8' },
        { label: '완주군', value: '9' },
        { label: '진안군', value: '10' },
        { label: '무주군', value: '11' },
        { label: '장수군', value: '12' },
        { label: '임실군', value: '13' },
        { label: '순창군', value: '14' },
        { label: '고창군', value: '15' },
        { label: '부안군', value: '16' }
    ];

    // 전라남도
    const sub_code14 = [
        { label: '전체', value: '-1' },
        { label: '목포시', value: '0' },
        { label: '여수시', value: '1' },
        { label: '순천시', value: '2' },
        { label: '나주시', value: '3' },
        { label: '광양시', value: '4' },
        { label: '담양군', value: '5' },
        { label: '곡성군', value: '6' },
        { label: '구례군', value: '7' },
        { label: '고흥군', value: '8' },
        { label: '보성군', value: '9' },
        { label: '화순군', value: '10' },
        { label: '장흥군', value: '11' },
        { label: '강진군', value: '12' },
        { label: '해남군', value: '13' },
        { label: '영암군', value: '14' },
        { label: '무안군', value: '15' },
        { label: '함평군', value: '16' },
        { label: '영광군', value: '17' },
        { label: '장성군', value: '18' },
        { label: '완도군', value: '19' },
        { label: '진도군', value: '20' },
        { label: '신안군', value: '21' }
    ];

    // 경상북도
    const sub_code15 = [
        { label: '전체', value: '-1' },
        { label: '포항시', value: '0' },
        { label: '남구', value: '1' },
        { label: '북구', value: '2' },
        { label: '자치시', value: '3' },
        { label: '경주시', value: '4' },
        { label: '김천시', value: '5' },
        { label: '안동시', value: '6' },
        { label: '구미시', value: '7' },
        { label: '영주시', value: '8' },
        { label: '영천시', value: '9' },
        { label: '상주시', value: '10' },
        { label: '문경시', value: '11' },
        { label: '경산시', value: '12' },
        { label: '군위군', value: '13' },
        { label: '의성군', value: '14' },
        { label: '청송군', value: '15' },
        { label: '영양군', value: '16' },
        { label: '영덕군', value: '17' },
        { label: '청도군', value: '18' },
        { label: '고령군', value: '19' },
        { label: '성주군', value: '20' },
        { label: '칠곡군', value: '21' },
        { label: '예천군', value: '22' },
        { label: '봉화군', value: '23' },
        { label: '울진군', value: '24' },
        { label: '울릉군', value: '25' }
    ];

    // 경상남도
    const sub_code16 = [
        { label: '전체', value: '-1' },
        { label: '창원시', value: '0' },
        { label: '의창구', value: '1' },
        { label: '성산구', value: '2' },
        { label: '마산합포구', value: '3' },
        { label: '마산회원구', value: '4' },
        { label: '진해구', value: '5' },
        { label: '자치시', value: '6' },
        { label: '진주시', value: '7' },
        { label: '통영시', value: '8' },
        { label: '사천시', value: '9' },
        { label: '김해시', value: '10' },
        { label: '밀양시', value: '11' },
        { label: '거제시', value: '12' },
        { label: '양산시', value: '13' },
        { label: '의령군', value: '14' },
        { label: '함안군', value: '15' },
        { label: '창녕군', value: '16' },
        { label: '고성군', value: '17' },
        { label: '남해군', value: '18' },
        { label: '하동군', value: '19' },
        { label: '산청군', value: '20' },
        { label: '함양군', value: '21' },
        { label: '거창군', value: '22' },
        { label: '합천군', value: '23' }
    ];

    // 제주특별자치도
    const sub_code17 = [
        { label: '전체', value: '-1' },
        { label: '제주시', value: '0' },
        { label: '서귀포시', value: '1' }
    ];

    if (num === 0) {
        return code1;
    }
    else {
        const subCodeArray = [sub_code0, sub_code1, sub_code2, sub_code3, sub_code4, sub_code5, sub_code6, sub_code7, sub_code8, sub_code9, sub_code10, sub_code11, sub_code12, sub_code13, sub_code14, sub_code15, sub_code16, sub_code17];
        return subCodeArray;
    }
}

