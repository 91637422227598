import React, { useState, useEffect } from "react";
import { View, Text, Button, Keyboard, Dimensions, TouchableOpacity, TextInput } from "react-native"
import fbInstance from "../Config/fbInstance";
import styled from "styled-components/native";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import AwesomeAlert from 'react-native-awesome-alerts';
import moment from "moment";
import { GetColors } from '../Config/Datas';
import * as Linking from 'expo-linking';
import { AntDesign, FontAwesome } from '@expo/vector-icons';

// const windowHeight = Dimensions.get('window').height;
// const windowWidth = Dimensions.get('window').width;

const BackView = styled.View`
    padding-left: 45px;
    padding-right: 45px;
    background-color: ${GetColors(0)};
`;

const GuideText1 = styled.Text`
    font-size: 14px;
    font-weight: 500;
`;

const GuideText2 = styled.Text`
    font-size: 18px;
    font-weight: 600;
`;

const GuideText3 = styled.Text`
    font-size: 20px;
    font-weight: 700;
    color: ${GetColors(0)};
`;

const GuideText4 = styled.Text`
    font-size: 18px;
    font-weight: 700;
    color: ${GetColors(5)};
`;

const GuideText6 = styled.Text`
    font-size: 18px;
    font-weight: 500;
`;

const SignInType0 = ({ navigation }) => {
    const [isAlert, setIsAlert] = useState([false, "메세지", false, true]);
    const fDb = fbInstance.firestore();
    const fAuth = fbInstance.auth();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [vPassword, setVPassword] = useState("");
    const [agreement1, setAgreement1] = useState(false);

    const onChangeEmail = t => {
        setEmail(t.nativeEvent.text);
    }
    const onChangeName = t => {
        setName(t.nativeEvent.text);
    }
    const onChangePhone = text => {
        setPhone(text.nativeEvent.text);
    }
    const onChangePw = text => {
        setPassword(text.nativeEvent.text);
    }
    const onChangeVPw = text => {
        setVPassword(text.nativeEvent.text);
    }

    const onSignInNormal = async () => {

        if (!agreement1) {
            setIsAlert([true, "개인정보 보호 약관에 동의해주세요.", false, true]);
            return;
        }

        if (email.length === 0) {
            setIsAlert([true, "이메일 주소를 확인해주세요.", false, true]);
            return;
        }

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(email) === false) {
            setIsAlert([true, "이메일 형식을 확인해주세요.", false, true]);
            return;
        }

        if (password !== vPassword) {
            setIsAlert([true, "비밀번호가 일치하지 않습니다.", false, true]);
            return;
        }


        if (password.length < 6) {
            setIsAlert([true, "6자리 이상의 비밀번호를 설정해주세요.", false, true]);
        }


        if (name.length === 0 || phone.length === 0) {
            setIsAlert([true, "전화번호를 입력해주세요.", false, true]);
            return;
        }

        try {
            setIsAlert([true, "회원가입을 진행중입니다.\n잠시만 기다려주세요.", true, false]);
            const response = await fAuth.createUserWithEmailAndPassword(email, password)
            if (response.user.uid) {
                const user = {
                    uid: response.user.uid,
                    email: email,
                    name: name,
                    phone: phone,
                    userCode: 1,
                    licenseUrl: "",
                    licenseImagePath: "",
                    signInDate: moment().format('YYYY/MM/DD'),
                    holding: false,
                    comment: "",
                    history: [],
                    shopName: "",
                    shopNumber: "",
                    shopAddress: "",
                    shopFounder: "",
                    shopConnect: ""
                }

                fDb.collection('GUsers')
                    .doc(response.user.uid)
                    .set(user);

                setIsAlert([false, "회원가입을 진행중입니다.\n잠시만 기다려주세요.", true, false]);
                navigation.goBack();
                navigation.goBack();
            }
        } catch (e) {
            setIsAlert([true, "회원가입을 실패했습니다.", false, true]);
        }
    }

    return (
        <View style={{ width: '100%', flex: 1, alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView style={{ backgroundColor: GetColors(0), flex: 1 }}>
                <BackView style={{ backgroundColor: GetColors(0), width: 400 }}>
                    <GuideText1 style={{ paddingTop: 20 }}>안녕하세요.</GuideText1>
                    <View style={{ flexDirection: 'row', paddingTop: 3, alignItems: 'center' }}>
                        <GuideText4>국화</GuideText4>
                        <GuideText1>에 오신것을 환영합니다.</GuideText1>
                    </View>
                    <GuideText2 style={{ paddingTop: 20 }}>이메일</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="email-address"
                        placeholder="예: gukwha@naver.com"
                        value={email}
                        onChange={onChangeEmail}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>이름</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="홍길동"
                        value={name}
                        onChange={onChangeName}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>전화번호</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="number-pad"
                        placeholder="010 1234 1234"
                        value={phone}
                        onChange={onChangePhone}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>비밀번호</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="default"
                        secureTextEntry={true}
                        placeholder="영문, 숫자 조합 최소 6자 이상"
                        value={password}
                        onChange={onChangePw}></TextInput>
                    <GuideText2 style={{ paddingTop: 20 }}>비밀번호 확인</GuideText2>
                    <TextInput style={{
                        paddingLeft: 15,
                        marginTop: 5,
                        height: 40,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="default"
                        secureTextEntry={true}
                        textContentType="password"
                        placeholder="비밀번호를 다시한번 입력해주세요."
                        value={vPassword}
                        onChange={onChangeVPw}></TextInput>
                    <GuideText1 style={{ paddingTop: 20 }}>개인정보 보호약관</GuideText1>
                    <TouchableOpacity onPress={() => Linking.openURL(`https://theonplatform.imweb.me/terms`)}>
                        <GuideText1 style={{ color: GetColors(5) }}>https://theonplatform.imweb.me/terms</GuideText1>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{ marginTop: 10, flexDirection: 'row', height: 30, alignItems: 'center' }}
                        onPress={() => setAgreement1(!agreement1)}>
                        {agreement1 ?
                            <AntDesign name="checkcircleo" size={25} color={GetColors(5)} />
                            :
                            <FontAwesome name="circle-thin" size={29} color='grey' />
                        }
                        <GuideText6 style={{ color: agreement1 ? GetColors(5) : 'grey' }}>  개인정보 보호 약관 동의</GuideText6>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ marginTop: 30, justifyContent: 'center', alignItems: 'center', height: 45, borderRadius: 30, backgroundColor: GetColors(5) }}
                        onPress={onSignInNormal}>
                        <GuideText3>회원가입</GuideText3>
                    </TouchableOpacity>
                    <GuideText3>{"\n"}{"\n"}{"\n"}{"\n"}</GuideText3>
                </BackView>
                <AwesomeAlert
                    show={isAlert[0]}
                    showProgress={isAlert[2]}
                    title="국화"
                    message={isAlert[1]}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showCancelButton={false}
                    showConfirmButton={isAlert[3]}
                    cancelText=""
                    confirmText=" 확인 "
                    confirmButtonColor="#000000"
                    onCancelPressed={() => {
                        setIsAlert([false, "", false, true]);
                    }}
                    onConfirmPressed={() => {
                        setIsAlert([false, "", false, true]);
                    }}
                />
            </KeyboardAwareScrollView>
        </View>
    )
}

export default SignInType0;