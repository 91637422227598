import React, { useState, useEffect } from "react";
import { View, Text, Switch, TouchableOpacity, TextInput, Dimensions } from "react-native";
import styled from "styled-components/native";
import * as ImagePicker from 'expo-image-picker';
import fbInstance from "../Config/fbInstance";
import AwesomeAlert from 'react-native-awesome-alerts';
import DropDownPicker from 'react-native-dropdown-picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { GetRoomStateName } from "../Config/Datas"
import StoreInputImageComponent from "../Components/StoreInputImageComponent"
import { GetPickedTime, GetColors, GetPickedTime2 } from "../Config/Datas"
import * as ImageManipulator from 'expo-image-manipulator';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const { width: WIDTH, height: HEIGHT } = Dimensions.get("window");

const RoomStateView = styled.View`
    margin: 2px;
    align-content: center;
    align-items: center;
    flex-direction: row;
`;

const RoomStateView3 = styled.View`
    margin-top: 7px;
    margin-bottom: 7px;
    height: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;


const RoomStateView2 = styled.View`
    margin-top: 7px;
    margin-bottom: 7px;
    height: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
`;

const BackView = styled.View`
    padding-left: 25px;
    padding-right: 25px;
    background-color: ${GetColors(0)};
    padding-bottom: 150px;
`;

const GuideText1 = styled.Text`
    font-size: 12px;
    font-weight: 500;
`;

const GuideText2 = styled.Text`
    font-size: 12px;
    font-weight: 600;
`;

const GuideText3 = styled.Text`
    font-size: 12px;
    font-weight: 400;
`;

const GuideText4 = styled.Text`
    font-size: 14px;
    font-weight: 600;
`;

const GuideText5 = styled.Text`
    font-size: 17px;
    font-weight: 600;
    color: ${GetColors(0)};
`;

let isImageDelete = false;
let isImageAdd = false;
let oriImageArr;
let updateImageArr;
const roomStateName = GetRoomStateName();
const pickedTime = GetPickedTime();
const pickedTime2 = GetPickedTime2();

const StorRoomDetail = ({ route, navigation }) => {
    const [roomInfo, setRoomInfo] = useState(route.params.roomInfo);

    const storeUid = route.params.storeUid;
    const targetIndex = route.params.index;
    const [image, setImage] = useState(roomInfo[targetIndex].roomPics);
    useEffect(() => {
        oriImageArr = roomInfo[targetIndex].roomPics;
        updateImageArr = image;
    }, []);

    const [isAlert, setIsAlert] = useState([false, false, "국화", "메세지", false, false, false, false, "취소", "확인", "#0073cf", -1]);
    const [isLoading, setIsLoading] = useState(false);
    const [text1, setText1] = useState(roomInfo[targetIndex].roomName);
    const [text2, setText2] = useState(roomInfo[targetIndex].roomSize);
    const [text3, setText3] = useState(roomInfo[targetIndex].roomPrice);
    const [text4, setText4] = useState(roomInfo[targetIndex].roomSaleRate);
    const [ddIsOpen1, setDdIsOpen1] = useState(false);
    const [ddIsOpen2, setDdIsOpen2] = useState(false);
    const roomState = roomInfo[targetIndex].roomState.split(',');

    const [byeTime, setByeTime] = useState(roomInfo[targetIndex].byeTime);
    const [state0, setState0] = useState(roomInfo[targetIndex].roomBookState);
    const [state1, setState1] = useState(roomState[0] === "true" ? true : false);
    const [state2, setState2] = useState(roomState[1] === "true" ? true : false);
    const [state3, setState3] = useState(roomState[2] === "true" ? true : false);
    const [state4, setState4] = useState(roomState[3] === "true" ? true : false);
    const [state5, setState5] = useState(roomState[4] === "true" ? true : false);
    const [state6, setState6] = useState(roomState[5] === "true" ? true : false);

    const toggleState0 = () => setState0(previousState => !previousState);
    const toggleState1 = () => setState1(previousState => !previousState);
    const toggleState2 = () => setState2(previousState => !previousState);
    const toggleState3 = () => setState3(previousState => !previousState);
    const toggleState4 = () => setState4(previousState => !previousState);
    const toggleState5 = () => setState5(previousState => !previousState);
    const toggleState6 = () => setState6(previousState => !previousState);

    const onChange1 = text => {
        setText1(text.nativeEvent.text);
    }

    const onChange2 = text => {
        setText2(text.nativeEvent.text);
    }

    const onChange3 = text => {
        setText3(text.nativeEvent.text);
    }

    const onChange4 = text => {
        setText4(text.nativeEvent.text);
    }

    const pickImage = async () => {
        if (image.length > 9) {
            setIsAlert([true, false, "국화", "10장 이상의 사진을 등록 할 수 없습니다.", false, false, false, true, "취소", "확인", "#0073cf", -1]);
            return;
        }
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.images,
            allowsEditing: true,
            aspect: [3, 3],
            quality: 0.1
        });

        if (!result.cancelled) {
            const manipResult = await ImageManipulator.manipulateAsync(
                result.uri,
                [{ rotate: 0 }],
                { compress: 0.1, format: ImageManipulator.SaveFormat.JPEG }
            );

            isImageAdd = true;
            let imageTemp = [...image];
            imageTemp.push({
                imageUrl: "",
                imagePath: "",
                imageUri: manipResult.uri
            });
            updateImageArr.push({
                imageUrl: "",
                imagePath: "",
                imageUri: manipResult.uri
            })

            setImage(imageTemp);
        }
    };


    const deleteImage = (index) => {
        isImageDelete = true;
        let imageTemp = [];
        for (let i = 0; i < image.length; i++) {
            if (i !== index) {
                imageTemp.push(image[i]);
            }
        }

        setImage(imageTemp);
        updateImageArr = imageTemp;

    }

    const actSaveStoreInfo = async () => {
        setIsLoading(true);
        if (isImageDelete) {
            for (let i = 0; i < oriImageArr.length; i++) {
                let isDelete = true;
                for (let j = 0; j < image.length; j++) {
                    if (oriImageArr[i].imageUrl === image[j].imageUrl) {
                        isDelete = false;
                    }
                }
                if (isDelete) {
                    const storageRef = fbInstance.storage().ref();
                    const desertRef = storageRef.child(`storeRoomImages/${oriImageArr[i].imagePath}`);

                    desertRef.delete().then(function () {
                        console.log("delete success");
                    }).catch(function (error) {
                        console.log("delete error");
                    });
                }
            }
        }

        // 이미지 추가된거 셋팅
        if (isImageAdd) {
            for (let i = 0; i < image.length; i++) {
                if (image[i].imageUri != "") {
                    let imageUrlTemp = "";
                    let imagePathTemp = "";
                    const randomString = Math.random().toString(36).substr(2, 11) + Math.random().toString(36).substr(2, 11) + Math.random().toString(36).substr(2, 11);

                    const res = await fetch(image[i].imageUri);
                    const blob = await res.blob();

                    const storegeService = fbInstance.storage();

                    const fileRef = storegeService.ref().child(`storeRoomImages/${randomString}`);
                    imagePathTemp = randomString;
                    const response = await fileRef.put(blob);
                    imageUrlTemp = await response.ref.getDownloadURL();

                    updateImageArr[i].imageUri = "";
                    updateImageArr[i].imageUrl = imageUrlTemp;
                    updateImageArr[i].imagePath = imagePathTemp;
                }
            }
        }

        let roomInfoTemp = roomInfo;
        roomInfoTemp[targetIndex].roomName = text1;
        roomInfoTemp[targetIndex].roomSize = text2;
        roomInfoTemp[targetIndex].roomPrice = text3;
        roomInfoTemp[targetIndex].roomSaleRate = text4;
        roomInfoTemp[targetIndex].roomState = state1 + "," + state2 + "," + state3 + "," + state4 + "," + state5 + "," + state6;
        roomInfoTemp[targetIndex].roomPics = updateImageArr;
        roomInfoTemp[targetIndex].roomBookState = state0;

        if (state0) {
            roomInfoTemp[targetIndex].roomBookUserInfo = "";
            roomInfoTemp[targetIndex].roomBookUserUid = "";
            roomInfoTemp[targetIndex].byeTime = "0,0";
        }
        else {
            roomInfoTemp[targetIndex].byeTime = byeTime;
        }

        setRoomInfo(roomInfoTemp);
        const fDb = fbInstance.firestore().collection("StoreInfo").doc(storeUid);
        fDb.update({
            roomInfo: roomInfo
        });

        // UserHistory, StoreHistory 데이터 업데이트 처리
        // roomBookUserUid, userHistoryIndex
        const fDb2 = fbInstance.firestore();
        fDb2.collection("UserHistory").doc(roomInfo[targetIndex].roomBookUserUid).get().then((doc) => {

            let uHistoryTemp = [];
            uHistoryTemp = doc.data();

            uHistoryTemp.history[roomInfo[targetIndex].userHistoryIndex].byeTime = byeTime + "";
            fDb2.collection("UserHistory").doc(roomInfo[targetIndex].roomBookUserUid).update(uHistoryTemp);
        });


        // companyUid, storeHistoryIndex
        fDb2.collection("StoreHistory").doc(storeUid).get().then((doc) => {
            let sHistoryTemp = doc.data();

            sHistoryTemp.history[roomInfoTemp[targetIndex].storeHistoryIndex].byeTime = byeTime + "";
            fDb2.collection("StoreHistory").doc(storeUid).update(sHistoryTemp);
        });

        setIsAlert([false, false, "국화", "메세지", false, false, false, true, "취소", "확인", "#0073cf", -1]);
        setIsLoading(false);
        navigation.goBack();
    }

    const saveRoomInfo = () => {
        if (text1 == "" || text2 == "" || text3 == "" || text4 == "") {
            setIsAlert([true, false, "국화", "비어 있는 입력정보가 있습니다.", false, false, false, true, "취소", "확인", "#0073cf", 0]);
        }
        else {
            setIsAlert([true, false, "국화", "입력하신 정보를 저장하시겠습니까?", false, false, false, true, "취소", "확인", "#0073cf", 1]);
        }
    }


    return (
        <View style={{ flex: 1, width: '100%', alignItems: 'center', backgroundColor: GetColors(0) }}>
            <KeyboardAwareScrollView showsVerticalScrollIndicator={false} style={{ width: 400, backgroundColor: GetColors(0) }}>
                <BackView>
                    <GuideText1 style={{ paddingTop: 24 }}>- 업체상세정보 수정 페이지입니다.</GuideText1>
                    <GuideText1 style={{ paddingTop: 3 }}>- 아래 정보를 수정하시면 즉시 수정되어 사용자에게 보여집니다.</GuideText1>
                    <GuideText2 style={{ paddingTop: 17 }}>예약가능여부</GuideText2>
                    <View style={{ flexDirection: 'row', paddingTop: 5, alignItems: 'center' }}>
                        <GuideText3>사용중  </GuideText3>
                        <Switch
                            style={{ transform: [{ scaleX: 0.7 }, { scaleY: .7 }] }}
                            trackColor={{ false: '#767577', true: '#0073cf' }}
                            onValueChange={toggleState0}
                            value={state0}
                        />
                        <GuideText3>  예약가능</GuideText3>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        {roomInfo[targetIndex].roomBookState ?
                            <></>
                            :
                            <View style={{ width: 360 / 2, minHeight: ddIsOpen1 ? 200 : ddIsOpen2 ? 200 : 0 }}>
                                <GuideText2 style={{ paddingTop: 17, marginBottom: 5, color: parseInt(byeTime.split(',')[0]) === 0 ? 'red' : 'black' }}>입실시간 입력</GuideText2>
                                <DropDownPicker
                                    style={{
                                        backgroundColor: 'white',
                                        borderRadius: 4,
                                        shadowColor: 'black',
                                        shadowRadius: 1,
                                        shadowOpacity: 0.1,
                                        shadowOffset: { width: 1, height: 2 },
                                        elevation: 3,
                                        paddingBottom: 5
                                    }}
                                    onOpen={() => { setDdIsOpen1(true); }}
                                    onClose={() => { setDdIsOpen1(false); }}
                                    items={pickedTime2}
                                    defaultValue={byeTime.split(',')[0]}
                                    containerStyle={{ height: 40 }}
                                    itemStyle={{
                                        justifyContent: 'flex-start'
                                    }}
                                    selectedLabelStyle={{
                                        color: '#000000'
                                    }}
                                    dropDownStyle={{ backgroundColor: '#ffffff' }}
                                    onChangeItem={item => {
                                        let tempByeTime = byeTime;
                                        tempByeTime = item.value + "," + byeTime.split(',')[1];
                                        setByeTime(tempByeTime);
                                    }} />
                            </View>}
                        {roomInfo[targetIndex].roomBookState ?
                            <></>
                            :
                            <View style={{ width: 360 / 2 }}>
                                <GuideText2 style={{ paddingTop: 17, marginBottom: 5, color: parseInt(byeTime.split(',')[1]) === 0 ? 'red' : 'black' }}>발인시간 입력</GuideText2>
                                <DropDownPicker
                                    style={{
                                        backgroundColor: 'white',
                                        borderRadius: 4,
                                        shadowColor: 'black',
                                        shadowRadius: 1,
                                        shadowOpacity: 0.1,
                                        shadowOffset: { width: 1, height: 2 },
                                        elevation: 3,
                                        paddingBottom: 5
                                    }}
                                    onOpen={() => { setDdIsOpen2(true); }}
                                    onClose={() => { setDdIsOpen2(false); }}
                                    items={pickedTime}
                                    defaultValue={byeTime.split(',')[1]}
                                    containerStyle={{ height: 40 }}
                                    itemStyle={{
                                        justifyContent: 'flex-start'
                                    }}
                                    selectedLabelStyle={{
                                        color: '#000000'
                                    }}
                                    dropDownStyle={{ backgroundColor: '#ffffff' }}
                                    onChangeItem={item => {
                                        let tempByeTime = byeTime;
                                        tempByeTime = byeTime.split(',')[0] + "," + item.value;
                                        setByeTime(tempByeTime);
                                    }} />
                            </View>}
                    </View>
                    <GuideText2 style={{ paddingTop: 15, marginTop: 20 }}>방이름</GuideText2>
                    <TextInput style={{
                        paddingLeft: 12,
                        marginTop: 5,
                        height: 37,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        placeholder="빈소의 이름을 입력해주세요. - 101호, 국화 등..."
                        onChange={onChange1}
                        value={text1}></TextInput>
                    <GuideText2 style={{ paddingTop: 5 }}>빈소규모(수용인원)</GuideText2>
                    <TextInput style={{
                        paddingLeft: 12,
                        marginTop: 5,
                        height: 37,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="number-pad"
                        placeholder="빈소위 규모를 입력해주세요 - 50명"
                        onChange={onChange2}
                        value={text2}></TextInput>
                    <GuideText2 style={{ paddingTop: 5 }}>가격</GuideText2>
                    <TextInput style={{
                        paddingLeft: 12,
                        marginTop: 5,
                        height: 37,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="number-pad"
                        placeholder="빈소의 가격을 입력해주세요."
                        onChange={onChange3}
                        value={text3}></TextInput>
                    <GuideText2 style={{ paddingTop: 5 }}>할인율(%)</GuideText2>
                    <TextInput style={{
                        paddingLeft: 12,
                        marginTop: 5,
                        height: 37,
                        borderRadius: 5,
                        borderColor: GetColors(17),
                        borderWidth: 0.5
                    }}
                        keyboardType="number-pad"
                        placeholder="방의 할인율을 입력해주세요.(%)"
                        onChange={onChange4}
                        value={text4}></TextInput>
                    <GuideText2 style={{ paddingTop: 5 }}>시설</GuideText2>
                    <View style={{ paddingTop: 8, paddingBottom: 8 }}>
                        <View style={{ flexDirection: 'row' }}>
                            <RoomStateView2>
                                <GuideText4>{roomStateName[0]}</GuideText4>
                                <Switch
                                    style={{ transform: [{ scaleX: 0.7 }, { scaleY: .7 }] }}
                                    trackColor={{ false: '#767577', true: '#0073cf' }}
                                    onValueChange={toggleState1}
                                    value={state1}
                                />
                            </RoomStateView2>
                            <RoomStateView2>
                                <GuideText4>{roomStateName[1]}</GuideText4>
                                <Switch
                                    style={{ transform: [{ scaleX: 0.7 }, { scaleY: .7 }] }}
                                    trackColor={{ false: '#767577', true: '#0073cf' }}
                                    onValueChange={toggleState2}
                                    value={state2}
                                />
                            </RoomStateView2>
                        </View>
                        <RoomStateView>
                            <RoomStateView2>
                                <GuideText4>{roomStateName[2]}</GuideText4>
                                <Switch
                                    style={{ transform: [{ scaleX: 0.7 }, { scaleY: .7 }] }}
                                    trackColor={{ false: '#767577', true: '#0073cf' }}
                                    onValueChange={toggleState3}
                                    value={state3}
                                />
                            </RoomStateView2>
                            <RoomStateView2>
                                <GuideText4>{roomStateName[3]}</GuideText4>
                                <Switch
                                    style={{ transform: [{ scaleX: 0.7 }, { scaleY: .7 }] }}
                                    trackColor={{ false: '#767577', true: '#0073cf' }}
                                    onValueChange={toggleState4}
                                    value={state4}
                                />
                            </RoomStateView2>
                        </RoomStateView>
                        <RoomStateView>
                            <RoomStateView2>
                                <GuideText4>{roomStateName[4]}</GuideText4>
                                <Switch
                                    style={{ transform: [{ scaleX: 0.7 }, { scaleY: .7 }] }}
                                    trackColor={{ false: '#767577', true: '#0073cf' }}
                                    onValueChange={toggleState5}
                                    value={state5}
                                />
                            </RoomStateView2>
                            <RoomStateView2>
                                <GuideText4>{roomStateName[5]}</GuideText4>
                                <Switch
                                    style={{ transform: [{ scaleX: 0.7 }, { scaleY: .7 }] }}
                                    trackColor={{ false: '#767577', true: '#0073cf' }}
                                    onValueChange={toggleState6}
                                    value={state6}
                                />
                            </RoomStateView2>
                        </RoomStateView>
                    </View>
                    <View style={{ height: 0.3, backgroundColor: GetColors(18) }} />
                    <GuideText2 style={{ paddingTop: 15, paddingBottom: 5 }}>빈소 세부사진</GuideText2>
                    <StoreInputImageComponent image={image} deleteImage={deleteImage} pickImage={pickImage} />
                    <View style={{ flexDirection: 'row', paddingTop: 7 }}>
                        <GuideText1>{image.length}/10   </GuideText1>
                        <TouchableOpacity onPress={pickImage} style={{ flexDirection: 'row' }}>
                            <MaterialCommunityIcons name="plus-box-outline" size={20} color="black" />
                            <GuideText4>사진 추가</GuideText4>
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity onPress={saveRoomInfo} style={{ justifyContent: 'center', alignItems: 'center', marginTop: 20, paddingLeft: 20, paddingRight: 20, marginLeft: 20, marginRight: 20, borderRadius: 25, height: 40, backgroundColor: GetColors(5) }}>
                        <GuideText5>빈소정보 저장</GuideText5>
                    </TouchableOpacity>
                </BackView>
                <AwesomeAlert
                    show={isAlert[0]}
                    showProgress={isAlert[1]}
                    title={isAlert[2]}
                    message={isAlert[3]}
                    closeOnTouchOutside={isAlert[4]}
                    closeOnHardwareBackPress={isAlert[5]}
                    showCancelButton={true}
                    showConfirmButton={isAlert[7]}
                    cancelText={isAlert[8]}
                    confirmText={isAlert[9]}
                    confirmButtonColor={isAlert[10]}
                    onCancelPressed={() => {
                        setIsAlert([false, false, "국화", "메세지", false, false, false, true, "취소", "확인", "#0073cf", -1]);
                    }}
                    onConfirmPressed={() => {
                        // 정보 입력이 제대로 되지 않음
                        if (isAlert[11] === 0) {
                            setIsAlert([false, false, "국화", "비어 있는 입력정보가 있습니다.", false, false, false, true, "취소", "확인", "#0073cf", -1]);
                        }
                        // 저장 이후 확인 버튼
                        else if (isAlert[11] === 1) {
                            setIsAlert([false, false, "국화", "", false, false, false, true, "취소", "확인", "#0073cf", -1]);
                            actSaveStoreInfo();
                        }
                        else {
                            setIsAlert([false, false, "국화", "메세지", false, false, false, true, "취소", "확인", "#0073cf", -1]);
                        }
                    }}
                />
            </KeyboardAwareScrollView>
        </View>
    )
}

export default StorRoomDetail;
