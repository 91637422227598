import firebase from "firebase";
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDHje65hO8TU-uT0cyyfeL4KSsVt3bqBUs",
    authDomain: "gukwha-2eb33.firebaseapp.com",
    projectId: "gukwha-2eb33",
    storageBucket: "gukwha-2eb33.appspot.com",
    messagingSenderId: "427155037919",
    appId: "1:427155037919:web:2c999479981df7bbdf936a"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
